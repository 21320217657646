import React from "react";
import { form56_1_data, form56_1_dataEN } from "../../../mock/Mockup56_1";
import { Header } from "../../../Components";
import { useGlobalContext } from "../../../context/Context";
import { Link } from "react-router-dom";

export default function Form_56_1() {
  const { language } = useGlobalContext();

  return (
    <div className="sub-top-hight">
      <div className="container-I" style={{ marginBottom: 100 }}>
        <div className="row">
          <div className="box-line-c">
            <Header nameTH={"แบบ 56-1"} nameEN={"Form 56-1"} />
          </div>
        </div>

        <div
          class="container mt-4 "
          style={{ margin: "auto", marginTop: "3.75rem" }}
        >
          <div class="relative overflow-x-auto">
            <table class="w-full table-respon mt-4 text-sm text-center text-white ">
              {language === "T" ? (
                <thead class="text-md text-white bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                  <tr>
                    <th scope="col" class="px-6 py-4 text-center">
                      ชื่อบริษัท
                    </th>
                    <th scope="col" class="px-6 py-4">
                      ประจำปี
                    </th>
                    <th scope="col" class="px-6 py-4">
                      วันที่ได้รับข้อมูล
                    </th>
                    <th scope="col" class="px-6 py-4">
                      รายละเอียด
                    </th>
                  </tr>
                </thead>
              ) : (
                <thead class="text-md text-white bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                  <tr>
                    <th scope="col" class="px-6 py-4 text-center">
                      Company Name
                    </th>
                    <th scope="col" class="px-6 py-4">
                      Year
                    </th>
                    <th scope="col" class="px-6 py-4">
                      Date & Time
                    </th>
                    <th scope="col" class="px-6 py-4">
                      Detail
                    </th>
                  </tr>
                </thead>
              )}

              <tbody>
                {language === "T"
                  ? form56_1_data.map((item, i) => {
                      return (
                        <tr
                          key={i}
                          class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                        >
                          <th
                            scope="row"
                            class="px-6 py-4 text-[#1b3a91] font-medium"
                            style={{ textAlign: "left" }}
                          >
                            {item.companyName}
                          </th>
                          <td class="px-6 py-4 text-[#666] text-center">
                            {item.year}
                          </td>
                          <td class="px-6 py-4 text-[#666] text-center">
                            {item.dateTime}
                          </td>
                          <td class="px-6 py-4 text-[#092267] text-center">
                            <Link to={item.detail} target="_blank">
                              {" "}
                              ไฟล์ดาวน์โหลด{" "}
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  : form56_1_dataEN.map((item, i) => {
                      return (
                        <tr
                          key={i}
                          class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                        >
                          <th
                            scope="row"
                            class="px-6 py-4 text-[#1b3a91] font-medium"
                            style={{ textAlign: "left" }}
                          >
                            {item.companyName}
                          </th>
                          <td class="px-6 py-4 text-[#666] text-center">
                            {item.year}
                          </td>
                          <td class="px-6 py-4 text-[#666] text-center">
                            {item.dateTime}
                          </td>
                          <td class="px-6 py-4 text-[#092267] text-center">
                            <Link to={item.detail} target="_blank">
                              {" "}
                              Detail{" "}
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
