export const boardData1 = [
  {
    name: "ดร.สุวิทย์ ธนียวัน",
    position: "กรรมการอิสระ และประธานกรรมการ (กรรมการที่ไม่เป็นผู้บริหาร)",
  },
  {
    name: "นายวิชัย แสงวงศ์กิจ",
    position: "กรรมการ และกรรมการผู้จัดการ (กรรมการผู้มีอำนาจลงนาม)",
  },
  {
    name: "นายวิเชียร แสงวงศ์กิจ",
    position: "กรรมการ (กรรมการผู้มีอำนาจลงนาม)",
  },
  {
    name: "นายชัยวัฒน์ ทวีพิริยะ",
    position: "กรรมการ (กรรมการผู้มีอำนาจลงนาม)",
  },
  {
    name: "นายสงวน แสงวงศ์กิจ",
    position: "กรรมการ (กรรมการผู้มีอำนาจลงนาม)",
  },
  {
    name: "นายสุพจน์ มัสยามาศ",
    position: "กรรมการ",
  },
  {
    name: "นายสมนึก แสงวงศ์กิจ",
    position: "กรรมการ",
  },
  {
    name: "นางสาวกนกพร อารยะปราการ",
    position: "กรรมการ (กรรมการผู้มีอำนาจลงนาม)",
  },
  {
    name: "นางสาวอรวรรณ แสงวงศ์กิจ",
    position: "กรรมการ (กรรมการผู้มีอำนาจลงนาม)",
  },
  {
    name: "นายธะเรศ โปษยานนท์",
    position:
      "กรรมการอิสระ ประธานคณะกรรมการตรวจสอบ และกรรมการสรรหาและกำหนดค่าตอบแทน",
  },
  {
    name: "พล.ต.ท.สัญชัย ไชยอำพร",
    position:
      "กรรมการอิสระ กรรมการตรวจสอบ และประธานคณะกรรมการสรรหาและกำหนดค่าตอบแทน",
  },
  {
    name: "	นายทิวัฒน์ รัตนเกตุ",
    position: "กรรมการอิสระ และกรรมการตรวจสอบ",
  },
  {
    name: "นางสาววิชุตา แสงวงศ์กิจ 1/",
    position: "กรรมการสรรหาและกำหนดค่าตอบแทน",
  },
  {
    name: "นายหาญศิริ แสงวงศ์กิจ 1/",
    position: "กรรมการสรรหาและกำหนดค่าตอบแทน",
  },
];

export const boardData1en = [
  {
    name: "Dr. Suvit Thaniyavarn",
    position:
      "Independent Director and Chairman of the Board (Non-Executive Director)",
  },
  {
    name: "Mr. Vichai Sangwongkit",
    position: "Director and Managing Director (Authorized Director)",
  },
  {
    name: "Mr. Vichien Saengvongkij",
    position: "Director (Authorized Director)",
  },
  {
    name: "	Mr. Chaiwat Taweepiriya",
    position: "Director (Authorized Director)",
  },
  {
    name: "	Mr. Sanguan Saengwongkij",
    position: "Director (Authorized Director)",
  },
  {
    name: "	Mr. Supot Massayamas",
    position: "Director",
  },
  {
    name: "Mr. Somnuk Saengwongkij",
    position: "Director",
  },
  {
    name: "Miss Kanokporn Arayaprakarn",
    position: "Director (Authorized Director)",
  },
  {
    name: "Miss Orawan Sangwongkit",
    position: "Director (Authorized Director)",
  },
  {
    name: "Mr. Tarate Poshyananda",
    position:
      "Independent Director, Chairman of the Audit Committee, and Member of the Nomination and Compensation Committee",
  },
  {
    name: "Pol.Lt.Gen. Sunchai Chaiamporn",
    position:
      "Independent Director, Member of the Audit committee, and Chairman of the Nomination and Compensation Committee",
  },
  {
    name: "Mr. Tiwat Rattanakate",
    position: "Independent Director and Member of the Audit committee",
  },
  {
    name: "Miss Vichuta Saengvongkij 1/",
    position: "Member of the Nomination and Compensation Committee",
  },
  {
    name: "Mr. Harnsiri Sangwongkit 1/",
    position: "Member of the Nomination and Compensation Committee",
  },
];

export const boardData2 = [
  {
    name: "นายวิชัย แสงวงศ์กิจ",
    position: "ประธานคณะกรรมการบริหาร",
  },
  {
    name: "นายสุพจน์ มัสยามาศ",
    position: "กรรมการบริหาร",
  },
  {
    name: "นางสาวอรวรรณ แสงวงศ์กิจ",
    position: "กรรมการบริหาร",
  },
  {
    name: "นายธงชัย ตั้งศุภอนันต์",
    position: "กรรมการบริหาร",
  },
  {
    name: "นางสาวกนกพร อารยะปราการ",
    position: "กรรมการบริหาร",
  },
  {
    name: "นางกิติยา นีบเลอร์",
    position: "กรรมการบริหาร",
  },
  {
    name: "นายหาญศิริ แสงวงศ์กิจ",
    position: "กรรมการบริหาร",
  },
  {
    name: "นางสาววิชุตา แสงวงศ์กิจ",
    position: "กรรมการบริหาร",
  },
];

export const boardData2en = [
  {
    name: "Mr. Vichai Sangwongkit",
    position: "Chairman of the Executive Committee",
  },
  {
    name: "Mr. Supot Massayamas",
    position: "Member of the Executive Committee",
  },
  {
    name: "Miss Orawan Sangwongkit",
    position: "Member of the Executive Committee",
  },
  {
    name: "Mr. Thongchai Tangsupa-anan",
    position: "Member of the Executive Committee",
  },
  {
    name: "Miss Kanokporn Arayaprakarn",
    position: "Member of the Executive Committee",
  },
  {
    name: "Mrs. Kitiya Niebler",
    position: "Member of the Executive Committee",
  },
  {
    name: "Mr. Harnsiri Sangwongkit",
    position: "Member of the Executive Committee",
  },
  {
    name: "Miss Vichuta Saengvongkij",
    position: "Member of the Executive Committee",
  },
];

export const boardData3 = [
  {
    name: "นายวิชัย แสงวงศ์กิจ",
    position: "กรรมการผู้จัดการ",
  },
  {
    name: "นายหาญศิริ แสงวงศ์กิจ",
    position: "รองกรรมการผู้จัดการอาวุโส",
  },
  {
    name: "นางกิติยา นีบเลอร์",
    position: "รองกรรมการผู้จัดการฝ่ายขายและการตลาด",
  },
  {
    name: "นายธีรวิทย์ หนูสง",
    position: "รองกรรมการผู้จัดการฝ่ายผลิต",
  },
  {
    name: "นายสุพจน์ มัสยามาศ",
    position: "รองกรรมการผู้จัดการฝ่ายวิศวกรรม ตรวจสอบ และควบคุมคุณภาพ",
  },
  {
    name: "นางสาวอรวรรณ แสงวงศ์กิจ",
    position: "รองกรรมการผู้จัดการฝ่ายบัญชีและการเงิน",
  },
  {
    name: "นางสาววิชุตา แสงวงศ์กิจ",
    position: "รองกรรมการผู้จัดการฝ่ายบริหารและพัฒนาองค์กร",
  },
  {
    name: "- อยู่ระหว่างสรรหาบุคคล -",
    position: "รองกรรมการผู้จัดการฝ่ายพัฒนาธุรกิจ",
  },
  {
    name: "นางสาวพัชสนันท์ เสนุชัย",
    position: "ผู้อานวยการฝ่ายบัญชีและการเงิน",
  },
];

export const boardData3en = [
  {
    name: "Mr. Vichai Sangwongkit",
    position: "Managing Director",
  },
  {
    name: "Mr. Harnsiri Sangwongkit",
    position: "Senior Vice President",
  },
  {
    name: "Mrs. Kitiya Niebler",
    position: "Vice President of Sales and Marketing",
  },
  {
    name: "Mr. Teerawit Noosong",
    position: "Vice President of Production",
  },
  {
    name: "Mr. Supot Massayamas",
    position: "Vice President of Engineering and Quality Control",
  },
  {
    name: "Ms. Orawan Sangwongkit",
    position: "Vice President of Accounting and Finance",
  },
  {
    name: "Ms. Vichuta Saengvongkij",
    position: "Vice President of Administrative and Organization development",
  },
  {
    name: "- finding a qualified person -",
    position: "Vice President of Business Development",
  },
  {
    name: "Ms. Phatsanan Senuchai",
    position: "Director in Accounting and Finance",
  },
];
