import { FinancialDownloadData } from "../../../data/FinancialDownloadData";

export default function FinancialDownload() {
  return (
    <section className="mt-36 mb-20 lg:mt-44 container mx-auto px-4">
      <div className="box pl-4 md:pl-8 pr-8 py-4">
        <div className="flex flex-col gap-4 text-center">
          <h2 className="p1">Welcome To S.Kijchai</h2>
          <h1 className="p2 text-4xl  text-[#1b3a91] font-bold">
            ดาวน์โหลดงบการเงิน
          </h1>
          <img src="../corporate-1.png" class="corporate-dot-1" />
        </div>
      </div>

      <div class="">
        <div class="relative">
          <table class="mt-20 sm:w-full">
            <thead class="text-md md:text-xl">
              <tr>
                <th scope="col" class="px-6 py-4 text-start text-[#a5a5a5]">
                  งบการเงิน
                </th>

                <th scope="col" class="px-6 py-4 text-[#a5a5a5]">
                  วันที่
                </th>
              </tr>
            </thead>
            <tbody>
              <img src="update.gif" />
              {FinancialDownloadData.map((e) => (
                <tr class="text-md md:text-xl" key={e.id}>
                  <th
                    scope="row"
                    class="px-8 text-[#1b3a91] font-medium text-start"
                  >
                    <a href={e.filefn}>{e.namefn}</a>
                  </th>
                  <td class="px-8 text-[#666] text-end">{e.datefn}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}
