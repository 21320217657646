import { logo } from "../../assets/Logo";
import { useGlobalContext } from "../../context/Context";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getNews } from "../../Service/Service";
import { formatDateFull } from "../../utils/utils";

export default function SetNews() {
  const { language } = useGlobalContext();
  const { link } = useParams();
  const [dataNews, setDataNews] = useState([]);
  useEffect(() => {
    fetchSetNews();
  }, [language]);
  const fetchSetNews = async () => {
    let pageSize = 20;
    let currentPage = 0;
    const { status, result } = await getNews(
      language,
      currentPage,
      pageSize,
      link
    );
    if (status) {
      setDataNews(result.data);
    }
  };
  const filteredData = dataNews.filter((item) => item.newsId === link);
  const urls = filteredData
    .map((response) => {
      return response.documents.map((doc) => {
        const urlRegex = /https?:[^'"\s]+/g;
        const matches = doc.match(urlRegex);
        let url = matches ? matches[0] : null;
        url = url ? url.replace("</iframe>", "") : null;
        return url;
      });
    })
    .flat() // รวมอาร์เรย์ซ้อนกันเป็นอาร์เรย์เดียว
    .filter((url) => url !== null);
  return (
    <>
      <div className="container mx-auto ">
        <div className="flex items-center justify-center bg-slate-100 w-full">
          <img src={logo} alt="logoSTI" className=" w-[220px] max-w-full p-4" />
        </div>

        <div className="container px-4 sm:px-0">
          <div className="mt-12 flex flex-col gap-10">
            <h1 className="f30 font-bold text-primary">
              {filteredData[0]?.headline}
            </h1>
            <p className="f20">{filteredData[0]?.story}</p>
            <p className="f20 text-right">
              {formatDateFull(filteredData[0]?.publishedDate, language)}
            </p>

            {urls.map((url, index) => (
              <>
                <iframe
                  key={index}
                  className="mt-10 h-[700px] w-full"
                  src={url}
                  title="pdf"
                ></iframe>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
