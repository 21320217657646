import React from "react";
import { CardReport } from "../Components";
import { dataReportBook, dataReportBookEN } from "../../../mock/MockupReport";
import { useGlobalContext } from "../../../context/Context";
import { Header } from "../../../Components";

export default function AnnualReport() {
  const { language } = useGlobalContext();

  return (
    <div className="container-page">
      <div className="container-top">
        <Header nameTH={"รายงานประจำปี"} nameEN={"Annual Report"} />
        <div className="container-main">
          <div className="container-content-top">
            <div className="row-main">
              {language === "T"
                ? dataReportBook.map((item, i) => {
                    return (
                      <div className="col-main col-50" key={i}>
                        <CardReport {...item} />
                      </div>
                    );
                  })
                : dataReportBookEN.map((item, i) => {
                    return (
                      <div className="col-main col-50" key={i}>
                        <CardReport {...item} />
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
