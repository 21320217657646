import React from "react";
import { useGlobalContext } from "../../context/Context";

export default function FAQs() {
  const { language } = useGlobalContext();
  return (
    <>
      <section className="mt-36 mb-8 lg:mt-44 container mx-auto px-4">
        {language == "TH" ? (
          <>
            <div className="box pl-4 md:pl-8 pr-8 py-4">
              <div className="flex flex-col gap-4 text-center">
                <h2 className="p1">Welcome To S.Kijchai</h2>
                <h1 className="p2 text-3xl  text-[#1b3a91] font-bold">
                  คำถามที่ถูกถามบ่อย
                </h1>
              </div>
            </div>

            <div className="no-information">
              <p>ไม่มีข้อมูล ณ ขณะนี้</p>
            </div>
          </>
        ) : (
          <>
            <div className="box pl-4 md:pl-8 pr-8 py-4">
              <div className="flex flex-col gap-4 text-center">
                <h2 className="p1">Welcome To S.Kijchai</h2>
                <h1 className="p2 text-3xl  text-[#1b3a91] font-bold">FAQs</h1>
              </div>
            </div>

            <div className="no-information">
              <p>No Information Now</p>
            </div>
          </>
        )}
      </section>
    </>
  );
}
