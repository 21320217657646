export const generalMeetingData = [
  {
    annualGeneralMeeting:
      "หลักเกณฑ์การให้สิทธิผู้ถือหุ้นเสนอวาระการประชุมสามัญผู้ถือหุ้น",
    file: "TH_หลักเกณฑ์การให้สิทธิผู้ถือหุ้นเสนอวาระการประชุมสามัญผู้ถือหุ้น ประจำปี 2568.pdf",
    year: 2568,
  },
  {
    annualGeneralMeeting:
      "หลักเกณฑ์การให้สิทธิผู้ถือหุ้นเสนอชื่อบุคคลเพื่อเข้ารับเลือกตั้งเป็นกรรมการ",
    file: "TH_หลักเกณฑ์การให้สิทธิผู้ถือหุ้นเสนอชื่อบุคคลเพื่อเข้ารับเลือกตั้งเป็นกรรมการ 2568.pdf",
    year: 2568,
  },
  {
    annualGeneralMeeting:
      "หลักเกณฑ์การให้สิทธิผู้ถือหุ้นเสนอวาระการประชุมสามัญผู้ถือหุ้น",
    file: "TH_หลักเกณฑ์การให้สิทธิผู้ถือหุ้นเสนอวาระการประชุมสามัญผู้ถือหุ้น.pdf",
    year: 2567,
  },
  {
    annualGeneralMeeting:
      "หลักเกณฑ์การให้สิทธิผู้ถือหุ้นเสนอชื่อบุคคลเพื่อเข้ารับเลือกตั้งเป็นกรรมการ",
    file: "TH_หลักเกณฑ์การให้สิทธิผู้ถือหุ้นเสนอชื่อบุคคลเพื่อเข้ารับเลือกตั้งเป็นกรรมการ.pdf",
    year: 2567,
  },
  {
    annualGeneralMeeting: "การประชุมสามัญผู้ถือหุ้น ประจำปี 2567",
    year: 2567,
    subData: [
      {
        annualGeneralMeeting: "หนังสือเชิญประชุมสามัญผู้ถือหุ้นประจำปี 2567",
        file: "SKN_Invitation AGM 2567_TH.pdf",
        year: 2567,
      },
      {
        annualGeneralMeeting:
          "สิ่งที่ส่งมาด้วย 1	สำเนารายงานการประชุมสามัญผู้ถือหุ้นประจำปี 2566 ซึ่งประชุมเมื่อวันที่ 24 เมษายน 2566",
        file: "สิ่งที่ส่งมาด้วย1 _TH.pdf",
        year: 2567,
      },
      {
        annualGeneralMeeting:
          "สิ่งที่ส่งมาด้วย 2	แบบแสดงรายการข้อมูลประจำปี/ รายงานประจำปี 2566 (แบบ 56-1 One Report) และงบการเงินประจำปี 2566",
        file: "สิ่งที่ส่งมาด้วย2 _TH.pdf",
        year: 2567,
      },
      {
        annualGeneralMeeting:
          "สิ่งที่ส่งมาด้วย 3	ประวัติบุคคลที่เสนอชื่อให้ที่ประชุมผู้ถือหุ้นพิจารณาเลือกตั้งเป็นกรรมการบริษัท",
        file: "สิ่งที่ส่งมาด้วย3 _TH.pdf",
        year: 2567,
      },
      {
        annualGeneralMeeting:
          "สิ่งที่ส่งมาด้วย 4	รายละเอียดของผู้สอบบัญชีรับอนุญาต และกำหนดค่าตอบแทนผู้สอบบัญชี สำหรับปี 2567",
        file: "สิ่งที่ส่งมาด้วย4 _TH.pdf",
        year: 2567,
      },
      {
        annualGeneralMeeting:
          "สิ่งที่ส่งมาด้วย 5	คำชี้แจงวิธีการลงทะเบียน วิธีการมอบฉันทะ การแสดงเอกสารของผู้ถือหุ้น และ/หรือ ผู้รับมอบฉันทะของผู้ถือหุ้น การออกเสียงลงคะแนน และวิธีการนับคะแนนเสียง",
        file: "สิ่งที่ส่งมาด้วย5 _TH.pdf",
        year: 2567,
      },
      {
        annualGeneralMeeting: "สิ่งที่ส่งมาด้วย 6	หนังสือมอบฉันทะ",
        year: 2567,
        subData2: [
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะ แบบ ก.",
            file: "สิ่งที่ส่งมาด้วย6_หนังสือมอบฉันทะแบบก_TH-EN.pdf",
            year: 2567,
          },
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะ แบบ ข.",
            file: "สิ่งที่ส่งมาด้วย6_หนังสือมอบฉันทะแบบข_TH-EN.pdf",
            year: 2567,
          },
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะ แบบ ค.",
            file: "สิ่งที่ส่งมาด้วย6_หนังสือมอบฉันทะแบบค_TH-EN.pdf",
            year: 2567,
          },
        ],
        span: " (ผู้ถือหุ้นสามารถแจ้งขอหนังสือมอบฉันทะในรูปแบบเอกสารได้ทางอีเมล secretary@kijchai.co.th)",
      },
      {
        annualGeneralMeeting:
          "สิ่งที่ส่งมาด้วย 7	ข้อมูลกรรมการอิสระประกอบการมอบฉันทะของผู้ถือหุ้น และนิยามกรรมการ",
        file: "สิ่งที่ส่งมาด้วย7 _TH.pdf",
        year: 2567,
      },
      {
        annualGeneralMeeting:
          "สิ่งที่ส่งมาด้วย 8	ข้อบังคับบริษัทในส่วนที่เกี่ยวข้องกับการประชุมผู้ถือหุ้น",
        file: "สิ่งที่ส่งมาด้วย8 _TH.pdf",
        year: 2567,
      },
      {
        annualGeneralMeeting: "รายงานการประชุมสามัญผู้ถือหุ้นประจำปี 2567",
        file: "รายงานการประชุมสามัญผู้ถือหุ้นประจำปี_2567_TH.pdf",
        year: 2567,
      },
    ],
  },
  {
    annualGeneralMeeting:
      "หลักเกณฑ์การให้สิทธิผู้ถือหุ้นเสนอวาระการประชุมสามัญผู้ถือหุ้น",
    file: "agm_20221006101731_T.pdf",
    year: 2566,
  },
  {
    annualGeneralMeeting:
      "หลักเกณฑ์การให้สิทธิผู้ถือหุ้นเสนอชื่อบุคคลเพื่อเข้ารับเลือกตั้งเป็นกรรมการ",
    file: "agm_20221006102010_T.pdf",
    year: 2566,
  },

  {
    annualGeneralMeeting: "การประชุมสามัญผู้ถือหุ้น ประจำปี 2566",
    year: 2566,
    subData: [
      {
        annualGeneralMeeting: "หนังสือประชุมสามัญผู้ถือหุ้นประจำปี 2566",
        file: "agm_20230323183833_T.pdf",
        year: 2566,
      },
      {
        annualGeneralMeeting:
          "สิ่งที่ส่งมาด้วย 1 : สำเนารายงานการประชุมสามัญผู้ถือหุ้นประจำปี 2565 ซึ่งประชุมเมื่อวันที่ 26 เมษายน 2565",
        file: "agm_20230323184706_T.pdf",
        year: 2566,
      },
      {
        annualGeneralMeeting:
          "สิ่งที่ส่งมาด้วย 2 : แบบแสดงรายการข้อมูลประจำปี/ รายงานประจำปี 2565 (แบบ 56-1 One Report) และงบการเงินประจำปี 2565",
        file: "agm_20230323184858_T.pdf",
        year: 2566,
      },
      {
        annualGeneralMeeting:
          "สิ่งที่ส่งมาด้วย 3 : ประวัติบุคคลที่เสนอชื่อให้ที่ประชุมผู้ถือหุ้นพิจารณาเลือกตั้งเป็นกรรมการบริษัท",
        file: "agm_20230323185055_T.pdf",
        year: 2566,
      },
      {
        annualGeneralMeeting:
          "สิ่งที่ส่งมาด้วย 4 : รายละเอียดของผู้สอบบัญชีรับอนุญาต และกำหนดค่าตอบแทนผู้สอบบัญชี สำหรับปี 2566",
        file: "agm_20230323185144_T.pdf",
        year: 2566,
      },
      {
        annualGeneralMeeting:
          "สิ่งที่ส่งมาด้วย 5 : คำชี้แจงวิธีการลงทะเบียน วิธีการมอบฉันทะ การแสดงเอกสารของผู้ถือหุ้น และ/หรือ ผู้รับมอบฉันทะของผู้ถือหุ้น การออกเสียงลงคะแนน และวิธีการนับคะแนนเสียง",
        file: "agm_20230323185322_T.pdf",
        year: 2566,
      },
      {
        annualGeneralMeeting: "สิ่งที่ส่งมาด้วย 6 : หนังสือมอบฉันทะ",
        year: 2566,
        subData2: [
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะ แบบ ก.",
            file: "agm_20230323185449_T.pdf",
            year: 2566,
          },
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะ แบบ ข.",
            file: "agm_20230323185527_T.pdf",
            year: 2566,
          },
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะ แบบ ค.",
            file: "agm_20230323185648_T.pdf",
            year: 2566,
          },
        ],
      },
      {
        annualGeneralMeeting:
          "สิ่งที่ส่งมาด้วย 7 : ข้อมูลกรรมการอิสระประกอบการมอบฉันทะของผู้ถือหุ้น และนิยามกรรมการอิสระ",
        file: "agm_20230323185745_T.pdf",
        year: 2566,
      },
      {
        annualGeneralMeeting:
          "สิ่งที่ส่งมาด้วย 8 : ข้อบังคับบริษัทในส่วนที่เกี่ยวข้องกับการประชุมผู้ถือหุ้น",
        file: "agm_20230323185820_T.pdf",
        year: 2566,
      },
      {
        annualGeneralMeeting: "มติที่ประชุมสามัญผู้ถือหุ้น ประจำปี 2566",
        file: "agm_20230425100851_T.pdf",
        year: 2566,
      },
      {
        annualGeneralMeeting: "รายงานการประชุมสามัญผู้ถือหุ้นประจำปี 2566",
        file: "agm_20230503111513_T.pdf",
        year: 2566,
      },
    ],
  },
  {
    annualGeneralMeeting:
      "การเสนอวาระการประชุมสามัญผู้ถือหุ้น ประจำปี 2565 และเสนอชื่อบุคคลเพื่อเข้ารับเลือกตั้งเป็นกรรมการ",
    file: "agm_20211001121034_T.pdf",
    year: 2565,
  },
  {
    annualGeneralMeeting: "การประชุมสามัญผู้ถือหุ้น ประจำปี 2565",
    year: 2565,
    subData: [
      {
        annualGeneralMeeting: "หนังสือเชิญประชุมสามัญผู้ถือหุ้นประจำปี 2565",
        file: "agm_20220317170541_T.pdf",
        year: 2565,
      },
      {
        annualGeneralMeeting: "สำเนารายงานการประชุมสามัญผู้ถือหุ้นประจำปี 2564",
        file: "agm_20220317171037_T.pdf",
        year: 2565,
      },
      {
        annualGeneralMeeting: "แบบแสดงรายการข้อมูลประจำปี/รายงานประจำปี 2564",
        file: "agm_20220317171211_T.pdf",
        year: 2565,
      },
      {
        annualGeneralMeeting:
          "ประวัติบุคคลที่เสนอชื่อให้ที่ประชุมผู้ถือหุ้นพิจารณาเลือกตั้งเป็นกรรมการบริษัท",
        file: "agm_20220317171403_T.pdf",
        year: 2565,
      },
      {
        annualGeneralMeeting:
          "รายละเอียดของผู้สอบบัญชีรับอนุญาต และกำหนดค่าตอบแทนผู้สอบบัญชี สำหรับปี 2565",
        file: "agm_20220317171509_T.pdf",
        year: 2565,
      },
      {
        annualGeneralMeeting:
          "คำชี้แจงวิธีการลงทะเบียน วิธีการมอบฉันทะ การแสดงเอกสารของผู้ถือหุ้น และ/หรือ ผู้รับมอบฉันทะของผู้ถือหุ้นการออกเสียงลงคะแนนและวิธีการนับคะแนนเสียง",
        file: "agm_20220317171623_T.pdf",
        year: 2565,
      },
      {
        annualGeneralMeeting: "ขั้นตอนการประชุมผู้ถือหุ้น",
        file: "agm_20220317171742_T.pdf",
        year: 2565,
      },
      {
        annualGeneralMeeting: "หนังสือมอบฉันทะ (แบบ ก. แบบ ข. และแบบ ค.)",
        year: 2565,
        subData2: [
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะ แบบ ก",
            file: "agm_20220317171938_T.pdf",
            year: 2565,
          },
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะ แบบ ข",
            file: "agm_20220317172018_T.pdf",
            year: 2565,
          },
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะ แบบ ค",
            file: "agm_20220317172127_T.pdf",
            year: 2565,
          },
        ],
      },
      {
        annualGeneralMeeting:
          "ข้อมูลกรรมการอิสระประกอบการมอบฉันทะของผู้ถือหุ้น และนิยามกรรมการอิสระ",
        file: "agm_20220317172215_T.pdf",
        year: 2565,
      },
      {
        annualGeneralMeeting:
          "ข้อบังคับบริษัทในส่วนที่เกี่ยวข้องกับการประชุมผู้ถือหุ้น",
        file: "agm_20220317172304_T.pdf",
        year: 2565,
      },
      {
        annualGeneralMeeting:
          "แผนที่สถานที่จัดประชุม (ประชุมผ่านสื่ออิเล็กทรอนิกส์ เพียงรูปแบบเดียวเท่นั้น)",
        file: "agm_20220317172357_T.pdf",
        year: 2565,
      },
      {
        annualGeneralMeeting: "แจ้งมติที่ประชุมสามัญผู้ถือหุ้น ประจำปี 2565",
        file: "agm_20220428104948_T.pdf",
        year: 2565,
      },
      {
        annualGeneralMeeting:
          "รายงานการประชุมสามัญผู้ถือหุ้น ประจำปี 2565ผ่านสื่ออิเล็กทรอนิกส์ (E-AGM)",
        file: "agm_20220509135928_T.pdf",
        year: 2565,
      },
    ],
  },
  {
    annualGeneralMeeting:
      "หลักเกณฑ์การให้สิทธิผู้ถือหุ้นเสนอวาระการประชุมสามัญผู้ถือหุ้น",
    file: "agm_20201016182717_T.pdf",
    year: 2564,
  },
  {
    annualGeneralMeeting:
      "หลักเกณฑ์การให้สิทธิผู้ถือหุ้นเสนอชื่อบุคลเพื่อเข้ารับการเลือกตั้งเป็นกรรมการ",
    file: "agm_20201016182749_T.pdf",
    year: 2564,
  },
  {
    annualGeneralMeeting: "การประชุมสามัญผู้ถือหุ้น ประจำปี 2564",
    year: 2564,
    subData: [
      {
        annualGeneralMeeting: "หนังสือเชิญประชุมสามัญผู้ถือหุ้นประจำปี 2564",
        file: "agm_20210322095007_T.pdf",
        year: 2564,
      },
      {
        annualGeneralMeeting:
          "สำเนารายงานการประชุมสามัญผู้ถือหุ้น ประจำปี 2563 ซึ่งประชุมเมื่อวันที่ 16 กันยายน 2563",
        file: "agm_20210322095018_T.pdf",
        year: 2564,
      },
      {
        annualGeneralMeeting: "รายงานประจำปี 2563",
        file: "agm_20210322095206_T.pdf",
        year: 2564,
      },
      {
        annualGeneralMeeting:
          "ประวัติบุคคลที่เสนอชื่อให้ที่ประชุมผู้ถือหุ้นพิจารณาเลือกตั้งเป็นกรรมการบริษัท",
        file: "agm_20210322095222_T.pdf",
        year: 2564,
      },
      {
        annualGeneralMeeting:
          "รายละเอียดของผู้สอบบัญชีรับอนุญาต และกำหนดค่าตอบแทนผู้สอบบัญชี สำหรับปี 2564",
        file: "agm_20210512105607_T.pdf",
        year: 2564,
      },
      {
        annualGeneralMeeting:
          " คำชี้แจงวิธีการลงทะเบียน วิธีการมอบฉันทะ การแสดงเอกสารของผู้ถือหุ้น และ/หรือ ผู้รับมอบฉันทะของผู้ถือหุ้นการออกเสียงลงคะแนนและวิธีการนับคะแนนเสียง",
        file: "agm_20210322095245_T.pdf",
        year: 2564,
      },
      {
        annualGeneralMeeting: "ขั้นตอนการประชุมผู้ถือหุ้น",
        file: "agm_20210322095311_T.pdf",
        year: 2564,
      },
      {
        annualGeneralMeeting: "หนังสือมอบฉันทะ",
        year: 2564,
        subData2: [
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะ แบบ ก.",
            file: "agm_20210322095352_T.pdf",
            year: 2564,
          },
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะ แบบ ข.",
            file: "agm_20210322095400_T.pdf",
            year: 2564,
          },
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะ แบบ ค.",
            file: "agm_20210322095411_T.pdf",
            year: 2564,
          },
        ],
      },
      {
        annualGeneralMeeting:
          "ข้อมูลกรรมการอิสระประกอบการมอบฉันทะของผู้ถือหุ้น และนิยามกรรมการอิสระ",
        file: "agm_20210322095506_T.pdf",
        year: 2564,
      },
      {
        annualGeneralMeeting:
          "ข้อบังคับบริษัทในส่วนที่เกี่ยวข้องกับการประชุมผู้ถือหุ้น",
        file: "agm_20210322095519_T.pdf",
        year: 2564,
      },
      {
        annualGeneralMeeting: "แผนที่สถานที่จัดประชุม",
        file: "agm_20210322095531_T.pdf",
        year: 2564,
      },
      {
        annualGeneralMeeting:
          " มาตรการและแนวปฏิบัติในการร่วมประชุมสามัญผู้ถือหุ้น ประจำปี 2564 ภายใต้สถานการณ์การแพร่ระบาดของโรคติดเชื้อไวรัสโคโรนา 2019 (COVID-19)",
        file: "agm_20210322095609_T.pdf",
        year: 2564,
      },
      {
        annualGeneralMeeting: "แจ้งมติที่ประชุมสามัญผู้ถือหุ้น ประจำปี 2564",
        file: "agm_20210430190117_T.pdf",
        year: 2564,
      },
      {
        annualGeneralMeeting:
          "รายงานการประชุมสามัญผู้ถือหุ้น ประจำปี 2564ผ่านสื่ออิเล็กทรอนิกส์ (E-AGM)",
        file: "agm_20210510093538_T.pdf",
        year: 2564,
      },
    ],
  },
  {
    annualGeneralMeeting:
      "หลักเกณฑ์การให้สิทธิผู้ถือหุ้นเสนอวาระการประชุมสามัญผู้ถือหุ้น",
    file: "agm_20191002142547_T.pdf",
    year: 2563,
  },
  {
    annualGeneralMeeting:
      "หลักเกณฑ์การให้สิทธิผู้ถือหุ้นเสนอชื่อบุคลเพื่อเข้ารับการเลือกตั้งเป็นกรรมการ",
    file: "agm_20191002142633_T.pdf",
    year: 2563,
  },
  {
    annualGeneralMeeting: "การประชุมสามัญผู้ถือหุ้น ประจำปี 2563",
    year: 2563,
    subData: [
      {
        annualGeneralMeeting: "หนังสือเชิญประชุมสามัญผู้ถือหุ้นประจำปี 2563",
        file: "agm_20200319183750_T.pdf",
        year: 2563,
      },
      {
        annualGeneralMeeting:
          "สำเนารายงานการประชุมสามัญผู้ถือหุ้น ประจำปี 2562 ซึ่งประชุมเมื่อวันที่ 26 เมษายน 2562",
        file: "agm_20200319183950_T.pdf",
        year: 2563,
      },
      {
        annualGeneralMeeting: "รายงานประจำปี 2562",
        file: "agm_20200319184023_T.pdf",
        year: 2563,
      },
      {
        annualGeneralMeeting:
          "ประวัติบุคคลที่เสนอชื่อให้ที่ประชุมผู้ถือหุ้นพิจารณาเลือกตั้งเป็นกรรมการบริษัท",
        file: "agm_20200319184112_T.pdf",
        year: 2563,
      },
      {
        annualGeneralMeeting:
          "คำชี้แจงวิธีการลงทะเบียน วิธีการมอบฉันทะ การแสดงเอกสารของผู้ถือหุ้น และ/หรือ ผู้รับมอบฉันทะของผู้ถือหุ้นการออกเสียงลงคะแนนและวิธีการนับคะแนนเสียง",
        file: "agm_20200319184141_T.pdf",
        year: 2563,
      },
      {
        annualGeneralMeeting: "ขั้นตอนการประชุมผู้ถือหุ้น",
        file: "agm_20200319184231_T.pdf",
        year: 2563,
      },
      {
        annualGeneralMeeting: "หนังสือมอบฉันทะ",
        year: 2563,
        subData2: [
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะ แบบ ก.",
            file: "agm_20200319184331_T.pdf",
            year: 2563,
          },
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะ แบบ ข.",
            file: "agm_20200319184404_T.pdf",
            year: 2563,
          },
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะ แบบ ค.",
            file: "agm_20200319184443_T.pdf",
            year: 2563,
          },
        ],
      },
      {
        annualGeneralMeeting:
          "ข้อมูลกรรมการอิสระประกอบการมอบฉันทะของผู้ถือหุ้น และนิยามกรรมการอิสระ",
        file: "agm_20200319184512_T.pdf",
        year: 2563,
      },
      {
        annualGeneralMeeting:
          "ข้อบังคับบริษัทในส่วนที่เกี่ยวข้องกับการประชุมผู้ถือหุ้น",
        file: "agm_20200319184546_T.pdf",
        year: 2563,
      },
      {
        annualGeneralMeeting: "แผนที่สถานที่จัดประชุม",
        file: "agm_20200319184710_T.pdf",
        year: 2563,
      },
      {
        annualGeneralMeeting:
          "มาตรการและแนวปฏิบัติในการร่วมประชุมสามัญผู้ถือหุ้น ประจำปี 2563ภายใต้สถานการณ์การแพร่ระบาดของโรคติดเชื้อไวรัสโคโรนา 2019 (COVID-19)",
        file: "agm_20200811095731_T.pdf",
        year: 2563,
      },
      {
        annualGeneralMeeting: "มติที่ประชุมผู้ถือหุ้น",
        file: "agm_20200917094239_T.pdf",
        year: 2563,
      },
      {
        annualGeneralMeeting: "รายงานการประชุมสามัญผู้ถือหุ้น ประจำปี 2563",
        file: "agm_20200930151703_T.pdf",
        year: 2563,
      },
    ],
  },
  {
    annualGeneralMeeting:
      "หลักเกณฑ์การให้สิทธิผู้ถือหุ้นเสนอวาระการประชุมสามัญผู้ถือหุ้น",
    file: "agm_20181019141818_T.pdf",
    year: 2562,
  },
  {
    annualGeneralMeeting:
      "หลักเกณฑ์การให้สิทธิผู้ถือหุ้นเสนอชื่อบุคลเพื่อเข้ารับการเลือกตั้งเป็นกรรมการ",
    file: "agm_20181019141944_T.pdf",
    year: 2562,
  },
  {
    annualGeneralMeeting: "การประชุมสามัญผู้ถือหุ้น ประจำปี 2562",
    year: 2562,
    subData: [
      {
        annualGeneralMeeting: "หนังสือเชิญประชุมสำมัญผู้ถือหุ้น ประจำปี 2562",
        file: "agm_20190319180045_T.pdf",
        year: 2562,
      },
      {
        annualGeneralMeeting:
          "สำเนารายงานการประชุมสามัญผู้ถือหุ้น ประจำปี 2561 ซึ่งประชุมเมื่อวันที่ 24 เมษายน 2561",
        file: "agm_20190319180209_T.pdf",
        year: 2562,
      },
      {
        annualGeneralMeeting: "รายงานประจำปี 2561",
        file: "agm_20190319180314_T.pdf",
        year: 2562,
      },
      {
        annualGeneralMeeting:
          "ประวัติบุคคลที่เสนอชื่อให้ที่ประชุมผู้ถือหุ้นพิจารณาเลือกตั้งเป็นกรรมการบริษัท",
        file: "agm_20190319180350_T.pdf",
        year: 2562,
      },
      {
        annualGeneralMeeting: "รายละเอียดการแก้ไขเพิ่มเติมข้อบังคับของบริษัท",
        file: "agm_20190319180426_T.pdf",
        year: 2562,
      },
      {
        annualGeneralMeeting:
          "คำชี้แจงวิธีการลงทะเบียน วิธีการมอบฉันทะ การแสดงเอกสารของผู้ถือหุ้น และ/หรือ ผู้รับมอบฉันทะของ ผู้ถือหุ้น การออกเสียงลงคะแนนและวิธีการนับคะแนนเสียง",
        file: "agm_20190319180516_T.pdf",
        year: 2562,
      },
      {
        annualGeneralMeeting: "ขั้นตอนการประชุมผู้ถือหุ้น",
        file: "agm_20190319180616_T.pdf",
        year: 2562,
      },
      {
        annualGeneralMeeting: "หนังสือมอบฉันทะ (แบบ ก. แบบ ข. และแบบ ค.)",
        year: 2562,
        subData2: [
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะ แบบ ก.",
            file: "agm_20190319180731_T.pdf",
            year: 2562,
          },
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะ แบบ ข.",
            file: "agm_20190319180803_T.pdf",
            year: 2562,
          },
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะ แบบ ค.",
            file: "agm_20190319180830_T.pdf",
            year: 2562,
          },
        ],
      },
      {
        annualGeneralMeeting:
          "ข้อมูลกรรมการอิสระประกอบการมอบฉันทะของผู้ถือหุ้น และนิยามกรรมการอิสระ",
        file: "agm_20190319180905_T.pdf",
        year: 2562,
      },
      {
        annualGeneralMeeting:
          "ข้อบังคับบริษัทในส่วนที่เกี่ยวข้องกับการประชุมผู้ถือหุ้น",
        file: "agm_20190319180949_T.pdf",
        year: 2562,
      },
      {
        annualGeneralMeeting: "แผนที่สถานที่จัดประชุม",
        file: "agm_20190319181021_T.pdf",
        year: 2562,
      },
      {
        annualGeneralMeeting: "มติที่ประชุมผู้ถือหุ้น",
        file: "agm_20190429165811_T.pdf",
        year: 2562,
      },
    ],
  },
  {
    annualGeneralMeeting:
      "หลักเกณฑ์การให้สิทธิผู้ถือหุ้นเสนอวาระการประชุมสามัญผู้ถือหุ้นและแบบฟอร์มการเสนอวาระ",
    file: "agm_20171229112805_T.pdf",
    year: 2561,
  },
  {
    annualGeneralMeeting:
      "หลักเกณฑ์การให้สิทธิผู้ถือหุ้นเสนอชื่อบุคคลเพื่อเข้ารับเลือกตั้งเป็นกรรมการ,แบบฟอร์มการเสนอชื่อบุคคลเพื่อเลือกตั้งเป็นกรรมการบริษัท,หนังสือรับรองและประวัติของผู้ที่ได้รับการเสนอชื่อ",
    file: "agm_20171229113030_T.pdf",
    year: 2561,
  },
  {
    annualGeneralMeeting: "การประชุมสามัญผู้ถือหุ้น ประจำปี 2561",
    year: 2561,
    subData: [
      {
        annualGeneralMeeting: "หนังสือเชิญประชุมสามัญผู้ถือหุ้น ประจำปี 2561",
        file: "agm_20180403172622_T.pdf",
        year: 2561,
      },
      {
        annualGeneralMeeting: "แบบฟอร์มลงทะเบียน (โปรดนำมาแสดงในวันประชุม)",
        year: 2561,
      },
      {
        annualGeneralMeeting:
          "สำเนารายงานการประชุม สามัญผู้ถือหุ้น ประจำปี 2560 ซึ่งประชุม เมื่อ วันที่ 6 มีนาคม 2560",
        file: "agm_20180403172805_T.pdf",
        year: 2561,
      },
      {
        annualGeneralMeeting: "รายงานประจำปี 2560",
        file: "agm_20180403172919_T.pdf",
        year: 2561,
      },
      {
        annualGeneralMeeting:
          "ประวัติบุคคลที่เสนอชื่อให้ที่ประชุมผู้ถือหุ้นพิจารณาเลือกตั้งเป็นกรรมการบริษัท",
        file: "agm_20180403172936_T.pdf",
        year: 2561,
      },
      {
        annualGeneralMeeting:
          "คำชี้แจงวิธีการลงทะเบียน วิธีการมอบฉันทะ การแสดงเอกสารของผู้ถือหุ้นและ/หรือผู้มอบฉันทะ ของผู้ถือหุ้น การออกเสียงลงคะแนนและวิธีการนบัคะแนนเสียง",
        file: "agm_20180403173043_T.pdf",
        year: 2561,
      },
      {
        annualGeneralMeeting: "ขั้นตอนการเข้าประชุมผู้ถือหุ้น",
        file: "agm_20180403173138_T.pdf",
        year: 2561,
      },
      {
        annualGeneralMeeting: "หนังสือมอบฉันทะ",
        year: 2561,
        subData2: [
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะแบบ ก.",
            file: "agm_20180403173248_T.pdf",
            year: 2561,
          },
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะแบบ ข.",
            file: "agm_20180403173305_T.pdf",
            year: 2561,
          },
          {
            annualGeneralMeeting: "หนังสือมอบฉันทะแบบ ค.",
            file: "agm_20180403173324_T.pdf",
            year: 2561,
          },
        ],
      },
      {
        annualGeneralMeeting:
          "ข้อมูลกรรมการอิสระประกอบการมอบฉันทะของผู้ถือหุ้น และนิยามกรรมการอิสระ",
        file: "agm_20180403173451_T.pdf",
        year: 2561,
      },
      {
        annualGeneralMeeting:
          "ข้อบังคับบริษัทในส่วนที่เกี่ยวข้องกับการประชมุผ้ถูือหุ้น",
        file: "agm_20180403173541_T.pdf",
        year: 2561,
      },
      {
        annualGeneralMeeting: "แผนที่สถานที่จัดประชุม",
        file: "agm_20180403173633_T.pdf",
        year: 2561,
      },
      {
        annualGeneralMeeting: "มติที่ประชุมผู้ถือหุ้น",
        file: "agm_20180425183925_T.pdf",
        year: 2561,
      },
    ],
  },
];

export const generalMeetingDataEN = [
  {
    annualGeneralMeeting:
      "Criteria for Shareholders to propose an agenda at an Annual General Meeting of Shareholder",
    file: "Criteria for Shareholders to propose an agenda at an Annual General Meeting of Shareholder_2025.pdf",
    year: 2025,
  },
  {
    annualGeneralMeeting: "Criteria for Shareholders to Nominate a Director",
    file: "Criteria for Shareholders to Nominate a Director_2025.pdf",
    year: 2025,
  },
  {
    annualGeneralMeeting:
      "Criteria for Shareholders to propose an agenda at an Annual General Meeting of Shareholder",
    file: "Criteria for Shareholders to propose an agenda at an Annual General Meeting of Shareholder.pdf",
    year: 2024,
  },
  {
    annualGeneralMeeting: "Criteria for Shareholders to Nominate a Director",
    file: "Criteria for Shareholders to Nominate a Director.pdf",
    year: 2024,
  },
  {
    annualGeneralMeeting: "General Meeting of Shareholders 2024",
    year: 2024,
    subData: [
      {
        annualGeneralMeeting:
          "Invitation to the Annual General Meeting of Shareholders 2024",
        file: "SKN_Invitation AGM 2567_EN.pdf",
        year: 2024,
      },
      {
        annualGeneralMeeting:
          "Enclosures 1 Copy of the Minutes of the 2023 Annual General Meeting of Shareholders, held on 24 April 2023",
        file: "Enclosure1 _EN.pdf",
        year: 2024,
      },
      {
        annualGeneralMeeting:
          "Enclosures 2	The 2023 Annual Registration Statement (Form 56-1 One Report)",
        file: "Enclosure2 _EN.pdf",
        year: 2024,
      },
      {
        annualGeneralMeeting:
          "Enclosures 3	Profiles of the Candidates Nominated for Election as Directors",
        file: "Enclosure3 _EN.pdf",
        year: 2024,
      },
      {
        annualGeneralMeeting:
          "Enclosures 4	Details of the Auditors and Audit Fee for the Year 2024",
        file: "Enclosure4 _EN.pdf",
        year: 2024,
      },
      {
        annualGeneralMeeting:
          "Enclosures 5	Guidelines for the Registration, the Appointment of Proxy, Documents to be Presented by Shareholders and/or Proxy, Votes Casting and Votes Counting",
        file: "Enclosure5 _EN.pdf",
        year: 2024,
      },
      {
        annualGeneralMeeting: "Enclosures 6	Proxy Form",
        year: 2024,
        subData2: [
          {
            annualGeneralMeeting: "Proxy Form A",
            file: "Enclosure_proxyA_TH-EN.pdf",
            year: 2024,
          },
          {
            annualGeneralMeeting: "Proxy Form B",
            file: "Enclosure_proxyB_TH-EN.pdf",
            year: 2024,
          },
          {
            annualGeneralMeeting: "Proxy Form C",
            file: "Enclosure_proxyC_TH-EN.pdf",
            year: 2024,
          },
        ],
        span: " (The shareholder can request the Proxy Form in paper form via email at  secretary@kijchai.co.th)",
      },
      {
        annualGeneralMeeting:
          "Enclosures 7	Profiles of the Independent Directors proposed to act as proxy for the Shareholders and Definition of Independent Directors",
        file: "Enclosure7 _EN.pdf",
        year: 2024,
      },
      {
        annualGeneralMeeting:
          "Enclosures 8	The Company’s Articles of Association with Respect to the Meeting of Shareholders",
        file: "Enclosure8 _EN.pdf",
        year: 2024,
      },
      {
        annualGeneralMeeting:
          "Minutes of the Annual General Meeting of Shareholders Year 2024",
        file: "รายงานการประชุมสามัญผู้ถือหุ้นประจำปี_2567_EN.pdf",
        year: 2023,
      },
    ],
  },

  {
    annualGeneralMeeting:
      "Criteria for Shareholders to propose an agenda at an Annual General Meeting of Shareholder",
    file: "agm_20221006101731_T.pdf",
    year: 2023,
  },
  {
    annualGeneralMeeting: "Criteria for Shareholders to Nominate a Director",
    file: "agm_20221006102010_T.pdf",
    year: 2023,
  },

  {
    annualGeneralMeeting: "General Meeting of Shareholders 2023",
    year: 2023,
    subData: [
      {
        annualGeneralMeeting:
          "Invitation to the 2023 Annual General Meeting of Shareholders",
        file: "agm_20230323183833_T.pdf",
        year: 2023,
      },
      {
        annualGeneralMeeting:
          "Enclosures 1 : Copy of the Minutes of the 2022 Annual General Meeting of Shareholders, held on 26 April 2022",
        file: "agm_20230323184706_T.pdf",
        year: 2023,
      },
      {
        annualGeneralMeeting:
          "Enclosures 2 : The 2022 Annual Registration Statement (Form 56-1 One Report)",
        file: "agm_20230323184858_T.pdf",
        year: 2023,
      },
      {
        annualGeneralMeeting:
          "Enclosures 3 : Profiles of the Candidates Nominated for Election as Directors",
        file: "agm_20230323185055_T.pdf",
        year: 2023,
      },
      {
        annualGeneralMeeting:
          "Enclosures 4 : Details of the Auditors and Audit Fee for the Year 2023",
        file: "agm_20230323185144_T.pdf",
        year: 2023,
      },
      {
        annualGeneralMeeting:
          "Enclosures 5 : Guidelines for the Registration, the Appointment of Proxy, Documents to be Presented by Shareholders and/or Proxy, Votes Casting and Votes Counting",
        file: "agm_20230323185322_T.pdf",
        year: 2023,
      },
      {
        annualGeneralMeeting: "Enclosures 6 : Proxy Form",
        year: 2023,
        subData2: [
          {
            annualGeneralMeeting: "Proxy Form A",
            file: "agm_20230323185449_T.pdf",
            year: 2023,
          },
          {
            annualGeneralMeeting: "Proxy Form B",
            file: "agm_20230323185527_T.pdf",
            year: 2023,
          },
          {
            annualGeneralMeeting: "Proxy Form C",
            file: "agm_20230323185648_T.pdf",
            year: 2023,
          },
        ],
      },
      {
        annualGeneralMeeting:
          "Enclosures 7 : Profiles of the Independent Directors proposed to act as proxy for the Shareholders and Definition of Independent Directors",
        file: "agm_20230323185745_T.pdf",
        year: 2023,
      },
      {
        annualGeneralMeeting:
          "Enclosures 8 : The Company’s Articles of Association with Respect to the Meeting of Shareholders",
        file: "agm_20230323185820_T.pdf",
        year: 2023,
      },
      {
        annualGeneralMeeting:
          "Notification of the resolutions of the Annual General Meeting of Shareholders 2023",
        file: "agm_20230425100851_T.pdf",
        year: 2023,
      },
      {
        annualGeneralMeeting:
          "Minutes of the Annual General Meeting of Shareholders Year 2023",
        file: "agm_20230503111513_T.pdf",
        year: 2023,
      },
    ],
  },
  {
    annualGeneralMeeting:
      "The proposal of meeting agenda & director nomination for the 2022 Annual General Meeting of Shareholder.",
    file: "agm_20211001121034_T.pdf",
    year: 2022,
  },
  {
    annualGeneralMeeting: "General Meeting of Shareholders 2022",
    year: 2022,
    subData: [
      {
        annualGeneralMeeting:
          "Invitation to the 2022 Annual General Meeting of Shareholders",
        file: "agm_20220317170541_T.pdf",
        year: 2022,
      },
      {
        annualGeneralMeeting:
          "Copy of the minutes of the 2021 Annual General Meeting of Shareholders",
        file: "agm_20220317171037_T.pdf",
        year: 2022,
      },
      {
        annualGeneralMeeting:
          "56-1 One Report and the 2021 Financial Statement",
        file: "agm_20220317171211_T.pdf",
        year: 2022,
      },
      {
        annualGeneralMeeting:
          "Profiles of candidates nominated for election as directors",
        file: "agm_20220317171403_T.pdf",
        year: 2022,
      },
      {
        annualGeneralMeeting:
          "Details of the auditors and audit fee for the year 2022",
        file: "agm_20220317171509_T.pdf",
        year: 2022,
      },
      {
        annualGeneralMeeting:
          "Guidelines for the registration, the appointment of proxy, documents to be presented by Shareholdersand/or proxy on the meeting date, votes casting, and counting.",
        file: "agm_20220317171623_T.pdf",
        year: 2022,
      },
      {
        annualGeneralMeeting: "Meeting Procedures",
        file: "agm_20220317171742_T.pdf",
        year: 2022,
      },
      {
        annualGeneralMeeting: "Proxy Form A, Form B, and Form C",
        year: 2022,
        subData2: [
          {
            annualGeneralMeeting: "Proxy Form A",
            file: "agm_20220317171938_T.pdf",
            year: 2022,
          },
          {
            annualGeneralMeeting: "Proxy Form B",
            file: "agm_20220317172018_T.pdf",
            year: 2022,
          },
          {
            annualGeneralMeeting: "Proxy Form C",
            file: "agm_20220317172127_T.pdf",
            year: 2022,
          },
        ],
      },
      {
        annualGeneralMeeting:
          "Profiles of Independent Directors for Appointment of Proxy by the Shareholders and Definition of Independent Director of the Company",
        file: "agm_20220317172215_T.pdf",
        year: 2022,
      },
      {
        annualGeneralMeeting:
          "The Company’s Articles of Association relating to the Shareholders’ Meeting",
        file: "agm_20220317172304_T.pdf",
        year: 2022,
      },
      {
        annualGeneralMeeting:
          "Map of the Meeting Venue (Electronic Meeting (E-AGM) only)",
        file: "agm_20220317172357_T.pdf",
        year: 2022,
      },
      {
        annualGeneralMeeting: "Shareholders meeting`s resolution",
        file: "agm_20220428104948_T.pdf",
        year: 2022,
      },
      {
        annualGeneralMeeting:
          "Minutes of the 2022 Annual General Meeting of ShareholdersConducted Through Electronic Meeting (E-AGM)",
        file: "agm_20220509135928_T.pdf",
        year: 2022,
      },
    ],
  },
  {
    annualGeneralMeeting:
      "Criteria for Shareholders to Propose an Agenda at an Annual General Meeting of Shareholder",
    file: "agm_20201016182717_T.pdf",
    year: 2021,
  },
  {
    annualGeneralMeeting: "Criteria for Shareholders to Nominate a Director",
    file: "agm_20201016182749_T.pdf",
    year: 2021,
  },
  {
    annualGeneralMeeting: "General Meeting of Shareholders 2021",
    year: 2021,
    subData: [
      {
        annualGeneralMeeting:
          "Invitation to the Annual General Meeting of Shareholders 2021",
        file: "agm_20210322095007_T.pdf",
        year: 2021,
      },
      {
        annualGeneralMeeting:
          "Copy of the minutes of the 2019 Annual General Meeting of Shareholders, held on 16 September 2020.",
        file: "agm_20210322095018_T.pdf",
        year: 2021,
      },
      {
        annualGeneralMeeting: "Annual Report 2020",
        file: "agm_20210322095206_T.pdf",
        year: 2021,
      },
      {
        annualGeneralMeeting:
          "Profiles of candidates nominated for election as directors",
        file: "agm_20210322095222_T.pdf",
        year: 2021,
      },
      {
        annualGeneralMeeting:
          "Details of the auditors and audit fee for the year 2021",
        file: "agm_20210512105607_T.pdf",
        year: 2021,
      },
      {
        annualGeneralMeeting:
          "Guidelines for the registration, the appointment of proxy, documents to be presented by Shareholders and/or proxy on the meeting date, votes casting, and counting.ง",
        file: "agm_20210322095245_T.pdf",
        year: 2021,
      },
      {
        annualGeneralMeeting: "Meeting Procedures",
        file: "agm_20210322095311_T.pdf",
        year: 2021,
      },
      {
        annualGeneralMeeting: "Proxy Form",
        year: 2021,
        subData2: [
          {
            annualGeneralMeeting: "Proxy Form A",
            file: "agm_20210322095352_T.pdf",
            year: 2021,
          },
          {
            annualGeneralMeeting: "Proxy Form B",
            file: "agm_20210322095400_T.pdf",
            year: 2021,
          },
          {
            annualGeneralMeeting: "Proxy Form C",
            file: "agm_20210322095411_T.pdf",
            year: 2021,
          },
        ],
      },
      {
        annualGeneralMeeting:
          "Profiles of Independent Directors for Appointment of Proxy by the Shareholders and Definition of Independent Director of the Company",
        file: "agm_20210322095506_T.pdf",
        year: 2021,
      },
      {
        annualGeneralMeeting:
          "The Company’s Articles of Association relating to the Shareholders’ Meeting",
        file: "agm_20210322095519_T.pdf",
        year: 2021,
      },
      {
        annualGeneralMeeting: "Map of the Meeting Venue",
        file: "agm_20210322095531_T.pdf",
        year: 2021,
      },
      {
        annualGeneralMeeting:
          "Measures and Guidelines for Joining the 2021 Annual General Meeting of Shareholders under the COVID-19 Situation",
        file: "agm_20210322095609_T.pdf",
        year: 2021,
      },
      {
        annualGeneralMeeting: "Shareholders meeting`s resolution",
        file: "agm_20210430190117_T.pdf",
        year: 2021,
      },
      {
        annualGeneralMeeting:
          "Minutes of the 2021 Annual General Meeting of ShareholdersConducted Through Electronic Meeting (E-AGM)",
        file: "agm_20210510093538_T.pdf",
        year: 2021,
      },
    ],
  },
  {
    annualGeneralMeeting: "General Meeting of Shareholders 2020",
    year: 2020,
    subData: [
      {
        annualGeneralMeeting:
          "Invitation to the Annual General Meeting of Shareholders 2020",
        file: "agm_20200319183750_T.pdf",
        year: 2020,
      },
      {
        annualGeneralMeeting:
          "Copy of the minutes of the 2019 Annual General Meeting of Shareholders, held on 26 April 2019.",
        file: "agm_20200319183950_T.pdf",
        year: 2020,
      },
      {
        annualGeneralMeeting: "Annual Report 2019",
        file: "agm_20200319184023_T.pdf",
        year: 2020,
      },
      {
        annualGeneralMeeting:
          "Profiles of candidates nominated for election as directors",
        file: "agm_20200319184112_T.pdf",
        year: 2020,
      },
      {
        annualGeneralMeeting:
          "Guidelines for the registration, the appointment of proxy, documents to be presented by Shareholders and/or proxy on the meeting date, votes casting, and counting.",
        file: "agm_20200319184141_T.pdf",
        year: 2020,
      },
      {
        annualGeneralMeeting: "Meeting Procedures",
        file: "agm_20200319184231_T.pdf",
        year: 2020,
      },
      {
        annualGeneralMeeting: "Proxy Form",
        year: 2020,
        subData2: [
          {
            annualGeneralMeeting: "Proxy Form A",
            file: "agm_20200319184331_T.pdf",
            year: 2020,
          },
          {
            annualGeneralMeeting: "Proxy Form B",
            file: "agm_20200319184404_T.pdf",
            year: 2020,
          },
          {
            annualGeneralMeeting: "Proxy Form C",
            file: "agm_20200319184443_T.pdf",
            year: 2020,
          },
        ],
      },
      {
        annualGeneralMeeting:
          "Profiles of Independent Directors for Appointment of Proxy by the Shareholders and Definition of Independent Director of the Company",
        file: "agm_20200319184512_T.pdf",
        year: 2020,
      },
      {
        annualGeneralMeeting:
          "The Company’s Articles of Association relating to the Shareholders’ Meeting",
        file: "agm_20200319184546_T.pdf",
        year: 2020,
      },
      {
        annualGeneralMeeting: "Map of the Meeting Venue",
        file: "agm_20200319184710_T.pdf",
        year: 2020,
      },
      {
        annualGeneralMeeting:
          "Measures and Guidelines for Joining the 2020 Annual General Meeting of Shareholders under the COVID-19 Situation",
        file: "agm_20200811095731_T.pdf",
        year: 2020,
      },
      {
        annualGeneralMeeting: "Shareholders meeting`s resolution",
        file: "agm_20200917094239_T.pdf",
        year: 2020,
      },
      {
        annualGeneralMeeting:
          "Minutes of the Annual General Meeting of Shareholders 2020",
        file: "agm_20200930151703_T.pdf",
        year: 2020,
      },
    ],
  },
  {
    annualGeneralMeeting:
      "Criteria for Shareholders to Propose an Agenda at an Annual General Meeting of Shareholder",
    file: "agm_20181019141818_T.pdf",
    year: 2019,
  },
  {
    annualGeneralMeeting: "Criteria for Shareholders to Nominate a Director",
    file: "agm_20181019141944_T.pdf",
    year: 2019,
  },
  {
    annualGeneralMeeting: "Annual General Meeting of Shareholders 2019",
    year: 2019,
    subData: [
      {
        annualGeneralMeeting:
          "Invitation to the 2019 Annual General Meeting of Shareholders",
        file: "agm_20190319180045_T.pdf",
        year: 2019,
      },
      {
        annualGeneralMeeting:
          "Copy of the Minutes of the Annual General Meeting of Shareholders 2018",
        file: "agm_20190319180209_T.pdf",
        year: 2019,
      },
      {
        annualGeneralMeeting:
          "Annual Report 2018 and the Financial Statements for the year ended 31 December 2018",
        file: "agm_20190319180314_T.pdf",
        year: 2019,
      },
      {
        annualGeneralMeeting: "Directors’ Profile",
        file: "agm_20190319180350_T.pdf",
        year: 2019,
      },
      {
        annualGeneralMeeting:
          "Details of the Amendment and Addition of the Company’s Articles of Association",
        file: "agm_20190319180426_T.pdf",
        year: 2019,
      },
      {
        annualGeneralMeeting:
          "Guidelines for the registration, the appointment of proxy, documents to be presented by Shareholders and/or proxy on the meeting date, votes casting and counting",
        file: "agm_20190319180516_T.pdf",
        year: 2019,
      },
      {
        annualGeneralMeeting: "Meeting Procedures",
        file: "agm_20190319180616_T.pdf",
        year: 2019,
      },
      {
        annualGeneralMeeting: "Proxy Form A, Form B and Form C",
        year: 2019,
        subData2: [
          {
            annualGeneralMeeting: "Proxy Form A",
            file: "agm_20190319180731_T.pdf",
            year: 2019,
          },
          {
            annualGeneralMeeting: "Proxy Form B",
            file: "agm_20190319180803_T.pdf",
            year: 2019,
          },
          {
            annualGeneralMeeting: "Proxy Form C",
            file: "agm_20190319180830_T.pdf",
            year: 2019,
          },
        ],
      },
      {
        annualGeneralMeeting:
          "Profiles of Independent Directors for Appointment of Proxy by the Shareholders and Definition of Independent Director of the Company",
        file: "agm_20190319180905_T.pdf",
        year: 2019,
      },
      {
        annualGeneralMeeting:
          "The Company’s Articles of Association relating to the Shareholders Meeting",
        file: "agm_20190319180949_T.pdf",
        year: 2019,
      },
      {
        annualGeneralMeeting: "Map of the Meeting Venue",
        file: "agm_20190319181021_T.pdf",
        year: 2019,
      },
      {
        annualGeneralMeeting: "Shareholders meeting`s resolution",
        file: "agm_20190429165811_T.pdf",
        year: 2019,
      },
    ],
  },
  {
    annualGeneralMeeting:
      "Criteria for Shareholders to Propose an Agenda at an Annual General Meeting of Shareholder and AGM Agenda Proposal Form",
    file: "agm_20171229112805_T.pdf",
    year: 2018,
  },
  {
    annualGeneralMeeting:
      "Criteria for Shareholders to Nominate a Director,Director Nomination Form,Representations and Bio-Data of Candidate",
    file: "agm_20171229113030_T.pdf",
    year: 2018,
  },
  {
    annualGeneralMeeting: "Annual General Meeting of Shareholders 2018",
    year: 2018,
    subData: [
      {
        annualGeneralMeeting:
          "Invitation to the 2018 Annual General Meeting of Shareholders",
        file: "agm_20180403172622_T.pdf",
        year: 2018,
      },
      {
        annualGeneralMeeting:
          "Registration Form (Document declare name and number of securities hold)",
        year: 2018,
      },
      {
        annualGeneralMeeting:
          "Copy of the Minutes of the Annual General Meeting of Shareholders 2017",
        file: "agm_20180403172805_T.pdf",
        year: 2018,
      },
      {
        annualGeneralMeeting: "Annual Report 2017",
        file: "agm_20180403172919_T.pdf",
        year: 2018,
      },
      {
        annualGeneralMeeting: "Directors’ Profile",
        file: "agm_20180403172936_T.pdf",
        year: 2018,
      },
      {
        annualGeneralMeeting:
          "Guidelines for the registration, the appointment of proxy, documents to be presented by Shareholders and/or proxy on the meeting date, votes casting and counting",
        file: "agm_20180403173043_T.pdf",
        year: 2018,
      },
      {
        annualGeneralMeeting: "Meeting Procedures",
        file: "agm_20180403173138_T.pdf",
        year: 2018,
      },
      {
        annualGeneralMeeting: "Proxy Form",
        year: 2018,
        subData2: [
          {
            annualGeneralMeeting: "Proxy Form A,",
            file: "agm_20180403173248_T.pdf",
            year: 2018,
          },
          {
            annualGeneralMeeting: "Proxy Form B,",
            file: "agm_20180403173305_T.pdf",
            year: 2018,
          },
          {
            annualGeneralMeeting: "Proxy Form C,",
            file: "agm_20180403173324_T.pdf",
            year: 2018,
          },
        ],
      },
      {
        annualGeneralMeeting:
          "Profiles of Independent Directors for Appointment of Proxy by the Shareholders and Definition of Independent Director of the Company",
        file: "agm_20180403173451_T.pdf",
        year: 2018,
      },
      {
        annualGeneralMeeting:
          "The Company’s Articles of Association relating to the Shareholders Meeting",
        file: "agm_20180403173541_T.pdf",
        year: 2018,
      },
      {
        annualGeneralMeeting: "Map of the Meeting Venue",
        file: "agm_20180403173633_T.pdf",
        year: 2018,
      },
      {
        annualGeneralMeeting: "Shareholders meeting`s resolution",
        file: "agm_20180425183925_T.pdf",
        year: 2018,
      },
    ],
  },
];
