import React from "react";
import { globlex } from "../../assets/Logo";
import { update } from "../../assets/Logo";
import { useGlobalContext } from "../../context/Context";
import { Link } from "react-router-dom";

export default function Analysis() {
  const { language, changeLanguage } = useGlobalContext();
  return (
    <section className="mt-36 mb-8 lg:mt-44 container mx-auto px-4">
      {language === "E" ? (
        <>
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-3xl  text-[#1b3a91] font-bold">
                Analyst Report
              </h1>
            </div>
          </div>
          <div
            class="relative overflow-x-auto"
            style={{ margin: "5rem 0 10rem" }}
          >
            <table class="w-full table-respon mt-4 text-sm text-center text-white">
              <thead class="text-md text-white bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                <tr>
                  <th scope="col" class="px-6 py-4 text-left">
                    Date
                  </th>
                  <th scope="col" class="px-6 py-4 text-left">
                    Title
                  </th>
                  <th scope="col" class="px-6 py-4 text-left">
                    Analyst
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base">
                  <td
                    scope="row"
                    class="px-6 py-4 text-[#1b3a91] font-medium text-left"
                  >
                    30 Nov 2022
                  </td>
                  <td class="px-6 py-4 text-[#666] text-left">
                    <Link
                      to="../pdf/analysis/Company_SKN_301122.pdf"
                      target="_blank"
                      className="flex"
                    >
                      Company Visit : SKN{" "}
                      <img
                        src={update}
                        alt=""
                        className="h-fit w-fit pt-1 ps-1"
                      />
                    </Link>
                  </td>
                  <td class="px-6 py-4 text-[#666] text-left">
                    <img src={globlex} alt="" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-3xl  text-[#1b3a91] font-bold">
                บทวิเคราะห์
              </h1>
            </div>
          </div>
          <div
            class="relative overflow-x-auto"
            style={{ margin: "5rem 0 10rem" }}
          >
            <table class="w-full table-respon mt-4 text-sm text-center text-white">
              <thead class="text-md text-white bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                <tr>
                  <th scope="col" class="px-6 py-4 text-left">
                    วันที่
                  </th>
                  <th scope="col" class="px-6 py-4 text-left">
                    บทวิเคราะห์
                  </th>
                  <th scope="col" class="px-6 py-4 text-left">
                    บริษัทหลักทรัพย์
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base">
                  <td
                    scope="row"
                    class="px-6 py-4 text-[#1b3a91] font-medium text-left"
                  >
                    30 พ.ย. 2565
                  </td>
                  <td class="px-6 py-4 text-[#666] text-left">
                    <Link
                      to="../pdf/analysis/Company_SKN_301122.pdf"
                      target="_blank"
                      className="flex"
                    >
                      Company Visit : SKN{" "}
                      <img
                        src={update}
                        alt=""
                        className="h-fit w-fit pt-1 ps-1"
                      />
                    </Link>
                  </td>
                  <td class="px-6 py-4 text-[#666] text-left">
                    <img src={globlex} alt="" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </section>
  );
}
