import React from "react";
import ReactDOM from "react-dom/client";
import "./indexD.css";
import "./indexI.css";
import "./indexTailwind.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { Router } from "./Router";
import { Footer, Navbar } from "./Components";
import { AppProvider } from "./context/Context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppProvider>
    <React.StrictMode>
      {/* <Navbar /> */}
      <RouterProvider router={Router} />
      {/* <Footer /> */}
    </React.StrictMode>
  </AppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
