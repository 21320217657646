export const MultiMediaData = [
  {
    id: 0,
    activity: "บริษัทจดทะเบียนพบผู้ลงทุนสำหรับผลประกอบการไตรมาส 2/2567",
    youtubeLink: "https://listed-company-presentation.setgroup.or.th/vdo/6783",
    file: "../pdf/multimedia/opportunityQ2_2024.pdf",
  },
  {
    id: 1,
    activity: "บริษัทจดทะเบียนพบผู้ลงทุนสำหรับผลประกอบการไตรมาส 1/2567",
    youtubeLink: "https://www.youtube.com/live/8832SlMWR7A",
    file: "../pdf/multimedia/opportunityQ1_2024.pdf",
  },
  {
    id: 2,
    activity: "การประชุมสามัญผู้ถือหุ้นประจำปี 2567",
    youtubeLink: null,
    file: null,
  },
  {
    id: 3,
    activity: "บริษัทจดทะเบียนพบผู้ลงทุนสำหรับผลประกอบการไตรมาส 4/2566",
    youtubeLink: "https://www.youtube.com/live/L5pzF99VpLQ",
    file: "../pdf/multimedia/opportunityQ4_2023.pdf",
  },
  {
    id: 4,
    activity: "บริษัทจดทะเบียนพบผู้ลงทุนสำหรับผลประกอบการไตรมาส 3/2566",
    youtubeLink: "https://www.youtube.com/live/-fazP6qGHd8",
    file: "../pdf/multimedia/opportunityQ3_2023.pdf",
  },
  {
    id: 5,
    activity: "บริษัทจดทะเบียนพบผู้ลงทุนสำหรับผลประกอบการไตรมาส 2/2566",
    youtubeLink: "https://www.youtube.com/watch?v=d1KSCpNLvec",
    file: "../pdf/multimedia/multimedia01.pdf",
  },
  {
    id: 6,
    activity: "การประชุมสามัญผู้ถือหุ้นประจำปี 2566",
    youtubeLink: null,
    file: null,
  },
  {
    id: 7,
    activity: "บริษัทจดทะเบียนพบผู้ลงทุนสำหรับผลประกอบการไตรมาส 4/2565",
    youtubeLink: "https://www.youtube.com/watch?v=hYjE8lqn3aw",
    file: "../pdf/multimedia/multimedia03.pdf",
  },
  {
    id: 8,
    activity: "บริษัทจดทะเบียนพบผู้ลงทุนสำหรับผลประกอบการไตรมาส 2/2565",
    youtubeLink: "https://www.youtube.com/watch?v=Sby0yX98l-U",
    file: "../pdf/multimedia/multimedia04.pdf",
  },
  {
    id: 9,
    activity: "การประชุมสามัญผู้ถือหุ้นประจำปี 2565",
    youtubeLink: null,
    file: null,
  },
  {
    id: 10,
    activity: "บริษัทจดทะเบียนพบผู้ลงทุนสำหรับผลประกอบการไตรมาส 4/2564",
    youtubeLink: "https://www.youtube.com/watch?v=WbX7knC7DBw",
    file: "../pdf/multimedia/multimedia06.pdf",
  },
  {
    id: 11,
    activity: "บริษัทจดทะเบียนพบผู้ลงทุนสำหรับผลประกอบการไตรมาส 3/2564",
    youtubeLink: "https://www.youtube.com/watch?v=jFtoaeJdj7w",
    file: "../pdf/multimedia/multimedia07.pdf",
  },
  {
    id: 12,
    activity: "บริษัทจดทะเบียนพบผู้ลงทุนสำหรับผลประกอบการไตรมาส 2/2564",
    youtubeLink: "https://www.youtube.com/watch?v=xX70IVAAKE0",
    file: "../pdf/multimedia/multimedia08.pdf",
  },
  {
    id: 13,
    activity: "การประชุมสามัญผู้ถือหุ้นประจำปี 2564",
    youtubeLink: null,
    file: null,
  },
  {
    id: 14,
    activity: "การประชุมสามัญผู้ถือหุ้นประจำปี 2563",
    youtubeLink: null,
    file: null,
  },
  {
    id: 15,
    activity: "บริษัทจดทะเบียนพบผู้ลงทุน สำหรับผลประกอบการไตรมาส 2/2563",
    youtubeLink: null,
    file: "../pdf/multimedia/multimedia11.pdf",
  },
  {
    id: 16,
    activity: "การประชุมสามัญผู้ถือหุ้นประจำปี 2562",
    youtubeLink: null,
    file: null,
  },
  {
    id: 17,
    activity: "บริษัทจดทะเบียนพบผู้ลงทุน สำหรับผลประกอบการไตรมาส 1/2561",
    youtubeLink: null,
    file: "../pdf/multimedia/multimedia13.pdf",
  },
  {
    id: 18,
    activity: "การประชุมสามัญผู้ถือหุ้นประจำปี 2561",
    youtubeLink: null,
    file: null,
  },
];

export const MultiMediaDataEn = [
  {
    id: 0,
    activity: "Opportunity Day Quarter 2/2024",
    youtubeLink: "https://listed-company-presentation.setgroup.or.th/vdo/6783",
    file: "../pdf/multimedia/opportunityQ2_2024_E.pdf",
  },
  {
    id: 1,
    activity: "Opportunity Day Quarter 1/2024",
    youtubeLink: "https://www.youtube.com/live/8832SlMWR7A",
    file: "../pdf/multimedia/opportunityQ1_2024.pdf",
  },
  {
    id: 2,
    activity: "Annual General Meeting of 2024",
    youtubeLink: null,
    file: null,
  },
  {
    id: 3,
    activity: "Opportunity Day Quarter 4/2023",
    youtubeLink: "https://www.youtube.com/live/L5pzF99VpLQ",
    file: "../pdf/multimedia/opportunityQ4_2023.pdf",
  },
  {
    id: 4,
    activity: "Opportunity Day Quarter 3/2023",
    youtubeLink: "https://www.youtube.com/live/-fazP6qGHd8",
    file: "../pdf/multimedia/opportunityQ3_2023.pdf",
  },
  {
    id: 5,
    activity: "Opportunity Day Quarter 2/2023",
    youtubeLink: "https://www.youtube.com/watch?v=d1KSCpNLvec",
    file: "../pdf/multimedia/multimedia01.pdf",
  },
  {
    id: 6,
    activity: "Annual General Meeting of 2023",
    youtubeLink: null,
    file: null,
  },
  {
    id: 7,
    activity: "Opportunity Day Quarter 4/2022",
    youtubeLink: "https://www.youtube.com/watch?v=hYjE8lqn3aw",
    file: "../pdf/multimedia/multimedia03.pdf",
  },
  {
    id: 8,
    activity: "Opportunity Day Quarter 2/2022",
    youtubeLink: "https://www.youtube.com/watch?v=Sby0yX98l-U",
    file: "../pdf/multimedia/multimedia04.pdf",
  },
  {
    id: 9,
    activity: "Annual General Meeting of Shareholders 2022",
    youtubeLink: null,
    file: null,
  },
  {
    id: 10,
    activity: "Opportunity Day Quarter 4/2021",
    youtubeLink: "https://www.youtube.com/watch?v=WbX7knC7DBw",
    file: "../pdf/multimedia/multimedia06.pdf",
  },
  {
    id: 11,
    activity: "Opportunity Day Quarter 3/2021",
    youtubeLink: "https://www.youtube.com/watch?v=jFtoaeJdj7w",
    file: "../pdf/multimedia/multimedia07.pdf",
  },
  {
    id: 12,
    activity: "Opportunity Day Quarter 2/2021",
    youtubeLink: "https://www.youtube.com/watch?v=xX70IVAAKE0",
    file: "../pdf/multimedia/multimedia08.pdf",
  },
  {
    id: 13,
    activity: "Annual General Meeting of Shareholders 2021",
    youtubeLink: null,
    file: null,
  },
  {
    id: 14,
    activity: "Annual General Meeting of Shareholders 2020",
    youtubeLink: null,
    file: null,
  },
  {
    id: 15,
    activity: "Opportunity Day Quarter 2/2020",
    youtubeLink: null,
    file: "../pdf/multimedia/multimedia11.pdf",
  },
  {
    id: 16,
    activity: "Annual General Meeting of Shareholders 2019",
    youtubeLink: null,
    file: null,
  },
  {
    id: 17,
    activity: "Opportunity Day Quarter 1/2018",
    youtubeLink: null,
    file: "../pdf/multimedia/multimedia13.pdf",
  },
  {
    id: 18,
    activity: "Annual General Meeting of Shareholders 2018",
    youtubeLink: null,
    file: null,
  },
];
