import React from "react";
import { categoryEn, categoryTh } from "../../data/ComplaintData";
import { useGlobalContext } from "../../context/Context";
import { Header } from "../../Components/index"

export default function Complaints() {
  const { language, changeLanguage } = useGlobalContext();

  return (
    <>
      <section className="mt-36 mb-8 lg:mt-44 container mx-auto px-4">
        <Header nameTH={"ช่องทางรับเรื่องร้องเรียน"} nameEN={'Complaint Channel'}/>
        {language == "TH" ? (
          <>
           
            <div className="form-container form-margin-top">
              <div className="fc-1">
                <div className="fc-11">ประเภทการรับเรื่องร้องเรียน *</div>
                <div className="fc-12">
                  <select className="form-control" style={{ paddingLeft: 10 }}>
                    <option>โปรดเลือก</option>

                    {categoryTh.map((option, index) => {
                      return <option value={index}>{option.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="fc-1">
                <div className="fc-11">รายละเอียด *</div>
                <div className="fc-12">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    cols="55"
                    style={{ height: "auto" }}
                  ></textarea>
                  <p className="text-under-box">ไม่เกิน 500 ตัวอักษร</p>
                </div>
              </div>
              <div className="fc-1">
                <div className="fc-11">เอกสารแนบ</div>
                <div className="fc-12">
                  <input type="file" name="file[]" multiple></input>
                  <p className="text-under-box">
                    รองรับไฟล์ .jpg, .png, .gif, .doc, .docx, .xls, .xlsx, .pdf
                    สูงสุด 3 ไฟล์ (ขนาดไม่เกิน 2MB)
                  </p>
                </div>
              </div>
              <div className="fc-1">
                <div className="fc-11-bold">ช่องทางติดต่อกลับผู้ร้องเรียน</div>
              </div>
              <div className="fc-1 text">
                <div className="fc-11">ชื่อ - สกุล *</div>
                <div className="fc-12">
                  <input className="form-control" type="text"></input>
                </div>
              </div>
              <div className="fc-1">
                <div className="fc-11">อีเมล *</div>
                <div className="fc-12">
                  <input className="form-control" type="text"></input>
                </div>
              </div>
              <div className="fc-1 text">
                <div className="fc-11">เบอร์โทรศัพท์/มือถือ</div>
                <div className="fc-12">
                  <input className="form-control" type="text"></input>
                </div>
              </div>
              <div className="fc-1">
                <div className="fc-11">ช่องทางอื่น ๆ</div>
                <div className="fc-12">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    cols="55"
                    style={{ height: "auto" }}
                  ></textarea>
                </div>
              </div>
              <div className="fc-1 poly">
                <input
                  className="check-b"
                  type="checkbox"
                  style={{ height: 18, width: 18, marginRight: 5 }}
                ></input>
                <p className="text-check-b">
                  ฉันได้อ่านและยอมรับข้อกำหนดและเงื่อนไขที่ระบุไว้ใน
                  <a className="poly-text" href="PrivacyPolicy" target="_blank">
                    นโยบายความเป็นส่วนตัว
                  </a>
                  และยินยอมให้รวบรวมประมวลผลและ /
                  หรือเปิดเผยข้อมูลส่วนบุคคลที่ฉันให้ไว้เพื่อบรรลุวัตถุประสงค์ดังกล่าวข้างต้น
                </p>
              </div>
              <div className="fc-1-1-edit">
                <button className="fc-1-btn">ส่งข้อมูล</button>
              </div>
            </div>
          </>
        ) : (
          <>
            
            <div className="form-container">
              <div className="fc-1">
                <div className="fc-11">Category *</div>
                <div className="fc-12">
                  <select className="form-control" style={{ paddingLeft: 10 }}>
                    <option>Please Selecte</option>
                    {categoryEn.map((option, index) => {
                      return <option value={index}>{option.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="fc-1">
                <div className="fc-11">Description *</div>
                <div className="fc-12">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    cols="55"
                    style={{ height: "auto" }}
                  ></textarea>
                  <p className="text-under-box">
                    Detail character limit to 500
                  </p>
                </div>
              </div>
              <div className="fc-1">
                <div className="fc-11">Attach File</div>
                <div className="fc-12">
                  <input type="file" name="file[]" multiple></input>
                  <p className="text-under-box">
                    File Support .jpg, .png, .gif, .doc, .docx, .xls, .xlsx,
                    .pdf max 3 files (File should not over 2MB)
                  </p>
                </div>
              </div>
              <div className="fc-1">
                <div className="fc-11-bold">Reply to complainant</div>
              </div>
              <div className="fc-1 text">
                <div className="fc-11">Name - Surename *</div>
                <div className="fc-12">
                  <input className="form-control" type="text"></input>
                </div>
              </div>
              <div className="fc-1">
                <div className="fc-11">E-mail *</div>
                <div className="fc-12">
                  <input className="form-control" type="text"></input>
                </div>
              </div>
              <div className="fc-1 text">
                <div className="fc-11">Telephone/Mobile</div>
                <div className="fc-12">
                  <input className="form-control" type="text"></input>
                </div>
              </div>
              <div className="fc-1">
                <div className="fc-11">Other</div>
                <div className="fc-12">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    cols="55"
                    style={{ height: "auto" }}
                  ></textarea>
                </div>
              </div>
              <div className="fc-1 poly">
                <input
                  className="check-b"
                  type="checkbox"
                  style={{ height: 18, width: 18, marginRight: 5 }}
                ></input>
                <p className="text-check-b">
                  I have read and accepted terms and conditions specified in the
                  <a className="poly-text" href="PrivacyPolicy" target="_blank">
                    Privacy Policy
                  </a>
                  and do hereby consent to the collecting, processing and/or
                  disclosing of the personal data provided by me to fulfil the
                  above-said purpose.
                </p>
              </div>
              <div className="fc-1-1-edit">
                <button className="fc-1-btn">ส่งข้อมูล</button>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
}
