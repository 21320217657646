import React, { useState } from "react";
import LineChart from "./LineChart";
import CandlestickChart from "./CandlestickChart";
import OhlcChart from "./OhlcChart";
import { Link } from "react-router-dom";
import { DatePicker } from "antd";
import { motion } from "framer-motion";

export default function StockPriceChart(props) {
  const { dataSet } = props;

  const [selectChart, setSelectChart] = useState("Line");
  const [dateRange, setDateRange] = useState([]);
  const { RangePicker } = DatePicker;

  const handleChartChange = (chartType) => {
    setSelectChart(chartType);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const renderSelectedChart = () => {
    switch (selectChart) {
      case "Line":
        return (
          <LineChart
            key="line"
            dateRange={dateRange}
            dataSet={dataSet}
            {...props}
          />
        );
      case "Candlestick":
        return (
          <CandlestickChart
            key="candlestick"
            dateRange={dateRange}
            dataSet={dataSet}
            {...props}
          />
        );
      case "Bar":
        return (
          <OhlcChart
            key="bar"
            dateRange={dateRange}
            dataSet={dataSet}
            {...props}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="chart-stock-section">
      <div className="chart-stock">
        <div className="chart-stock-head">
          <div className="chart-selector">
            <span className="mr-6 text-sm font-bold">Chart Type:</span>
            <div className="radio-selector">
              <input
                className={`radio-button ${
                  selectChart === "Line" ? "selected" : ""
                }`}
                type="radio"
                name="Line"
                checked={selectChart === "Line"}
                onChange={() => handleChartChange("Line")}
              />
              <label className="ml-2 mr-6 font-light text-[#0059AB]">
                Line
              </label>
              <input
                className={`radio-button ${
                  selectChart === "Candlestick" ? "selected" : ""
                }`}
                type="radio"
                name="Candlestick"
                checked={selectChart === "Candlestick"}
                onClick={() => handleChartChange("Candlestick")}
              />
              <label className="ml-2 mr-6 font-light text-[#0059AB]">
                Candlestick
              </label>
              <input
                className={`radio-button ${
                  selectChart === "Bar" ? "selected" : ""
                }`}
                type="radio"
                name="Bar"
                checked={selectChart === "Bar"}
                onClick={() => handleChartChange("Bar")}
              />
              <label className="ml-2 mr-6 font-light text-[#0059AB]">
                OHLC
              </label>{" "}
            </div>
          </div>
          <div className="preiod-time">
            <span>Period From:</span>
            <RangePicker onChange={handleDateRangeChange} size="large" />
          </div>
        </div>
        <div className="chartlayout">
          <Link
            to="https://inventech.co.th/"
            target="_blank"
            rel="noopener noreferrer"
            className="inv-link"
          >
            ©inventech
          </Link>
          <motion.div
            key={selectChart}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 50 }}
            transition={{ duration: 0.5 }}
            style={{ width: "100%" }}
          >
            {renderSelectedChart()}
          </motion.div>
        </div>
      </div>
    </div>
  );
}
