import React from "react";
import { ImgChairman } from "../../assets/Image";
import { useGlobalContext } from "../../context/Context";

export default function Chairman() {
  const { language } = useGlobalContext();
  return (
    <section className="mt-36 mb-8 lg:mt-44 container mx-auto px-4">
      {language === "T" ? (
        <div id="aboutUs" className="sub-top-hight">
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-3xl  text-[#1b3a91] font-bold">
                สารจากประธานกรรมการบริษัท
              </h1>
            </div>
          </div>
          <div className="container-I" style={{ marginBottom: 100 }}>
            <div className="page-header text-center">
              <p>รายงานจากคณะกรรมการบริษัท</p>
            </div>
            <div className="row">
              <div className="box-line-2">
                {/* <img src={ImgChairman} className="chairman-pic" alt="" /> */}
                <p className="text-skn-standard tabbed">
                  ในปี 2566
                  มีปัจจัยด้านสถานการณ์ความตึงเครียดทางการเมืองระหว่างประเทศที่เกิดขึ้นมาต่อเนื่อง
                  ประกอบกับสภาวะด้านเศรษฐกิจที่มีปัจจัยด้านความกังวลเกี่ยวกับสภาวะเศรษฐกิจโลกที่เกิดขึ้น
                  ส่งผลให้สภาวะตลาดแผ่นไม้เอ็มดีเอฟมีแนวโน้มที่ชะลอตัวลงในบางช่วงของปี
                  นับว่าเป็นความท้าทายในการดำเนินงานของบริษัท
                  ทั้งในเรื่องของการบริหารจัดการต้นทุนที่มีความผันผวน
                  การประเมินและควบคุมความเสี่ยงที่อาจเกิดขึ้น
                  รวมถึงการปรับกลยุทธ์เพื่อให้สอดคล้องกับสถานการณ์ในปัจจุบัน
                  ทั้งนี้
                  ถือว่าบริษัทยังมีความสามารถในการบริหารจัดการกับวิกฤติการณ์ต่างๆ
                  และยังสามารถสร้างผลกำไรที่ดีได้อย่างต่อเนื่อง
                </p>
                <p className="text-skn-standard tabbed">
                  อย่างไรก็ดี
                  บริษัทยังคงมุ่งมั่นในการดำเนินธุรกิจตามเป้าหมายที่วางไว้
                  ซึ่งครอบคลุมทั้งด้านเศรษฐกิจ ด้านสิ่งแวดล้อม ด้านสังคม
                  และด้านบรรษัทภิบาล ซึ่งเป็นหัวใจสำคัญในการดำเนินธุรกิจ
                  เพื่อการเติบโตอย่างยั่งยืนและมอบผลตอบแทนที่ดีให้กับผู้มีส่วนได้เสียทุกฝ่าย
                  โดยตลอดมาบริษัทได้มุ่งเน้นการศึกษาและพัฒนากระบวนการผลิต
                  การควบคุมคุณภาพของสินค้า
                  การวิจัยและพัฒนาประสิทธิภาพของผลิตภัณฑ์ร่วมกับบริษัทย่อย
                  ศึกษาผลิตภัณฑ์ใหม่ สนับสนุนการพัฒนาบุคลากร
                  ประกอบกับการบริหารจัดการด้านสิ่งแวดล้อมที่ได้มาตรฐานอย่างต่อเนื่อง
                  ส่งผลให้บริษัทได้รับการตอบรับจากคู่ค้าทั้งในและต่างประเทศเป็นอย่างดีเสมอมา
                  ทั้งนี้
                  บริษัทขอให้ความมั่นใจว่าบริษัทมีความพร้อมกับการรับมือกับสถานการณ์ต่างๆ
                  ที่จะเกิดขึ้นในอนาคต
                  โดยมีแผนการบริหารจัดการที่ปรับให้สามารถข้ามผ่านวิกฤติการณ์ต่างๆ
                  ไปได้ รวมถึงมุ่งเน้นการสร้างโอกาสทางธุรกิจ
                  เพื่อเป็นการยกระดับความสามารถทางการแข่งขันและการเติบโตอย่างมั่นคง
                </p>
                <p className="text-skn-standard tabbed">
                  สุดท้ายนี้ ในนามของคณะกรรมการบริษัท
                  ขอขอบคุณลูกค้าผู้มีอุปการคุณที่คอยสนับสนุนและเป็นแรงผลักดันให้บริษัทคัดสรรผลิตภัณฑ์และปรับปรุงการบริการให้มีคุณภาพอย่างต่อเนื่อง
                  ส่งผลให้บริษัทเติบโตขึ้นอย่างมั่นคงตลอดระยะเวลาที่ผ่านมา
                  รวมถึงผู้มีส่วนได้เสียทุกภาคส่วน ประกอบด้วย ผู้ถือหุ้น คู่ค้า
                  ผู้บริหาร พนักงานทุกท่าน
                  และสถาบันการเงินที่มอบความไว้วางใจและสนับสนุนการดำเนินงานของบริษัทด้วยดีเสมอมา
                  และขอให้มั่นใจว่าบริษัทจะมุ่งมั่นในการดำเนินธุรกิจอย่างมีธรรมาภิบาล
                  ซื่อสัตย์ และมีจริยธรรม
                  และบริหารจัดการภายใต้หลักการกำกับดูแลกิจการที่ดีเพื่อประโยชน์สูงสุดของทุกฝ่ายต่อไป
                  และหวังเป็นอย่างยิ่งว่าจะได้รับการสนับสนุนเป็นอย่างดีจากทุกท่านตลอดไป
                </p>

                <p className="text-skn-standard chairman-t1">
                  ดร.สุวิทย์ ธนียวัน
                </p>
                <p className="text-skn-standard chairman-t2">
                  ประธานคณะกรรมการบริษัท
                </p>
              </div>
            </div>
          </div>
          <div className="container-I" style={{ marginBottom: 100 }}>
            <div className="page-header text-center">
              <p>สารจากกรรมการผู้จัดการ</p>
            </div>
            <div className="row">
              <div className="box-line-2">
                {/* <img src={ImgChairman} className="chairman-pic" alt="" /> */}
                <p className="text-skn-standard tabbed">
                  สำหรับปี 2566 ที่ผ่านมานั้น
                  ยังคงมีปัจจัยเรื่องสถานการณ์ความตึงเครียดทางการเมืองระหว่างประเทศที่เกิดขึ้นมาอย่างต่อเนื่อง
                  ซึ่งส่งผลกระทบให้เกิดความผันผวนของราคาวัตถุดิบ
                  ราคาพลังงานไฟฟ้า และค่าขนส่ง
                  ที่ล้วนแต่เป็นปัจจัยสำคัญในการดำเนินธุรกิจของบริษัท
                  ประกอบกับความกังวลเกี่ยวกับสภาวะเศรษฐกิจโลกที่เกิดขึ้น
                  ซึ่งส่งผลให้เกิดการชะลอตัวของความต้องการแผ่นไม้เอ็มดีเอฟในบางช่วงเวลา
                </p>
                <p className="text-skn-standard tabbed">
                  จากสถานการณ์ในปีที่ผ่านมานั้น
                  บริษัทจึงมุ่งเน้นถึงการศึกษาและพัฒนากระบวนการผลิต ผลิตภัณฑ์
                  และการบริหารจัดการต้นทุน
                  เพื่อลดผลกระทบที่อาจเกิดขึ้นกับบริษัทและผู้มีส่วนได้เสียทุกฝ่าย
                  รวมถึงรักษาความมั่นคงทางการเงิน
                  และผลการดำเนินงานให้มีเสถียรภาพ
                  ตลอดจนการประเมินและติดตามสถานการณ์ความตึงเครียดทางการเมืองและความขัดแย้งระหว่างประเทศที่เกิดขึ้นอย่างใกล้ชิด
                  พร้อมทบทวนและปรับกลยุทธ์และวางแผนการดำเนินงานกิจกรรมทางด้านต่างๆ
                  ไม่ว่าจะเป็นด้านการบริหาร การผลิต
                  การขายและการตลาดให้สอดคล้องกับสถานการณ์ที่เปลี่ยนแปลงอย่างรวดเร็ว
                  นอกจากนี้
                  บริษัทได้ให้ความสำคัญด้านการพัฒนาความรู้ความสามารถและทักษะในด้านต่างๆ
                  ของบุคลากร
                  และสื่อสารนโยบายต่อต้านการคอร์รัปชั่นและแนวทางปฏิบัติให้บุคลากรภายในองค์กรรับทราบและเน้นย้ำให้ปฏิบัติตามอย่างเคร่งครัด
                  ประกอบกับดำเนินงานโดยยึดหลักธรรมาภิบาล
                  บริหารงานอย่างมีความรับผิดชอบ
                  รวมถึงร่วมส่งเสริมอนุรักษ์วัฒนธรรมและสิ่งแวดล้อม เพื่อสังคม
                  ชุมชน และสิ่งแวดล้อมที่ยั่งยืน
                  ซึ่งส่งผลให้ภาพรวมผลการดำเนินงานปี 2566
                  นั้นยังสามารถสร้างผลกำไรได้อย่างต่อเนื่อง
                </p>
                <p className="text-skn-standard tabbed">
                  โดยในปี 2566 บริษัทมีรายได้จากการขาย 3,268.65 ล้านบาท
                  ลดลงจากปี 2565 เท่ากับ 906.30 ล้านบาท หรือลดลงประมาณร้อยละ
                  21.71 มีต้นทุนจากการขายสำหรับปี 2566 เท่ากับ 2,236.15 ล้านบาท
                  ลดลง 259.26 ล้านบาท คิดเป็นสัดส่วนร้อยละ 68.41
                  ของรายได้จากการขาย และมีกำไรสุทธิ 424.75 ล้านบาท ลดลงจากปี
                  2565 เท่ากับ 257.64 ล้านบาท หรือลดลงประมาณร้อยละ 37.76
                </p>
                <p className="text-skn-standard tabbed">
                  นอกจากนี้ บริษัท เอส.คลีเบอร์ เคมีคอล จำกัด (บริษัทย่อย)
                  ก็มีส่วนช่วยสนับสนุนธุรกิจด้านต้นทุนวัตถุดิบกาวและเป็นแหล่งวิจัยพัฒนาเพื่อเพิ่มคุณสมบัติของผลิตภัณฑ์
                  ประกอบกับ บริษัท เอสเคเอ็น คราฟท์ แอนด์ เปเปอร์ จำกัด
                  (บริษัทย่อย)
                  ที่ประกอบธุรกิจผลิตและจำหน่ายเยื่อกระดาษจากกระดาษที่ใช้แล้ว
                  ซึ่งจะเข้ามาช่วยเพิ่มผลิตภัณฑ์ที่หลากหลายให้บริษัทในอนาคต
                  ทั้งนี้
                  โครงการดังกล่าวข้างต้นถือเป็นอีกหนึ่งก้าวที่สำคัญของบริษัทในการเพิ่มศักยภาพทางการแข่งขันและผลักดันให้บริษัทมุ่งสู่วิสัยทัศน์ที่วางไว้
                </p>
                <p className="text-skn-standard tabbed">
                  สุดท้ายนี้ ผมขอขอบคุณคณะกรรมการบริษัท คณะกรรมการบริหาร ลูกค้า
                  ผู้ถือหุ้น คู่ค้า ที่ให้ความไว้วางใจในการดำเนินงานของบริษัท
                  รวมทั้งพนักงานของบริษัท
                  ที่ร่วมกันปฏิบัติหน้าที่ที่ได้รับมอบหมายด้วยความรับผิดชอบ
                  ขอให้คำมั่นว่าเราจะนำเอาประสบการณ์และความเชี่ยวชาญในการทำงานในวงการไม้มากว่า
                  30 ปี
                  ประกอบกับการทำงานโดยยึดมั่นความโปร่งใสและหลักธรรมาภิบาลที่ดี
                  รวมถึงการกำกับดูแลกิจการที่ดี
                  เพื่อผลักดันให้บริษัทเติบโตอย่างมั่นคงควบคู่กับธรรมชาติ
                  สังคมและสิ่งแวดล้อมอย่างยั่งยืน สืบต่อไปในอนาคต
                </p>
                <p className="text-skn-standard chairman-t1">
                  นายวิชัย แสงวงศ์กิจ
                </p>
                <p className="text-skn-standard chairman-t2">
                  กรรมการผู้จัดการ
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id="aboutUs" className="sub-top-hight">
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-3xl  text-[#1b3a91] font-bold">
                Chairman’s Statement
              </h1>
            </div>
          </div>
          <div className="container-I" style={{ marginBottom: 100 }}>
            <div className="page-header text-center">
              <p>Report of the Board of Directors</p>
            </div>
            <div className="row">
              <div className="box-line-2">
                {/* <img src={ImgChairman} className="chairman-pic" alt="" /> */}
                <p className="text-skn-standard tabbed">
                  In 2023, there were instances of political tension between
                  countries, that continues to occur. In addition, the economic
                  situation has factors of concern regarding the global economic
                  situation. As a result, the market for MDF boards tends to
                  slow down during certain periods of the year. All of these
                  were challenges for the company's operation in terms of
                  managing fluctuating costs, assessing and controlling
                  potential risks, and adjusting strategies to be accordance
                  with the current situation. In this regard, it is considered
                  that the company can manage various crises and generate
                  sustained profitability
                </p>
                <p className="text-skn-standard tabbed">
                  However, the company remains committed to operating its
                  business in line with its overarching goals, encompassing the
                  economic, environmental, social, and governance aspects that
                  are fundamental to achieving sustainable growth and generating
                  returns for all stakeholders. The company places great
                  emphasis on studying and refining its production processes,
                  quality control measures, and product performance through
                  collaboration with its subsidiaries to explore new products.
                  Additionally, the company is dedicated to supporting personnel
                  development and maintaining consistent environmental
                  management standards. As a result, the company has
                  consistently earned recognition and approval from both
                  domestic and foreign partners. Furthermore, the company would
                  like to assure that the company is ready to deal with various
                  situations that will occur in the future with a management
                  plan that has been adjusted to be able to overcome various
                  crises. Including, focusing on creating business opportunities
                  to raise the level of competitiveness and grow sustainability.
                </p>
                <p className="text-skn-standard tabbed">
                  Finally, as a representative of the Board of Directors, I
                  would like to express my appreciation for all these support
                  and motivation to the company. These have driven us to search
                  for a good quality product and keep improve our service
                  continually, which help us grow repeatedly with confidence. I
                  would like to express my gratitude to all interested person,
                  shareholder, partner, executive officer, employee, and
                  including financial institutions that always trusted and
                  supported us. And please rest assured that we will always
                  committing to operate this company with integrity and ethic,
                  under the concept of good governance for the most benefit for
                  all parties. And we hope that the company will receive a great
                  support from everyone forever and ever.
                </p>

                <p className="text-skn-standard chairman-t1">
                  Dr.Suvit thaniyavarn
                </p>
                <p className="text-skn-standard chairman-t2">
                  Chairman of the Board of Directors
                </p>
              </div>
            </div>
          </div>
          <div className="container-I" style={{ marginBottom: 100 }}>
            <div className="page-header text-center">
              <p>Chairman’s Statement</p>
            </div>
            <div className="row">
              <div className="box-line-2">
                {/* <img src={ImgChairman} className="chairman-pic" alt="" /> */}
                <p className="text-skn-standard tabbed">
                  As for the past year 2023, there were instances of political
                  tension between countries that continues to occur, which
                  affects fluctuations in raw materials prices, energy price,
                  and transportation costs. All of these are important factors
                  in the company's operations. In addition, there were concerns
                  regarding the global economic situation. As a result, the
                  demand for MDF boards tends to slow down during certain
                  periods of the year.
                </p>
                <p className="text-skn-standard tabbed">
                  From the situation in the past year, the company has focused
                  and placed great importance on studying and developing the
                  production processes, products, and cost management to
                  minimize any potential impacts on the company and all
                  stakeholders. The company has committed to maintain financial
                  stability and consistent performance. Throughout the year, the
                  company has assessed and closely monitored the situation of
                  political tension between countries and international
                  conflicts that occur, ready to adjust our strategies and plans
                  for various areas, including management, production, sales,
                  and marketing, in response to the rapidly changing situation.
                  Moreover, the company places great importance on developing
                  their knowledge, abilities, and skills in various fields and
                  communicating the anti-corruption policy and guidelines to the
                  personnel to acknowledge the importance of strict compliance.
                  In addition, the company operates by adhering to the
                  principles of good governance, responsible management and
                  participating in promoting the conservation of culture and the
                  environment for a sustainable society, community, and
                  environment. Consequently, that overall performance for 2023
                  will continue to generate profits.
                </p>
                <p className="text-skn-standard tabbed">
                  In 2023, the company has sales revenue of 3,268.65 million
                  Baht, decrease from the year 2022 equal to 906.30 million Baht
                  or 21.71%. The cost of sales for the year 2023 is 2,236.15
                  million Baht, decrease of 259.26 million Baht or 68.41% of
                  sales revenue and the net profit is 424.75 million Baht,
                  decrease from the year 2022 equal to 257.64 million Baht or
                  37.76%.
                </p>
                <p className="text-skn-standard tabbed">
                  Moreover, the company subsidiary, S.Kleber Chemical Co., Ltd.,
                  contributes to business by supplying cost-effective glue raw
                  materials and is a valuable source for research and
                  development to improve product properties. Additionally, the
                  company other subsidiary, SKN Kraft and Paper Co., Ltd., is
                  involved in selling and distributing pulp from recycled paper,
                  which will increase the variety of products in the future.
                  These projects mark important steps for the company to
                  increase competitiveness and drive us towards achieving the
                  company’s vision.
                </p>
                <p className="text-skn-standard tabbed">
                  Lastly, I would like to express my gratitude to the Board of
                  Directors, Executives, customers, shareholders, and partners
                  who confide in our company operations, including our employees
                  who perform duties and assignments with their responsibility.
                  We are committed to utilizing our experiences and proficiency
                  in working in the wood industry for over 30 years and adhering
                  to transparency and good governance, including the Principles
                  of Corporate Governance to drive the Company to grow steadily
                  and sustainable together with the nature, community, and
                  environment.
                </p>
                <p className="text-skn-standard chairman-t1">
                  Mr. Vichai Sangwongkit
                </p>
                <p className="text-skn-standard chairman-t2">
                  Managing Director
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
