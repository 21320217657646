import React from 'react'
import popup_th from '../../../assets/Bg/popup_th.png'
import popup_en from '../../../assets/Bg/popup_en.png'
import { useGlobalContext } from '../../../context/Context'
import { ImCross } from 'react-icons/im'

export default function PopupHome({ isOpen, onClose, link }) {
  const { language } = useGlobalContext()

  return (
    isOpen && (
      <div className='bg-modal'>
        <div className='layout-popup'>
          <div className='card-popup'>
            <a href={link} target='_blank' rel='noopener noreferrer'>
              <img src={language === 'T' ? popup_th : popup_en} alt='' />
            </a>
            <div className='close-popup'>
              <button className='icon-close-popup' onClick={onClose}>
                <ImCross />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
