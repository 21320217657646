import React, { useState } from "react";
import {
  generalMeetingData,
  generalMeetingDataEN,
} from "../../../mock/MockupGeneralMeeting";
import { FaMinus, FaPlus } from "react-icons/fa";
import { BiSolidFilePdf } from "react-icons/bi";
import { ImgBannerAGM } from "../../../assets/Image";
import { useGlobalContext } from "../../../context/Context";
import { Header } from "../../../Components";
import { Link } from "react-router-dom";

export default function GeneralMeeting() {
  const { language } = useGlobalContext();
  const [year, setYear] = useState(2568);
  const [yearEN, setYearEN] = useState(2025);
  const [showDataHead, setShowDataHead] = useState(true);
  const [showData, setShowData] = useState(true);
  const [showDataSub, setShowDataSub] = useState(false);
  const seenYears = new Set();
  const seenYearsEN = new Set();

  return (
    <div className="sub-top-hight">
      <div className="container-I" style={{ marginBottom: 100 }}>
        <div className="row">
          <div className="box-line-c">
            <Header nameTH={"การประชุมผู้ถือหุ้น"} nameEN={"General Meeting"} />
          </div>
        </div>
        <div className="container mt-4" style={{ margin: "auto" }}>
          <Link
            className="page-header text-center"
            to={"https://agm.irplus.in.th/manual/manual.html"}
            target="_blank"
          >
            <img
              alt="ImgBannerAGM"
              style={{
                maxWidth: "100%",
                margin: "30 auto",
              }}
              src={ImgBannerAGM}
              className="corporate-pic"
            />
          </Link>
          <div className="select-year">
            <p className="select-1">
              {language === "T" ? "เลือกปี:" : "Select Year:"}
            </p>
            <select
              className="select-box select-1"
              style={{ paddingLeft: 10 }}
              value={language === "T" ? year : yearEN}
              onChange={(e) => {
                language === "T"
                  ? setYear(e.target.value)
                  : setYearEN(e.target.value);
              }}
            >
              {language === "T"
                ? generalMeetingData.map((item, i) => {
                    if (!seenYears.has(item.year)) {
                      seenYears.add(item.year);

                      return (
                        <option key={i} value={item.year}>
                          {item.year}
                        </option>
                      );
                    }
                  })
                : generalMeetingDataEN.map((item, i) => {
                    if (!seenYearsEN.has(item.year)) {
                      seenYearsEN.add(item.year);

                      return (
                        <option key={i} value={item.year}>
                          {item.year}
                        </option>
                      );
                    }
                  })}

              {/* <option value="2566" onClick={() => setYear(2566)}>
                  2566
                </option>
                <option value="2565" onClick={() => setYear(2565)}>
                  2565
                </option>
                <option value="2564" onClick={() => setYear(2564)}>
                  2564
                </option>
                <option value="2563" onClick={() => setYear(2563)}>
                  2563
                </option>
                <option value="2562" onClick={() => setYear(2562)}>
                  2562
                </option>
                <option value="2561" onClick={() => setYear(2561)}>
                  2561
                </option> */}
            </select>
          </div>

          <div className="relative overflow-x-auto">
            <table className="w-full table-respon mt-4 text-sm text-center text-white">
              <thead className="text-base text-white bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                {language === "T" ? (
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowDataHead((prev) => !prev)}
                  >
                    <th
                      scope="col"
                      className="px-6 py-5 text-left"
                      style={{ width: "100%" }}
                    >
                      การประชุมสามัญผู้ถือหุ้น ประจำปี {year}
                    </th>
                    <th scope="col" className="px-6 py-5 text-center">
                      {showDataHead === true ? <FaMinus /> : <FaPlus />}
                    </th>
                  </tr>
                ) : (
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowDataHead((prev) => !prev)}
                  >
                    <th
                      scope="col"
                      className="px-6 py-5 text-left"
                      style={{ width: "100%" }}
                    >
                      Annual General Meeting of Shareholders {yearEN}
                    </th>
                    <th scope="col" className="px-6 py-5 text-center">
                      {showDataHead === true ? <FaMinus /> : <FaPlus />}
                    </th>
                  </tr>
                )}
              </thead>
              <tbody>
                {language === "T"
                  ? showDataHead &&
                    generalMeetingData.map((item, i) => {
                      if (item.year == year) {
                        return (
                          <>
                            <tr
                              key={i}
                              onClick={() => setShowData((prev) => !prev)}
                              style={{ cursor: "pointer" }}
                              className="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                            >
                              <td className="px-6 py-3 text-[#666] text-left">
                                {item.annualGeneralMeeting}
                              </td>

                              {item.file ? (
                                <td className="icon-pdf-1">
                                  <Link
                                    to={"/pdf/" + item.file}
                                    target="_blank"
                                  >
                                    <BiSolidFilePdf
                                      style={{ fill: "red", margin: "auto" }}
                                    />
                                  </Link>
                                </td>
                              ) : (
                                <td className="icon-pdf-1">
                                  {showData === true ? <FaMinus /> : <FaPlus />}
                                </td>
                              )}
                            </tr>
                            {showData &&
                              item.subData &&
                              item.subData.map((item, i) => {
                                return (
                                  <>
                                    <tr
                                      key={i}
                                      onClick={() =>
                                        setShowDataSub((prev) => !prev)
                                      }
                                      style={{ cursor: "pointer" }}
                                      className="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                                    >
                                      <td className="px-6 py-3 text-[#666] text-left">
                                        {item.annualGeneralMeeting}
                                        {item.span && (
                                          <span className="italic text-[#1b3a91]  text-xs  font-medium ">
                                            {item.span}
                                          </span>
                                        )}
                                      </td>
                                      {item.file ? (
                                        <td className="icon-pdf-1">
                                          <Link
                                            to={"/pdf/" + item.file}
                                            target="_blank"
                                          >
                                            <BiSolidFilePdf
                                              style={{
                                                fill: "red",
                                                margin: "auto",
                                              }}
                                            />
                                          </Link>
                                        </td>
                                      ) : (
                                        <td className="icon-pdf-1">
                                          {showDataSub === false ? (
                                            <FaPlus />
                                          ) : (
                                            <FaMinus />
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                    {showDataSub &&
                                      item.subData2 &&
                                      item.subData2.map((item, i) => {
                                        return (
                                          <>
                                            <tr
                                              key={i}
                                              className="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                                            >
                                              <td className="px-6 py-3 text-[#666] text-left">
                                                {item.annualGeneralMeeting}
                                              </td>
                                              <td className="icon-pdf-1">
                                                <Link
                                                  to={"/pdf/" + item.file}
                                                  target="_blank"
                                                >
                                                  <BiSolidFilePdf
                                                    style={{
                                                      fill: "red",
                                                      margin: "auto",
                                                    }}
                                                  />
                                                </Link>
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                  </>
                                );
                              })}
                          </>
                        );
                      }
                    })
                  : showDataHead &&
                    generalMeetingDataEN.map((item, i) => {
                      if (item.year == yearEN) {
                        return (
                          <>
                            <tr
                              key={i}
                              onClick={() => setShowData((prev) => !prev)}
                              style={{ cursor: "pointer" }}
                              className="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                            >
                              <td className="px-6 py-3 text-[#666] text-left">
                                {item.annualGeneralMeeting}
                              </td>

                              {item.file ? (
                                <td className="icon-pdf-1">
                                  <Link
                                    to={"/pdf/" + item.file}
                                    target="_blank"
                                  >
                                    <BiSolidFilePdf
                                      style={{ fill: "red", margin: "auto" }}
                                    />
                                  </Link>
                                </td>
                              ) : (
                                <td className="icon-pdf-1">
                                  {showData === true ? <FaMinus /> : <FaPlus />}
                                </td>
                              )}
                            </tr>
                            {showData &&
                              item.subData &&
                              item.subData.map((item, i) => {
                                return (
                                  <>
                                    <tr
                                      key={i}
                                      onClick={() =>
                                        setShowDataSub((prev) => !prev)
                                      }
                                      style={{ cursor: "pointer" }}
                                      className="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                                    >
                                      <td className="px-6 py-3 text-[#666] text-left">
                                        {item.annualGeneralMeeting}
                                        {item.span && (
                                          <span className="italic text-[#1b3a91]  text-xs  font-medium ">
                                            {item.span}
                                          </span>
                                        )}
                                      </td>
                                      {item.file ? (
                                        <td className="icon-pdf-1">
                                          <Link
                                            to={"/pdf/" + item.file}
                                            target="_blank"
                                          >
                                            <BiSolidFilePdf
                                              style={{
                                                fill: "red",
                                                margin: "auto",
                                              }}
                                            />
                                          </Link>
                                        </td>
                                      ) : (
                                        <td className="icon-pdf-1">
                                          {showDataSub === false ? (
                                            <FaPlus />
                                          ) : (
                                            <FaMinus />
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                    {showDataSub &&
                                      item.subData2 &&
                                      item.subData2.map((item, i) => {
                                        return (
                                          <>
                                            <tr
                                              key={i}
                                              className="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                                            >
                                              <td className="px-6 py-3 text-[#666] text-left">
                                                {item.annualGeneralMeeting}
                                              </td>
                                              <td className="icon-pdf-1">
                                                <Link
                                                  to={"/pdf/" + item.file}
                                                  target="_blank"
                                                >
                                                  <BiSolidFilePdf
                                                    style={{
                                                      fill: "red",
                                                      margin: "auto",
                                                    }}
                                                  />
                                                </Link>
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                  </>
                                );
                              })}
                          </>
                        );
                      }
                    })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
