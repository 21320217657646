import React from "react";
import { Header } from "../../Components";
import { imgCircleBg } from "../../assets/Image";
import { useGlobalContext } from "../../context/Context";

export default function PrivacyPolicy() {
  const { language } = useGlobalContext();

  return (
    <div className="container-page">
      <div className="container-top">
        <Header
          nameTH={"นโยบายคุ้มครองข้อมูลส่วนบุคคล"}
          nameEN={"Personal Data Privacy Policy"}
        />
        <div className="container-main">
          <div className="row-main">
            <div className="col-main">
              <img src={imgCircleBg} className="img-circle-bg-1" />
            </div>
            {language === "T" ? (
              <div className="col-main">
                <div className="circle-bg-1" />
                <div className="layout-content-policy">
                  <p className="text-normal">
                    <strong>
                      {" "}
                      บริษัท ส.กิจชัย เอ็นเตอร์ไพรส์ จำกัด (มหาชน){" "}
                    </strong>
                    ตระหนักถึงความสำคัญของการคุ้มครองข้อมูลส่วนบุคคล
                    และมีการกำกับดูแล และการบริหารจัดการข้อมูลส่วนบุคคล
                    ให้มีความสอดคล้องกับกฎหมายคุ้มครองข้อมูลส่วนบุคคล
                    และกฏหมายที่เกี่ยวข้อง
                    จึงขอแจ้งข้อมูลดังต่อไปนี้ให้ท่านทราบเพื่อเป็นการปฏิบัติตาม
                    พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
                    โดยมีสาระสำคัญดังต่อไปนี้
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">1. ข้อมูลส่วนบุคคล</p>
                  <p className="text-sub-header">
                    1.1 ลักษณะของข้อมูลส่วนบุคคล
                  </p>
                  <p className="text-normal">
                    ในเอกสารฉบับนี้
                    <br />
                    ข้อมูลส่วนบุคคล หมายถึง ข้อมูลใด ๆ
                    ที่เกี่ยวกับบุคคลธรรมดาที่ทำให้สามารถระบุถึงตัวบุคคลธรรมดานั้นได้ไม่ว่าทางตรงหรือทางอ้อม
                    โดยไม่รวมถึงข้อมูลส่วนบุคคลของผู้ถึงแก่กรรม
                  </p>
                  <p className="text-normal">
                    ข้อมูลส่วนบุคคลที่มีความอ่อนไหว หมายถึง
                    ข้อมูลส่วนบุคคลเกี่ยวกับเชื้อชาติ เผ่าพันธุ์
                    ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ ศาสนาหรือปรัชญา
                    พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ ความพิการ
                    ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม ข้อมูลชีวภาพ (เช่น
                    การสแกนลายนิ้วมือ การสแกนใบหน้า เป็นต้น)
                    หรือข้อมูลอื่นใดซึ่งกระทบต่อเจ้าของข้อมูลส่วนบุคคลในทำนองเดียวกันตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลประกาศกำหนด
                  </p>
                  <p className="text-sub-header">
                    1.2 ข้อมูลส่วนบุคคลที่มีการเก็บรวบรวม
                  </p>
                  <p className="text-normal">
                    บริษัทฯ อาจมีการเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน ดังต่อไปนี้
                  </p>
                  <p className="text-sub-header">ข้อมูลส่วนบุคคลทั่วไป:</p>
                  <p className="text-normal">
                    o (ก) ข้อมูลส่วนตัว ได้แก่ ชื่อและนามสกุล วันเดือนปี
                    สถานที่เกิด สถานภาพ
                    ข้อมูลตามที่ระบุในบัตรประจำตัวประชาชนและหนังสือเดินทาง
                    สำเนาบัตรประชาชน หรือหมายเลขบัตรประชาชน
                  </p>
                  <p className="text-normal">
                    o (ข) ข้อมูลติดต่อ ได้แก่ ที่อยู่ หมายเลขโทรศัพท์ Line ID
                    ช่องทางติดต่อในสื่อสังคมออนไลน์ สถานที่ทำงาน
                  </p>
                  <p className="text-normal">
                    o (ค) ตำแหน่งงาน หน่วยงานหรือองค์กร
                  </p>
                  <p className="text-normal">
                    o (ง) ข้อมูลเกี่ยวกับการใช้งานระบบอิเล็กทรอนิกส์ ได้แก่
                    email หมายเลขไอพี (IP Address) ประเภทของโปรแกรมบราวเซอร์
                    (Browser) และคุกกี้ (Cookies)
                    ประวัติการสนทนาในแอปพลิเคชันต่างๆ
                  </p>
                  <p className="text-normal">
                    o (จ) ข้อมูลที่ท่านได้ให้ไว้เมื่อท่านติดต่อ
                    หรือร่วมกิจกรรมใด ๆ กับบริษัทฯ เป็นต้น
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">
                    2. การเคารพสิทธิในความเป็นส่วนบุคคล
                  </p>
                  <p className="text-normal">
                    บริษัทฯ เคารพสิทธิในข้อมูลส่วนบุคคลของเจ้าของข้อมูล
                    และตระหนักดีว่าเจ้าของข้อมูล
                    ย่อมมีความประสงค์ที่จะได้รับความมั่นคงปลอดภัยเกี่ยวกับข้อมูลของตน
                    ข้อมูลส่วนบุคคลที่บริษัทฯได้รับมา
                    จะถูกนำไปใช้ตามวัตถุประสงค์ที่เกี่ยวข้องเท่านั้น โดยบริษัทฯ
                    มีมาตรการเข้มงวดในการรักษาความมั่นคงปลอดภัย
                    ตลอดจนการป้องกันมิให้มีการนำข้อมูลส่วนบุคคลไปใช้โดยมิชอบด้วยกฎหมาย
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">3. การเก็บรวบรวมข้อมูลส่วนบุคคล</p>
                  <p className="text-normal">
                    ในการเก็บรวบรวมข้อมูลส่วนบุคคลจากเจ้าของข้อมูลโดยตรง
                    และการนำข้อมูลส่วนบุคคลไปใช้ รวมถึงการเปิดเผยข้อมูลส่วนบุคคล
                    บริษัทฯ
                    จะขอความยินยอมจากเจ้าของข้อมูลก่อนหรือขณะทำการเก็บรวบรวม
                    หากกฎหมายกำหนดให้ต้องขอความยินยอม
                    และจะดำเนินการเกี่ยวกับข้อมูลส่วนบุคคลเท่าที่จำเป็นตามวัตถุประสงค์ที่บริษัทฯ
                    ระบุไว้โดยแจ้งชัด
                  </p>
                  <p className="text-normal">
                    ทั้งนี้ บริษัทฯ
                    อาจรวบรวมข้อมูลส่วนบุคคลที่ได้รับมาจากแหล่งอื่นที่ไม่ใช่จากเจ้าของข้อมูลส่วนบุคคลโดยตรง
                    เช่น จากสื่อสาธารณะต่าง ๆ
                    เฉพาะในกรณีที่มีความจำเป็นด้วยวิธีการตามที่กฎหมายกำหนด
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">
                    4. วัตถุประสงค์ในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล
                  </p>
                  <p className="text-normal">
                    บริษัทฯ เก็บรวบรวม ใช้ และเปิดเผย
                    ข้อมูลส่วนบุคคลของท่านตามวัตถุประสงค์ดังต่อไปนี้
                  </p>
                  <p className="text-normal">
                    o เพื่อประโยชน์ในการจัดหาหรือจำหน่ายผลิตภัณฑ์
                    การให้หรือรับบริการในรูปแบบต่างๆ
                  </p>
                  <p className="text-normal">
                    o เพื่อการทำธุรกรรมทางการเงิน และภาษี
                    ที่เกี่ยวข้องกับการปฏิบัติตามสัญญาของบริษัทฯ
                  </p>
                  <p className="text-normal">o การค้นคว้า หรือการวิจัย</p>
                  <p className="text-normal">
                    o
                    เพื่อประโยชน์ในการจัดทำฐานข้อมูลสำหรับการวิเคราะห์และนำเสนอบริการหรือผลิตภัณฑ์ใด
                    ๆ ของบริษัทฯ และบริษัทในกลุ่ม หรือบุคคลที่เป็นผู้จำหน่าย
                    เป็นตัวแทน หรือมีความเกี่ยวข้องกับบริษัทฯ
                  </p>
                  <p className="text-normal">
                    o เพื่อประโยชน์ในการปรับปรุงคุณภาพในการดำเนินงาน
                    การให้บริการ และการดำเนินการที่เกี่ยวข้องกับธุรกิจของบริษัทฯ
                  </p>
                  <p className="text-normal">
                    o เพื่อการวิเคราะห์และติดตามการใช้บริการทางเว็บไซต์
                    และวัตถุประสงค์ในการตรวจสอบย้อนหลังในกรณีที่เกิดปัญหาการใช้งาน
                  </p>
                  <p className="text-normal">
                    o เพื่อการเข้าร่วมกิจกรรมต่างๆ ของบริษัทฯ
                  </p>
                  <p className="text-normal">
                    o เพื่อวัตถุประสงค์ในการควบคุมการเข้าถึง
                    การป้องกันและระงับอันตรายต่อชีวิต ร่างกาย
                    หรือสุขภาพของท่านหรือบุคคลอื่น การควบคุมและป้องกันโรคติดต่อ
                    และเพื่อประโยชน์ในการดูแลรักษาความปลอดภัย ของบริเวณอาคาร
                    ภายในอาคาร และพื้นที่ของบริษัทฯ
                  </p>
                  <p className="text-normal">
                    o เพื่อปฏิบัติตามกฎหมายหรือกฎระเบียบที่ใช้บังคับกับบริษัทฯ
                    ทั้งในปัจจุบันและ ในอนาคต
                  </p>
                  <p className="text-normal">
                    ข้อมูลส่วนบุคคลที่บริษัทฯ
                    ดำเนินการเก็บรวบรวมเพื่อวัตถุประสงค์ข้างต้นเป็นข้อมูลที่จำเป็นในการปฏิบัติตามสัญญาหรือการปฏิบัติตามกฎหมายต่าง
                    ๆ ที่ใช้บังคับ หากท่านไม่ให้ข้อมูลส่วนบุคคลที่จำเป็นดังกล่าว
                    อาจเป็นการฝ่าฝืนกฎหมาย หรือบริษัทฯ
                    อาจไม่สามารถบริหารหรือจัดการสัญญา
                    หรืออำนวยความสะดวกในการดำเนินการต่างๆ ให้กับท่านได้
                  </p>
                  <p className="text-normal">
                    ทั้งนี้หากภายหลังมีการเปลี่ยนแปลงวัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล
                    บริษัทฯ จะแจ้งให้ท่านทราบ
                    และดำเนินการอื่นใดตามที่กฎหมายกำหนด
                    รวมถึงจัดให้มีบันทึกการแก้ไขเพิ่มเติมไว้เป็นหลักฐาน
                  </p>

                  <hr className="hr-line" />
                  <p className="text-header">
                    5. ระยะเวลาในการจัดเก็บข้อมูลส่วนบุคคล
                  </p>
                  <p className="text-normal">
                    บริษัทฯ
                    จะเก็บรักษาข้อมูลส่วนบุคคลของท่านเป็นระยะเวลาเท่าที่จำเป็นเพื่อวัตถุประสงค์ในการเก็บรวบรวม
                    ใช้ และเปิดเผยข้อมูลส่วนบุคคลซึ่งได้ระบุไว้ในคำประกาศฉบับนี้
                    ตามหลักเกณฑ์ที่ใช้กำหนดระยะเวลาเก็บ ได้แก่
                    ระยะเวลาที่บริษัทฯ
                    ยังมีความสัมพันธ์กับท่านในฐานะลูกค้าของบริษัทฯ
                    และอาจเก็บต่อไปตามระยะเวลาที่จำเป็นเพื่อการปฏิบัติตามกฎหมายหรือตามอายุความทางกฎหมาย
                    เพื่อการก่อตั้งสิทธิเรียกร้องตามกฎหมาย
                    การปฏิบัติตามกฎหมายหรือการใช้สิทธิเรียกร้องตามกฎหมาย
                    หรือการยกขึ้นต่อสู้สิทธิเรียกร้องตามกฎหมาย
                    หรือเพื่อเหตุอื่นตามนโยบายและข้อกำหนดภายในของบริษัทฯ
                  </p>
                  <p className="text-normal">
                    ในกรณีที่ไม่สามารถระบุระยะเวลาการเก็บรักษาข้อมูลส่วนบุคคลได้ชัดเจน
                    บริษัทฯ
                    จะเก็บรักษาข้อมูลไว้ตามระยะเวลาที่อาจคาดหมายได้ตามมาตรฐานของการเก็บรวบรวม
                    (เช่น อายุความตามกฎหมายทั่วไปสูงสุด 10 ปี)
                  </p>

                  <hr className="hr-line" />
                  <p className="text-header">6. การรักษาความมั่นคงปลอดภัย</p>
                  <p className="text-normal">
                    บริษัทฯ กำหนดให้มีมาตรการที่เหมาะสม
                    และเข้มงวดในการรักษาความมั่นคงปลอดภัย
                    ตามนโยบายและแนวปฏิบัติในการรักษาความมั่นคงปลอดภัยด้านเทคโนโลยีสารสนเทศของบริษัทฯ
                    เพื่อป้องกันการสูญหาย การเข้าถึง ทำลาย ใช้ แปลง
                    แก้ไขหรือมิให้มีการนำข้อมูลส่วนบุคคลไปใช้โดยไม่มีสิทธิหรือไม่ชอบด้วยกฎหมาย
                  </p>

                  <hr className="hr-line" />
                  <p className="text-header">
                    7. สิทธิของท่านในฐานะเจ้าของข้อมูลส่วนบุคคล
                  </p>
                  <p className="text-normal">
                    ในฐานะที่เป็นเจ้าของข้อมูลส่วนบุคคลท่านมีสิทธิตามที่กำหนดไว้โดยพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                    พ.ศ. 2562 รวมถึงสิทธิต่าง ๆ ดังนี้
                  </p>
                  <p className="text-sub-header">
                    7.1 สิทธิในการเพิกถอนความยินยอม
                  </p>
                  <p className="text-normal">
                    ท่านมีสิทธิในการเพิกถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคลที่ท่านได้ให้ความยินยอมกับบริษัทฯ
                    ได้
                    เว้นแต่การเพิกถอนความยินยอมจะมีข้อจำกัดโดยกฎหมายหรือสัญญาที่ให้ประโยชน์แก่ท่าน
                    ในฐานะที่ท่านเป็นเจ้าของข้อมูลทั้งนี้
                    การเพิกถอนความยินยอมจะไม่ส่งผลกระทบต่อการประมวลผลข้อมูลส่วนบุคคลที่ท่าน
                    ได้ให้ความยินยอมไปแล้วโดยชอบด้วยกฎหมาย
                  </p>
                  <p className="text-sub-header">
                    7.2 สิทธิในการขอเข้าถึงข้อมูลส่วนบุคคล
                  </p>
                  <p className="text-normal">
                    ท่านมีสิทธิขอเข้าถึงและขอรับสำเนาข้อมูลของท่านซึ่งอยู่ในความรับผิดชอบของบริษัทฯ
                    รวมถึงขอให้บริษัทฯ
                    เปิดเผยการได้มาซึ่งข้อมูลดังกล่าวที่ท่านไม่ได้ให้ความยินยอมต่อบริษัทฯ
                    ได้
                  </p>
                  <p className="text-sub-header">
                    7.3 สิทธิในการขอให้ส่งหรือโอนข้อมูลส่วนบุคคล
                  </p>
                  <p className="text-normal">
                    ท่านมีสิทธิขอให้บริษัทฯ
                    โอนข้อมูลส่วนบุคคลของท่านที่ท่านให้ไว้กับบริษัทฯ
                    ได้ตามที่กฎหมายกำหนด
                  </p>
                  <p className="text-sub-header">
                    7.4 สิทธิในการคัดค้านการเก็บรวบรวม ใช้
                    หรือเปิดเผยข้อมูลส่วนบุคคล
                  </p>
                  <p className="text-normal">
                    ท่านมีสิทธิในการคัดค้านการประมวลผลข้อมูลที่เกี่ยวกับท่านสำหรับกรณีการเก็บรวบรวม
                    ใช้
                    หรือเปิดเผยข้อมูลส่วนบุคคลที่เกี่ยวกับตนได้ตามที่กฎหมายกำหนด
                  </p>
                  <p className="text-sub-header">
                    7.5 สิทธิในการขอลบข้อมูลส่วนบุคคล
                  </p>
                  <p className="text-normal">
                    ท่านมีสิทธิขอให้บริษัทฯ
                    ลบหรือทำลายหรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวตนได้ตามที่กฎหมายกำหนด
                  </p>
                  <p className="text-sub-header">
                    7.6 สิทธิในการขอให้ระงับการใช้ข้อมูลส่วนบุคคล
                  </p>
                  <p className="text-normal">
                    ท่านมีสิทธิขอให้บริษัทฯ
                    ระงับการใช้ข้อมูลของท่านได้ตามที่กฎหมายกำหนด
                  </p>
                  <p className="text-sub-header">
                    7.7 สิทธิในการขอแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง
                  </p>
                  <p className="text-normal">
                    กรณีที่ท่านเห็นว่าข้อมูลที่บริษัทฯ
                    มีอยู่นั้นไม่ถูกต้องหรือท่านมีการเปลี่ยนแปลงข้อมูลส่วนบุคคลของท่านเอง
                    ท่านมีสิทธิขอให้บริษัทฯ
                    แก้ไขข้อมูลส่วนบุคคลของท่านเพื่อให้ข้อมูลส่วนบุคคลดังกล่าวถูกต้อง
                    เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
                  </p>
                  <p className="text-sub-header">
                    7.8
                    สิทธิในการรับทราบกรณีมีการแก้ไขเปลี่ยนแปลงแบบแจ้งเกี่ยวกับข้อมูลส่วนบุคคลของท่าน
                  </p>
                  <p className="text-normal">
                    บริษัทฯ
                    อาจมีการพิจารณาทบทวนและแก้ไขเปลี่ยนแปลงแบบแจ้งนี้ตามความเหมาะสม
                    ในบางครั้งเพื่อให้แน่ใจว่าข้อมูลส่วนบุคคลของท่านจะได้รับความคุ้มครองอย่างเหมาะสม
                  </p>
                  <p className="text-sub-header">7.9 สิทธิในการร้องเรียน</p>
                  <p className="text-normal">
                    ท่านมีสิทธิในการร้องเรียนต่อพนักงานเจ้าหน้าที่ผู้มีอำนาจตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                    พ.ศ. 2562 หากบริษัทฯ
                    ฝ่าฝืนหรือไม่ปฏิบัติตามพระราชบัญญัติดังกล่าวได้ในกรณีที่เจ้าของข้อมูลส่วนบุคคลยื่นคำร้องขอใช้สิทธิภายใต้บทบัญญัติของพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                    พ.ศ. 2562 เมื่อบริษัทฯ ได้รับคำร้องขอดังกล่าวแล้ว
                    จะดำเนินการภายในระยะเวลาที่กฎหมายกำหนด อนึ่ง บริษัทฯ
                    สงวนสิทธิที่จะปฏิเสธหรือไม่ดำเนินการตามคำร้องขอดังกล่าวได้ในกรณีที่กฎหมายกำหนด
                    ในกรณีที่เจ้าของข้อมูลมีข้อจำกัดโดยเลือกที่จะให้ข้อมูลส่วนบุคคลเฉพาะอย่าง
                    อาจส่งผลให้ ไม่สามารถได้รับบริการจากบริษัทฯได้อย่างเต็มที่
                    รวมทั้งบริษัทฯ
                    อาจจะไม่สามารถทำงานร่วมกับเจ้าของข้อมูลส่วนบุคคลหรือให้บริการใด
                    ๆ ได้ หากเจ้าของข้อมูลส่วนบุคคลไม่ยินยอมให้ข้อมูลที่บริษัทฯ
                    ต้องการ
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">
                    8. การเปิดเผยข้อมูลส่วนบุคคลกับบุคคลอื่นหรือหน่วยงานอื่น
                  </p>
                  <p className="text-normal">
                    บริษัทฯ
                    อาจมีความจำเป็นในการเปิดเผยข้อมูลส่วนบุคคลให้แก่บริษัทในกลุ่ม
                    หรือบุคคลหรือหน่วยงานอื่นที่เป็นพันธมิตรทางกลยุทธ์ทั้งในประเทศและต่างประเทศ
                    ซึ่งทำงานร่วมกับบริษัทฯ
                    ในการจัดหาผลิตภัณฑ์และให้บริการในรูปแบบต่าง ๆ
                    หรือตามความจำเป็นตามสมควรในการบังคับใช้ข้อกำหนดและเงื่อนไขของบริษัทฯ
                    หรือกรณีที่มีการปรับโครงสร้างองค์กร การควบรวมบริษัท
                    หรือการขายกิจการ
                    และอาจมีการเปิดเผยข้อมูลส่วนบุคคลให้กับหน่วยงานราชการหรือหน่วยงานภาครัฐตามข้อบังคับของกฎหมายหรือตามคำสั่งศาลหรือตามคำสั่งเจ้าหน้าที่ผู้มีอำนาจ
                    โดยข้อมูลส่วนบุคคลจะได้รับการเก็บรักษาเป็นความลับ
                    ทั้งในรูปเอกสารและข้อมูลอิเล็กทรอนิกส์
                    รวมทั้งในระหว่างการส่งผ่านข้อมูลทุกขั้นตอน
                  </p>
                  <p className="text-normal">
                    ทั้งนี้ ในกรณีที่ต้องมีการโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ
                    บริษัทฯ
                    จะดำเนินการตามที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.
                    2562 กำหนดไว้อย่างเคร่งครัด
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">9. วิธีการติดต่อ</p>
                  <p className="text-normal">
                    ในกรณีที่มีข้อสงสัยหรือต้องการสอบถามรายละเอียดเพิ่มเติมเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของท่าน
                    โปรดติดต่อบริษัทฯ ได้ตามช่องทางดังต่อไปนี้
                  </p>
                  <p className="text-sub-header">
                    บริษัท ส.กิจชัย เอ็นเตอร์ไพรส์ จำกัด (มหาชน)
                  </p>
                  <p className="text-normal">
                    สถานที่ติดต่อ: 99/9 หมู่ที่ 7 ตำบลห้วยยาง อำเภอแกลง ระยอง
                  </p>
                  <p className="text-normal">เบอร์โทรศัพท์: 0-3892-8188</p>

                  <hr className="hr-line" />
                  <p className="text-header">
                    10. การเปลี่ยนแปลงนโยบายและแนวปฏิบัติคุ้มครองข้อมูลส่วนบุคคล
                  </p>
                  <p className="text-normal">
                    บริษัทฯ จะทำการพิจารณาทบทวนเงื่อนไขนโยบายบริษัทฯ
                    ฉบับนี้เป็นครั้งคราว เพื่อให้สอดคล้องกับแนวปฏิบัติ
                    และกฎหมายที่เกี่ยวข้อง หากมีการแก้ไขเปลี่ยนแปลงบริษัทฯ
                    จะแจ้งให้ทราบด้วยการเผยแพร่ผ่านการประกาศที่เหมาะสมของบริษัทฯ
                  </p>
                </div>
              </div>
            ) : (
              <div className="col-main">
                <div className="circle-bg-1" />
                <div className="layout-content-policy">
                  <p className="text-normal">
                    <strong>
                      S.Kijchai Enterprise Public Company Limited{" "}
                    </strong>
                    (“Company”) realizes the importance of the personal data
                    protection and has compliance and management of Personal
                    Data which are consistent with Personal Data Protection law
                    and the related laws. The Company would like to notify you
                    of the Company’s compliance with the Personal Data
                    Protection Act, B.E. 2562 (2019) as follows:
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">1. Personal Data</p>
                  <p className="text-sub-header">
                    1.1 The Characteristics of Personal Data
                  </p>
                  <p className="text-normal">
                    In this document:
                    <br />
                    “Personal Data” means any information relating to a Person,
                    which enables the identification of such Person, whether
                    directly or indirectly, but not including the information of
                    the deceased Persons in particular.
                  </p>
                  <p className="text-normal">
                    “Sensitive Personal Data” means Personal Data pertaining to
                    racial, ethnic origins, political opinions, cult, religious
                    or philosophical beliefs, sexual orientation, criminal
                    records, health data, disability, trade union information,
                    genetic data, biometric data (such as fingerprints or facial
                    recognition data) or of any data which may affect the data
                    subjects in the same manner, as prescribed by the Personal
                    Data Protection Committee
                  </p>
                  <p className="text-sub-header">
                    1.2 The Collection of Personal Data
                  </p>
                  <p className="text-normal">
                    The Company may collect your Personal Data as follows:
                  </p>
                  <p className="text-sub-header">General Personal Data:</p>
                  <p className="text-normal">
                    o (a) Personal Data including, among others, name and
                    surname, the date and place of birth, marital status,
                    information contained in an individual’s citizen identity
                    card, passport and their copies.
                  </p>
                  <p className="text-normal">
                    o (b) Contact information including, among others, address,
                    telephone number, social media identification such as Line
                    ID, and workplace contact details.
                  </p>
                  <p className="text-normal">
                    o (c) Work position, work unit or organization
                  </p>
                  <p className="text-normal">
                    o (d) Information related to electronic systems access and
                    usage such as email, IP Address, Browsers, Cookies and chat
                    history in various applications
                  </p>
                  <p className="text-normal">
                    o (e) Other information provided to the Company during
                    company events in which you participated
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">
                    2. The respect of Personal privacy
                  </p>
                  <p className="text-normal">
                    The Company respects Data Subjects’ right of their Personal
                    Data and is also aware of the Data Subjects’ intention for
                    the due protection of their Personal Data. Any Personal Data
                    submitted to the Company will be used only for the stated
                    purposes. The Company has strict security measures and a
                    prevention not to let the unauthorized parties from
                    accessing and using your personal data.
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">
                    3. The Collection of Personal Data
                  </p>
                  <p className="text-normal">
                    In the direct collection, use, and disclosure of your
                    Personal Data, the company shall solicit your consent should
                    it be legally required and shall use the Personal Data only
                    as necessary and only in accordance with the Company’s
                    specified purposes.
                  </p>
                  <p className="text-normal">
                    However, the Company may collect your Personal Data from any
                    other sources, such as from various social media, but only
                    in necessity and carried out in accordance with legal
                    requirements.
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">
                    4. Purposes for the collection, use and disclosure of
                    Personal Data
                  </p>
                  <p className="text-normal">
                    The Company collects, uses, and discloses your Personal Data
                    for the following purposes:
                  </p>
                  <p className="text-normal">
                    o For the procurement or sales of products and providing or
                    employing services in various forms
                  </p>
                  <p className="text-normal">
                    o For the performance of financial transactions and taxation
                    in compliance with the company’s contractual obligations.
                  </p>
                  <p className="text-normal">o For Research or Development</p>
                  <p className="text-normal">
                    o For the creation of a database for analyzing and proposing
                    services or products of the Company and its affiliates, or
                    sales representatives/dealers representing the Company.
                  </p>
                  <p className="text-normal">
                    o For the improvement of quality in operations, provision of
                    services, and other company related activities
                  </p>
                  <p className="text-normal">
                    o For analyzing and monitoring of the Company’s website
                    services and for investigating difficulty of use issues
                  </p>
                  <p className="text-normal">
                    o For participating various activities of the Company
                  </p>
                  <p className="text-normal">
                    o For access control, preventing life-threatening situations
                    and incidents that may harm the health or cause bodily
                    injury to yourself or others, as well as prevent and control
                    contagious diseases, and security control of the Company’s
                    buildings and premises.
                  </p>
                  <p className="text-normal">
                    o For compliance with existing or future laws and
                    regulations applicable to the Company
                  </p>
                  <p className="text-normal">
                    The Personal Data collected by the company in relation to
                    the above-mentioned purposes are required in compliance with
                    contractual obligations or in accordance with applicable
                    laws. In case of refusal to provide the required Personal
                    Data, it may be considered as an infringement of relevant
                    laws or may cause the Company not to be able to manage or
                    execute contractual obligations or facilitate services for
                    you.
                  </p>
                  <p className="text-normal">
                    However, should any modifications to the stated purposes for
                    the collection of Personal Data subsequently occur, the
                    Company will duly inform you as well as fulfill other legal
                    obligations, including the recording as evidence of the
                    amendment of the purposes stated in this Policy.
                  </p>

                  <hr className="hr-line" />
                  <p className="text-header">
                    5. The Personal Data retention period
                  </p>
                  <p className="text-normal">
                    The Company will retain your Personal Data only for the
                    necessary duration, and will collect, use and disclose your
                    Personal Data, as defined in this Policy, in accordance with
                    the duration criteria, namely the period during which you
                    are still related to the Company as a client, and may still
                    retain your Personal Data as long as needed for legal
                    compliance or as per legal prescription, for the
                    establishment of legal claims, legal compliance or exercise
                    of legal claims, or defense of legal claims, or for other
                    purposes in accordance with policies and the internal
                    regulations of the Company.
                  </p>
                  <p className="text-normal">
                    If it is not possible to specify the Personal Data retention
                    period, the Company will retain the Personal Data as may be
                    expected per data retention standards (such as the longest
                    legal prescription of 10 years).
                  </p>

                  <hr className="hr-line" />
                  <p className="text-header">6. Security Measures</p>
                  <p className="text-normal">
                    The Company has in place adequate and strict security
                    measures, in accordance with Policy and Guidelines on
                    Information Technology of the Company, to prevent Personal
                    Data, loss, access, destruction, use, alteration,
                    correction, and to prohibit unauthorized or unlawful use of
                    Personal Data.
                  </p>

                  <hr className="hr-line" />
                  <p className="text-header">
                    7. Your Rights as a Data Subject
                  </p>
                  <p className="text-normal">
                    As a Data Subject, you have the rights as prescribed in the
                    Personal Data Protection Act, B.E. 2562 (2019) and other
                    rights as follows:
                  </p>
                  <p className="text-sub-header">
                    7.1 Right to withdraw consent
                  </p>
                  <p className="text-normal">
                    You have the right to withdraw consent for the processing of
                    your Personal Data as submitted to the Company, but not if
                    the consent withdrawal is restricted by law or by contract
                    of benefit to you as a Data Subject. However, the withdrawal
                    of consent shall not affect the processing of Personal Data
                    to which you have already legally consented.
                  </p>
                  <p className="text-sub-header">
                    7.2 Right to access to the Personal Data
                  </p>
                  <p className="text-normal">
                    You are entitled to request access to and obtain a copy of
                    your Personal Data, under the Company’s care and
                    responsibility, as well as to request the disclosure of the
                    acquisition of your Personal Data without your consent.
                  </p>
                  <p className="text-sub-header">
                    7.3 Right to request for sending or transferring the
                    Personal Data (Right to data portability)
                  </p>
                  <p className="text-normal">
                    You have the legal right to request the Company to transfer
                    your Personal Data.
                  </p>
                  <p className="text-sub-header">
                    7.4 Right to object to the collection, use, or disclosure of
                    Personal Data
                  </p>
                  <p className="text-normal">
                    You have the right to object to the processing of your
                    Personal Data, namely the collection, use, or disclosure of
                    your Personal Data as prescribed by law.
                  </p>
                  <p className="text-sub-header">
                    7.5 Right to request the erasure of Personal Data (Right to
                    be forgotten)
                  </p>
                  <p className="text-normal">
                    You have the legal right to request the Company to erase,
                    destroy, or to anonymize your Personal Data.
                  </p>
                  <p className="text-sub-header">
                    7.6 Right to restrict the use of Personal Data.
                  </p>
                  <p className="text-normal">
                    You have the legal right to request the Company to suspend
                    the use of your Personal Data.
                  </p>
                  <p className="text-sub-header">
                    7.7 Right to request for the correction of the Personal Data
                  </p>
                  <p className="text-normal">
                    Should you discover that your Personal Data kept by the
                    Company is not correct or has been modified, you have the
                    legal right to request the Company to correct them so that
                    your Personal Data is accurate, up-to-date, complete, and
                    will not incur any misunderstandings.
                  </p>
                  <p className="text-sub-header">
                    7.8 Right to be informed in case of the modification of the
                    Privacy Notice
                  </p>
                  <p className="text-normal">
                    The Company may consider and modify as appropriate this
                    Privacy Notice to ensure that your Personal Data is well
                    protected.
                  </p>
                  <p className="text-sub-header">7.9 Right to Complain</p>
                  <p className="text-normal">
                    You have the right to complain to authorized officials as
                    prescribed by the Personal Data Protection Act, B.E. 2562
                    (2019) in case of the Company’s infringement or
                    non-compliance with the Act. In the event that the Data
                    Subject lodges their request to exercise their rights as
                    prescribed by the Personal Data Protection Act, B.E. 2562
                    (2019), upon reception of such request, the company will
                    further proceed within the duration of time as prescribed by
                    the Act. However, the Company reserves the right to deny or
                    not to proceed with the request as prescribed by law in case
                    that the Data Subject has chosen to provide to the Company
                    only certain Personal Data which may cause the Company not
                    to be able to provide full services. Moreover, the Company
                    may not be able to collaborate with or to provide services
                    to the Data Subject if they do not consent to the provision
                    of information as required by the Company.
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">
                    8. The Disclosure of Personal Data to a Third Party
                  </p>
                  <p className="text-normal">
                    The Company may have the need to disclose Personal Data to
                    its subsidiary or to other persons or business units that
                    are strategic allies in Thailand or in a foreign country
                    cooperating with the Company in the procurement of various
                    products or services, or out of necessity as prescribed by
                    terms and conditions of the Company, or in case of
                    reorganization, merging or sales of the Company and may
                    require the disclosure of the Personal Data to Thai
                    authorities or Public Organizations concerned as prescribed
                    by law or by court order or by the order of authorized
                    officials for which the Personal Data will be kept
                    confidential, either in paper form or electronic form,
                    including during its transfer.
                  </p>
                  <p className="text-normal">
                    However, in case of an international transfer of Personal
                    Data, the Company will strictly comply with the Personal
                    Data Protection Act, B.E. 2562 (2019).
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">9. Contact channels</p>
                  <p className="text-normal">
                    Enquires or questions on the Personal Data Protection can be
                    addressed to the following channels:
                  </p>
                  <p className="text-sub-header">
                    S.Kijchai Enterprise Public Company Limited
                  </p>
                  <p className="text-normal">
                    Address: Moo 7 Huaiyang, Klaeng Rayong
                  </p>
                  <p className="text-normal">Tel: 0-3892-8188</p>

                  <hr className="hr-line" />
                  <p className="text-header">
                    10. Amendment of the Policy and Guideline on Personal Data
                    Protection
                  </p>
                  <p className="text-normal">
                    The Company may, from time to time, review this Policy for
                    compliance with the changing guidelines and relevant laws.
                    In case of changes thereto, the Company will provide a
                    public notification through appropriate channels
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
