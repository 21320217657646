import React from "react";
import { ImgPr1, ImgPr2 } from "../../assets/Image";
import { Header } from "../../Components";
import { useGlobalContext } from "../../context/Context";
import { Link } from "react-router-dom";

export default function Public() {
  const { language } = useGlobalContext();

  return language === "T" ? (
    <div id="newActivities" className="sub-top-hight">
      <div className="container-I" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <div className="row">
          <div className="box-line">
            <div className="about-text-cover text-center">
              <p className="t-1">Welcome To S.Kijchai</p>
              <p className="t-2">ห้องข่าวประชาสัมพันธ์</p>
            </div>
          </div>
        </div>
        <div className="cover-board">
          <div className="box-content">
            <div className="box-content-1">
              <div className="pic-content">
                <img src={ImgPr1}></img>
              </div>
              <p>
                SKN โชว์ฟอร์มแกร่งคว้ารางวัล Best Company Performance Award{" "}
                <br />
                ในงาน SET Award 2022
              </p>
              <p>
                นายหาญศิริ แสงวงศ์กิจ และ นางสาวอรวรรณ แสงวงศ์กิจ กรรมการบริหาร
                SKN ผู้ผลิตและส่งออกแผ่นไม้เอ็มดีเอฟ
                โชว์ฟอร์มการบริหารงานสุดแกร่ง นำทีมคว้ารางวัล Best Company
                Performance Award กลุ่ม Business Excellence
              </p>
              <div className="dateLink">
                <p>2022-11-02 14:12:28</p>
                <Link to={"/RDetail1"} className="textLink">
                  เพิ่มเติม
                </Link>
              </div>
            </div>
            <div className="box-content-1">
              <div className="pic-content">
                <img src={ImgPr2}></img>
              </div>
              <p>SKN เทรดวันแรกคึกคัก ราคาเปิด 9.50 บ./หุ้น</p>
              <p className="text-r2">
                นางเกศรา มัญชุศรี (กลาง) กรรมการ และผู้จัดการ
                ตลาดหลักทรัพย์แห่งประเทศไทย ถ่ายภาพเป็นที่ระลึกร่วมกับ นายวิชัย
                แสงวงศ์กิจ (ที่ 3 จากขวา)
              </p>
              <div className="dateLink">
                <p>2017-09-26 15:07:59</p>
                <Link to={"/RDetail2"} className="textLink">
                  เพิ่มเติม
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="container-page">
      <div className="container-top">
        <Header nameEN={"Public Relation"} />
        <div className="container-not-data">
          <div className="container-main">
            <div className="row-main">
              <div className="col-main">
                <div className="flex-main-center">
                  <div className="text-not-data">
                    {language === "T"
                      ? "ไม่มีข้อมูล ณ ขณะนี้"
                      : "No Information Now"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
