import React from "react";
import { product1, product2, product3 } from "../../../assets/Image";
import { useGlobalContext } from "../../../context/Context";

export default function Product() {
  const { language } = useGlobalContext();

  return (
    <div className="container-banner bg-image-product">
      <div className="cover-content">
        <div className="main-content-product">
          {language === "T" ? (
            <div className="content-product">
              <p className="t1-product">ผลิตภัณฑ์และคุณสมบัติ</p>
              <p className="sub-t-product">
                แผ่นเอ็มดีเอฟเป็นผลิตภัณฑ์ที่มีคุณสมบัติใกล์เคียงไม้ธรรมชาติ
                <br />
                สามารถตัดตกแต่ง ตอกตะปู แต่งขอบและทำลิ้นร่องได้
                ทำให้สามารถนำไปใช้งานแทนไม้ธรรมชาติ
                <br />
                ได้อย่างกว้างขวางทั้งในอุตสาหกรรมวัสดุก่อสร้าง งานตกแต่ง การผลิต
              </p>
            </div>
          ) : (
            <div className="content-product">
              <p className="t1-product">Product Features</p>
              <p className="sub-t-product">
                MDF board is a product with characteristics similar to natural
                wood. It can be trimmed, nailed, grooved, laminated and painted.
                It can be used instead of natural wood in the building materials
                industry, interior work and furniture production. Fiber-boards
                come with high density, smooth surface and even thickness
                throughout the sheet.
              </p>
            </div>
          )}
          <div className="content-product-box">
            <div className="cover-product">
              <div className="sub-cover">
                <div className="product-content-top">
                  <div className="text-name-product">
                    <p className="t-1-product black">SKN Products</p>
                    <p className="t-2-product black">MDF</p>
                    <p className="t-3-product black">
                      Glue Type:
                      <br />
                      E2/E1/CARB P2/E0
                    </p>
                  </div>
                  <div className="img-product">
                    <img src={product1} />
                  </div>
                </div>
                <div className="product-content-bottom">
                  <div className="left-t-detail">
                    <p className="t-1 black"></p>
                    <p className="t-2 black">Medium Density</p>
                    <p className="t-2 black">Fiberboard</p>
                  </div>
                  <div className="right-t-detail">
                    <p className="t-1 black"></p>
                    <p className="t-2 black">1MM - 30MM</p>
                    <p className="t-2 black">Standard size & Upon request</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="cover-product">
              <div className="sub-cover">
                <div className="product-content-top">
                  <div className="text-name-product">
                    <p className="t-1-product black">SKN Products</p>
                    <p className="t-2-product black">
                      HMR <br />
                      MDF
                    </p>
                    <p className="t-3-product black">
                      Glue Type:
                      <br />
                      E2/E1/E0
                    </p>
                  </div>
                  <div className="img-product">
                    <img src={product2} />
                  </div>
                </div>
                <div className="product-content-bottom">
                  <div className="left-t-detail">
                    <p className="t-1 black">High Moisture</p>
                    <p className="t-2 black">Resistance Medium</p>
                    <p className="t-2 black">Density Fiberboard</p>
                  </div>
                  <div className="right-t-detail">
                    <p className="t-1 black"></p>
                    <p className="t-2 black">1MM - 30MM</p>
                    <p className="t-2 black">Standard size & Upon request</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="cover-product">
              <div className="sub-cover">
                <div className="product-content-top">
                  <div className="text-name-product">
                    <p className="t-1-product black">SKN Products</p>
                    <p className="t-2-product black">
                      FR
                      <br />
                      MDF
                    </p>
                    <p className="t-3-product black">
                      Glue Type:
                      <br />
                      E2/E1
                    </p>
                  </div>
                  <div className="img-product">
                    <img src={product3} />
                  </div>
                </div>
                <div className="product-content-bottom">
                  <div className="left-t-detail">
                    <p className="t-1 black">Fire Retardant</p>
                    <p className="t-2 black">Medium Density</p>
                    <p className="t-2 black">Fiberboard</p>
                  </div>
                  <div className="right-t-detail">
                    <p className="t-1 black"></p>
                    <p className="t-2 black">6MM - 30MM</p>
                    <p className="t-2 black">Standard size & Upon request</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
