import React from "react";
import { Header } from "../../Components";
import { imgCircleBg } from "../../assets/Image";
import { useGlobalContext } from "../../context/Context";
import { Link } from "react-router-dom";

export default function CookiePolicy() {
  const { language } = useGlobalContext();

  return (
    <div className="container-page">
      <div className="container-top">
        <Header nameTH={"นโยบายคุกกี้"} nameEN={"Cookie Policy"} />
        <div className="container-main">
          <div className="row-main">
            <div className="col-main">
              <img src={imgCircleBg} className="img-circle-bg-1" />
            </div>
            {language === "T" ? (
              <div className="col-main">
                <div className="circle-bg-1" />
                <div className="layout-content-policy">
                  <p className="text-normal">
                    เว็บไซต์นี้ ให้บริการโดย
                    <strong>
                      {" "}
                      บริษัท ส.กิจชัย เอ็นเตอร์ไพรส์ จำกัด (มหาชน){" "}
                    </strong>
                    {"(ในที่นี้รวมเรียกว่า"}
                    <strong> “SKN” </strong>
                    {
                      "หรือ “เรา”) เว็บไซต์นี้ใช้คุกกี้และเครื่องมืออื่นเพื่อช่วยแยกแยะรูปแบบการใช้งานเว็บไซต์ของท่านจากผู้ใช้งานอื่นๆซึ่งจะช่วยให้ท่านได้รับประสบการณ์ที่ดีจากการใช้งานเว็บไซต์และช่วยให้เราสามารถพัฒนาคุณภาพของเว็บไซต์ให้ดียิ่งขึ้นกรณีที่ท่านใช้งานเว็บไซต์นี้ต่อไปถือว่าท่านได้ยินยอมให้เราติดตั้งคุกกี้ไว้ในเครื่องคอมพิวเตอร์ของท่าน"
                    }
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">คุกกี้ คือ อะไร</p>
                  <p className="text-normal">
                    คุกกี้ คือ
                    ไฟล์ข้อความขนาดเล็กที่ถูกดาวน์โหลดไปยังอุปกรณ์คอมพิวเตอร์หรืออุปกรณ์มือถือของท่าน
                    ซึ่งทำหน้าที่บันทึกข้อมูลและการตั้งค่าต่าง ๆ เช่น
                    บันทึกข้อมูลการตั้งค่าภาษาในเบราว์เซอร์บนอุปกรณ์ของท่าน
                    บันทึกสถานะการเข้าใช้งานในปัจจุบันของท่าน
                    เพื่อช่วยให้ท่านสามารถเข้าใช้งานเว็บไซต์ได้อย่างต่อเนื่อง
                    รวมถึงมีการรวบรวมข้อมูลเกี่ยวกับประวัติการเข้าชมเว็บไซต์ที่ท่านชื่นชอบในรูปแบบไฟล์
                    โดยคุกกี้ไม่ได้ทำให้เกิดอันตรายต่ออุปกรณ์ของท่าน
                    และเนื้อหาในคุกกี้จะถูกเรียกออกมาดูหรืออ่านได้โดยเว็บไซต์ที่สร้างคุกกี้ดังกล่าวเท่านั้น
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">ประโยชน์ ของ คุกกี้</p>
                  <p className="text-normal">
                    คุกกี้จะบอกให้เราทราบว่าท่านเข้าชมส่วนใดในเว็บไซต์ของเราเพื่อที่เราจะสามารถมอบประสบการณ์การใช้
                    งานเว็บไซต์ที่ดีขึ้นและตรงกับความต้องการของท่านได้
                    นอกจากนี้การบันทึกการตั้งค่าแรกของเว็บไซต์ด้วยคุกกี้จะช่วยให้ท่านเข้าถึงเว็บไซต์ด้วยค่าที่ตั้งไว้ทุกครั้งที่ใช้งาน
                    ยกเว้นในกรณีที่คุกกี้ถูกลบซึ่งจะทำให้การตั้งค่าทุกอย่างจะกลับไปที่ค่าเริ่มต้น
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">การใช้งาน คุกกี้</p>
                  <p className="text-normal">
                    เราใช้คุกกี้และเทคโนโลยีอื่นบนเว็บไซต์ของเรา (รวมเรียกว่า
                    “บริการที่อ้างอิงนโยบายการใช้คุกกี้”) ดังนั้น
                    เบราว์เซอร์ใดก็ตาม
                    ที่ท่านใช้เพื่อเข้าถึงบริการที่อ้างอิงนโยบายการใช้คุกกี้จะได้รับคุกกี้จากเราเมื่อท่านเข้าถึงบริการที่อ้างอิงนโยบายการใช้คุกกี้
                    คุกกี้ของเราจะถูกดาวน์โหลดไปยังอุปกรณ์ของท่านเพื่อเก็บข้อมูลเกี่ยวกับรูปแบบการใช้และประวัติการใช้งานเว็บไซต์
                    ข้อมูลหรือบริการที่ท่านสนใจ
                    รวมถึงหมายเลขอ้างอิงการเข้าใช้งานล่าสุดของท่าน
                    เป็นต้นอย่างไรก็ตาม
                    เราจะใช้งานคุกกี้ภายใต้รายละเอียดที่ระบุในนโยบายคุ้มครองข้อมูลส่วนบุคคล
                    ซึ่งท่านสามารถศึกษาข้อมูลเพิ่มเติมได้จาก
                    <Link to={"/PrivacyPolicy"}>
                      {" "}
                      http://www.skn.co.th/privacy-policy
                    </Link>
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">
                    สำหรับประเภทคุกกี้ที่เราใช้งาน สามารถแบ่งประเภทได้ดังนี้
                  </p>
                  <p className="text-normal">
                    1. คุกกี้เพื่อการให้บริการ
                    คุกกี้เพื่อการให้บริการจะช่วยให้ท่านสามารถใช้บริการ
                    และ/หรือเข้าถึงฟังก์ชันต่าง ๆ ได้ เช่น
                    คุกกี้ที่จัดการเรื่องการเข้าสู่ระบบ (Login)
                    และสถานะการเข้าสู่ระบบของท่าน
                  </p>
                  <p className="text-normal">
                    2. คุกกี้ที่ช่วยเสริมประสิทธิภาพในการใช้บริการ
                    คุกกี้ที่ช่วยเสริมประสิทธิภาพในการใช้บริการ
                    จะช่วยอำนวยความสะดวกแก่ท่านในการใช้บริการ
                    รวมทั้งช่วยให้เรารู้จักท่านมากยิ่งขึ้น
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">
                    ตัวอย่างการอำนวยความสะดวกแก่ท่านในการใช้บริการด้วยคุกกี้ประเภทนี้
                    คือ
                  </p>
                  <p className="text-normal">
                    • ช่วยจำสถานะของการใช้บริการ เช่น ภาษาที่เลือกใช้
                  </p>
                  <p className="text-normal">
                    • ช่วยจำคำตอบที่ท่านเคยตอบไปแล้ว
                    ซึ่งจะช่วยให้ท่านไม่ต้องตอบคำถามเดิมซ้ำอีก
                  </p>
                  <p className="text-normal">
                    • ให้บริการฟังก์ชัน Social Media Sharing
                    ซึ่งจะช่วยให้ท่านสามารถแบ่งปันเนื้อหาที่สนใจบนเครือข่ายสังคมออนไลน์
                    (Social Network)
                  </p>
                  <p className="text-normal">
                    ทั้งนี้
                    เพื่อการให้บริการและเพื่ออำนวยความสะดวกแก่ท่านในการใช้บริการตามตัวอย่างข้างต้น
                    เรามีการใช้งานโปรแกรมเสริม (Plugin) และบริการของบุคคลที่สาม
                    เช่น Google Analytics, Facebook Analytics, True hits
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">การตั้งค่าคุกกี้</p>
                  <p className="text-normal">
                    ท่านสามารถบล็อกการทำงานของคุกกี้ได้โดยการกำหนดค่าในเบราวเซอร์ของท่าน
                    ซึ่งท่านอาจปฏิเสธการติดตั้งค่าคุกกี้ทั้งหมดหรือบางประเภทก็ได้
                    แต่พึงตระหนักว่าหากท่านตั้งค่าเบราวเซอร์ของท่านด้วยการบล็อกคุกกี้ทั้งหมด
                    (รวมถึงคุกกี้ที่จำเป็นต่อการใช้งาน)
                    ท่านอาจจะไม่สามารถเข้าสู่เว็บไซต์ทั้งหมดหรือบางส่วนของเราได้
                    เมื่อใดก็ตามที่ท่านต้องการยกเลิกความยินยอมการใช้งานคุกกี้
                    ท่านจะต้องตั้งค่าเบราวเซอร์ของท่านเพื่อให้ลบคุกกี้ออกจากแต่ละเบราวเซอร์ที่ท่านใช้งาน
                    หากท่านต้องการข้อมูลเพิ่มเติมเกี่ยวกับวิธีการดังกล่าว
                    โปรดเลือกหัวข้อ “ความช่วยเหลือ”
                    ในอินเตอร์เน็ตเบราวเซอร์ของท่านเพื่อศึกษาในรายละเอียดมากขึ้น
                  </p>
                </div>
              </div>
            ) : (
              <div className="col-main">
                <div className="circle-bg-1" />
                <div className="layout-content-policy">
                  <p className="text-normal">
                    <strong>
                      S.Kijchai Enterprise Public Company Limited{" "}
                    </strong>
                    {"( "}
                    <strong> “SKN” </strong>

                    {
                      ", 'we', 'us' and 'our') operate this website. This website uses cookies and other tools to help distinguish you from other users of the website. This helps us to provide you with a good experience when you use the website and allows us to improve the website. By continuing to use the website, you are agreeing to us placing cookies on your computer."
                    }
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">WHAT ARE COOKIES</p>
                  <p className="text-normal">
                    Cookies are small text files that are downloaded to your
                    computer or mobile device which is responsible for saving
                    various data and settings for example, saving the language
                    setting information in the browser on your device, record
                    your current access status to help you access the website
                    continuously. Including the collection of the history of
                    your favorite websites in a file format. The cookies do not
                    harm your device and the content of the cookie can only be
                    retrieved or read by the website that created the cookie.
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">BENEFITS OF COOKIES</p>
                  <p className="text-normal">
                    Cookies tell us what parts of our website you are visiting.
                    In order for us to provide you with a better website
                    experience and tailored to your needs, saving the first
                    settings of the website with cookies will allow you to
                    access the website with the settings you set every time you
                    use it. Unless the cookies are deleted which will cause
                    everything settings to be returned to their defaults.
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">USE OF COOKIES</p>
                  <p className="text-normal">
                    We use cookies and other technologies on our website
                    (collectively, the "Cookie Policy Reference Service"),
                    therefore, any browser you use to access the cookies
                    policy-based services will receive cookies from us when you
                    access the services that refer to the Cookie Policy. Our
                    cookies will be downloaded to your device to store
                    information about your usage patterns and website usage
                    history. Information or services that you are interested in
                    Including your last access reference number, etc. However,
                    we will use cookies in accordance with the details set out
                    in the Privacy Policy. Which you can learn more from
                    <Link to={"/PrivacyPolicy"}>
                      {" "}
                      http://www.skn.co.th/privacy-policy
                    </Link>
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">
                    For the types of cookies, we use Can be categorized as
                    follows.
                  </p>
                  <p className="text-normal">
                    1. Cookies for serving. Serving cookies help you to use the
                    service. And /or access to functions such as cookies that
                    manage your login and login status.
                  </p>
                  <p className="text-normal">
                    2. Cookies that improve the performance of the service
                    Cookies that improve the performance of the service will
                    help facilitate you in using the service as well as help us
                    to know you better.
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">
                    Examples of facilitating your use of services with these
                    types of cookies are:
                  </p>
                  <p className="text-normal">
                    • It helps to remember the status of using the service, such
                    as the language selected.
                  </p>
                  <p className="text-normal">
                    • It helps to remember the answers you have already
                    answered. This will save you from having to answer the same
                    question again.
                  </p>
                  <p className="text-normal">
                    • Provides a Social Media Sharing function, which allows you
                    to share content of interest on social networks.
                  </p>
                  <p className="text-normal">
                    This is for the purpose of providing the service and to
                    facilitate you in using the services as described above. We
                    use third party plugins and services such as Google
                    Analytics, Facebook Analytics, Truehits.
                  </p>
                  <hr className="hr-line" />
                  <p className="text-header">Cookie settings</p>
                  <p className="text-normal">
                    You can block cookies by setting them in your browser. You
                    may refuse the installation of all or some types of cookies.
                    But be aware that if you set up your browser to block all
                    cookies. (Including essential functional cookies), you may
                    not be able to access all or part of our websites. Whenever
                    you wish to withdraw your consent to the use of cookies. You
                    need to set up your browser to delete cookies from each
                    browser you use. If you need more information on how to do
                    so, please select a topic "Help" in your Internet browser to
                    learn more.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
