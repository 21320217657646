import React from "react";
import { Banner } from "../Home/Components";
import { ContentIr, EmailContact, Financial, InvestorKits } from "./Components";

export default function Investor() {
  return (
    <div className="container-d">
      <Banner
        bg={"bg-image-banner-ir"}
        text1={"WELCOME TO"}
        text2={"INVESTOR"}
        text3={" RELATIONS."}
        subText={"S.KIJCHAI ENTERPRISE"}
      />
      <ContentIr />
      <Financial />
      <InvestorKits />
      <EmailContact />
    </div>
  );
}
