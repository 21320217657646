import React from "react";
import {
  boardData1,
  boardData1en,
  boardData2,
  boardData2en,
  boardData3,
  boardData3en,
} from "../../mock/MockupBoardData";
import { useGlobalContext } from "../../context/Context";

export default function Board() {
  const { language } = useGlobalContext();
  return (
    <section className="mt-36 mb-8 lg:mt-44 container mx-auto px-4">
      {language === "T" ? (
        <>
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-3xl  text-[#1b3a91] font-bold">
                คณะกรรมการบริษัทและผู้บริหาร
              </h1>
            </div>
          </div>
          <div className="box-line">
            <p className="t-h">คณะกรรมการบริษัท</p>
            <div className="relative overflow-x-auto">
              <table className="w-full table-respon mt-4 text-sm text-center text-white">
                <thead className="text-md text-white bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                  <tr>
                    <th scope="col" className="px-6 py-4 text-left">
                      ลำดับ
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                      รายชื่อ
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                      ตำแหน่ง
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                      ประวัติ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {boardData1.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        className="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                      >
                        <td
                          scope="row"
                          className="px-6 py-4 text-[#1b3a91] text-left"
                        >
                          {i + 1}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 text-black  text-left"
                        >
                          {item.name}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 text-black  text-left"
                        >
                          {item.position}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 text-[#1b3a91] text-left"
                        >
                          Detail
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="box-line mt-3">
            <p className="t-1">หมายเหตุ :</p>
            <p className="t-2">
              1/ น.ส.วิชุตา แสงวงศ์กิจ และนายหาญศิริ แสงวงศ์กิจ
              เป็นสมาชิกในคณะกรรมการชุดย่อย แต่ไม่ได้เป็นกรรมการบริษัท
            </p>
          </div>

          <div className="box-line">
            <p className="t-h">คณะกรรมการบริหาร</p>

            <div className="relative overflow-x-auto">
              <table className="w-full table-respon mt-4 text-sm text-center text-white">
                <thead className="text-md text-white bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                  <tr>
                    <th scope="col" className="px-6 py-4 text-left">
                      ลำดับ
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                      รายชื่อ
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                      ตำแหน่ง
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                      ประวัติ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {boardData2.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        className="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                      >
                        <td
                          scope="row"
                          className="px-6 py-4 text-[#1b3a91] text-left"
                        >
                          {i + 1}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 text-black text-left"
                        >
                          {item.name}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 text-black text-left"
                        >
                          {item.position}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 text-[#1b3a91]  text-left"
                        >
                          Detail
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="box-line" style={{ marginBottom: 100 }}>
            <p className="t-h">ผู้บริหาร</p>

            <div className="relative overflow-x-auto">
              <table className="w-full table-respon mt-4 text-sm text-center text-white">
                <thead className="text-md text-white bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                  <tr>
                    <th scope="col" className="px-6 py-4 text-left">
                      ลำดับ
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                      รายชื่อ
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                      ตำแหน่ง
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                      ประวัติ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {boardData3.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        className="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                      >
                        <td
                          scope="row"
                          className="px-6 py-4 text-[#1b3a91] text-left"
                        >
                          {i + 1}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 text-black text-left"
                        >
                          {item.name}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 text-black text-left"
                        >
                          {item.position}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 text-[#1b3a91] text-left"
                        >
                          Detail
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-3xl  text-[#1b3a91] font-bold">
                Board of Director & Management Team
              </h1>
            </div>
          </div>

          <div className="box-line">
            <p className="t-h">Board of Director</p>
            <div className="relative overflow-x-auto">
              <table className="w-full table-respon mt-4 text-sm text-center text-white">
                <thead className="text-md text-white bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                  <tr>
                    <th scope="col" className="px-6 py-4 text-left">
                      No.
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                      Position
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                      Detail
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {boardData1en.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        className="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                      >
                        <td
                          scope="row"
                          className="px-6 py-4 text-[#1b3a91] text-left"
                        >
                          {i + 1}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 text-black text-left"
                        >
                          {item.name}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 text-black text-left"
                        >
                          {item.position}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 text-[#1b3a91] text-left"
                        >
                          Detail
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="box-line mt-3">
            <p className="t-1">Note :</p>
            <p className="t-2">
              1/ Miss Vichuta Saengvongkij and Mr. Harnsiri Sangwongkit are a
              member of sub-committee board, but not a part of the Company’s
              board member.
            </p>
          </div>

          <div className="box-line">
            <p className="t-h">Executive Committee</p>

            <div className="relative overflow-x-auto">
              <table className="w-full table-respon mt-4 text-sm text-center text-white">
                <thead className="text-md text-white bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                  <tr>
                    <th scope="col" className="px-6 py-4 text-left">
                      No.
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                      Position
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                      Detail
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {boardData2en.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        className="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                      >
                        <td
                          scope="row"
                          className="px-6 py-4 text-[#1b3a91] font-medium text-left"
                        >
                          {i + 1}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 text-black text-left"
                        >
                          {item.name}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 text-black text-left"
                        >
                          {item.position}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 text-[#1b3a91] text-left"
                        >
                          Detail
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="box-line" style={{ marginBottom: 100 }}>
            <p className="t-h">Executives</p>

            <div className="relative overflow-x-auto">
              <table className="w-full table-respon mt-4 text-sm text-center text-white">
                <thead className="text-md text-white bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                  <tr>
                    <th scope="col" className="px-6 py-4 text-left">
                      No.
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                      Position
                    </th>
                    <th scope="col" className="px-6 py-4 text-left">
                      Detail
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {boardData3en.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        className="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                      >
                        <td
                          scope="row"
                          className="px-6 py-4 text-[#1b3a91] text-left"
                        >
                          {i + 1}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 text-black text-left"
                        >
                          {item.name}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 text-black text-left"
                        >
                          {item.position}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 text-[#1b3a91] text-left"
                        >
                          Detail
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </section>
  );
}
