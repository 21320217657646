import React from "react";
import { useGlobalContext } from "../../../context/Context";

export default function CardReport({ img, year, link }) {
  const { language } = useGlobalContext();

  return language === "T" ? (
    <div className="cover-bg-content-report">
      <img src={img} />
      <p className="t1">รายงานประจำปี {year}</p>
      <p className="t2">
        รายงานประจำปีฉบับนี้เป็นข้อมูลนำเสนอผลสำเร็จของการดำเนินงานตามแผนปฏิบัติงานของบริษัท
        ส.กิจชัย เอ็นเตอร์ไพรส์ จำกัด (มหาชน)
      </p>
      <div className="cover-bt-report">
        <a href={link} download>
          <button className="contact-bt-2">ดาวน์โหลด</button>
        </a>
      </div>
    </div>
  ) : (
    <div className="cover-bg-content-report">
      <img src={img} />
      <p className="t1">Annual Report {year}</p>
      <p className="t2">
        This annual report presents the success of the implementation plan of
        S.Kijchai Enterprise Public Company Limited.
      </p>
      <div className="cover-bt-report">
        <a href={link} download>
          <button className="contact-bt-2">Download</button>
        </a>
      </div>
    </div>
  );
}
