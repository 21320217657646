import React from "react";
import { outSetAward } from "../../../assets/Image";
import { useGlobalContext } from "../../../context/Context";
import { Link } from "react-router-dom";

export default function ContentIr() {
  const { language } = useGlobalContext();

  return (
    <div className="container-content-ir">
      <div className="container-main">
        <div className="cover-banner-other-content">
          <img src={outSetAward} />
        </div>
        {language === "T" ? (
          <div className="cover-our-ir">
            <p className="t1">S.KIJCHAI ENTERPRISE</p>
            <p className="t2">Our Investor Relations.</p>
            <div className="content-row margin-button">
              <div className="main-col">
                <Link to={"/GeneralMeeting"}>
                  <div className="layout-our-ir img-our-ir-1">
                    <figure className="effect-action">
                      <figcaption>
                        <h2>การประชุมผู้ถือหุ้น</h2>
                        <p>
                          ข้อมูลหลักเกณฑ์และรูปแบบที่จะต้องแจ้งให้ผู้ถือหุ้นทราบล่วงหน้า
                          รวมถึงวาระการประชุม การประชุม
                          จะจัดขึ้นในที่ประชุมผู้ถือหุ้น
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                </Link>
              </div>
              <div className="main-col">
                <Link to={"/DividendPolicy"}>
                  <div className="layout-our-ir img-our-ir-2">
                    <figure className="effect-action">
                      <figcaption>
                        <h2>นโยบายการจ่ายเงินปันผล</h2>
                        <p>
                          ข้อมูลชี้แจงในเรื่องรายละเอียดของนโยบายการจ่ายเงินปันผล
                          และข้อมูลการจ่ายเงินปันผลให้แก่นักลงทุน
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                </Link>
              </div>
            </div>
            <div className="content-row margin-button">
              <div className="main-col">
                <Link to={"/MajorShareholder"}>
                  <div className="layout-our-ir img-our-ir-3">
                    <figure className="effect-action">
                      <figcaption>
                        <h2>โครงสร้างผู้ถือหุ้น</h2>
                        <p>
                          ข้อมูลการถือหุ้นทั้งหมดของ บริษัทฯ
                          เพื่อเป็นข้อมูลให้นักลงทุนได้ศึกษาและใช้ประกอบกับข้อมูลอื่นๆ
                          เมื่อต้องการตัดสินใจลงทุน
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                </Link>
              </div>
              <div className="main-col">
                <Link to={"/Corporate"}>
                  <div className="layout-our-ir img-our-ir-4">
                    <figure className="effect-action">
                      <figcaption>
                        <h2>การกำกับดูแลกิจการ</h2>
                        <p>
                          การจัดการระบบ
                          โดยวัตถุประสงค์เพื่อสร้างประโยชน์ที่เหมาะสมแก่ผู้ถือหุ้น
                          และคำนึงถึงผู้มีส่วนได้เสียโดยรวม
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className="cover-our-ir">
            <p className="t1">S.KIJCHAI ENTERPRISE</p>
            <p className="t2">Our Investor Relations.</p>
            <div className="content-row margin-button">
              <div className="main-col">
                <Link to={"/GeneralMeeting"}>
                  <div className="layout-our-ir img-our-ir-1">
                    <figure className="effect-action">
                      <figcaption>
                        <h2>Meeting of Shareholders</h2>
                        <p>
                          Information to inform shareholders in advance. The
                          meeting will be held at a meeting of shareholders.
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                </Link>
              </div>
              <div className="main-col">
                <Link to={"/DividendPolicy"}>
                  <div className="layout-our-ir img-our-ir-2">
                    <figure className="effect-action">
                      <figcaption>
                        <h2>Dividend Policy</h2>
                        <p>
                          Details of the dividend policy. And information on
                          dividend payments to investors.
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                </Link>
              </div>
            </div>
            <div className="content-row margin-button">
              <div className="main-col">
                <Link to={"/MajorShareholder"}>
                  <div className="layout-our-ir img-our-ir-3">
                    <figure className="effect-action">
                      <figcaption>
                        <h2>Major Shareholder</h2>
                        <p>
                          Shareholding information of Company. Provide
                          information for investors to make investment
                          decisions.
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                </Link>
              </div>
              <div className="main-col">
                <Link to={"/Corporate"}>
                  <div className="layout-our-ir img-our-ir-4">
                    <figure className="effect-action">
                      <figcaption>
                        <h2>Corporate Governance</h2>
                        <p>
                          Management by objectives to create useful and proper
                          consideration of the stakeholders.
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
