import {
  investorBook2017,
  investorBook2018,
  investorBook2019,
  investorBook2020,
  investorBook2021,
  investorBook2022,
  investorBook2023,
  investorBook2023E,
} from "../assets/Image";

export const dataReportBook = [
  {
    img: investorBook2023,
    year: 2566,
    link: "pdf/an_SKN_2023.pdf",
  },
  {
    img: investorBook2022,
    year: 2565,
    link: "pdf/an_SKN_2022.pdf",
  },
  {
    img: investorBook2021,
    year: 2564,
    link: "pdf/an_skn_2021.pdf",
  },
  {
    img: investorBook2020,
    year: 2563,
    link: "pdf/an_skn_2020.pdf",
  },
  {
    img: investorBook2019,
    year: 2562,
    link: "pdf/an_skn_2019.pdf",
  },
  {
    img: investorBook2018,
    year: 2561,
    link: "pdf/an_skn_2018.pdf",
  },
  {
    img: investorBook2017,
    year: 2560,
    link: "pdf/an_skn_2017.pdf",
  },
];

export const dataReportBookEN = [
  {
    img: investorBook2023E,
    year: 2023,
    link: "pdf/an_SKN_2023_E.pdf",
  },
  {
    img: investorBook2022,
    year: 2022,
    link: "pdf/an_SKN_2022.pdf",
  },
  {
    img: investorBook2021,
    year: 2021,
    link: "pdf/an_skn_2021.pdf",
  },
  {
    img: investorBook2020,
    year: 2020,
    link: "pdf/an_skn_2020.pdf",
  },
  {
    img: investorBook2019,
    year: 2019,
    link: "pdf/an_skn_2019.pdf",
  },
  {
    img: investorBook2018,
    year: 2018,
    link: "pdf/an_skn_2018.pdf",
  },
  {
    img: investorBook2017,
    year: 2017,
    link: "pdf/an_skn_2017.pdf",
  },
];
