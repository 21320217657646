import React from "react";
import { logo } from "../../../assets/Logo";
import { ImgPr22 } from "../../../assets/Image";

export default function RelationDetail1() {
  return (
    <>
      <div className="container-Detail">
        <div className="h-bg" style={{ margin: "auto", marginTop: "10rem" }}>
          {/* <div className="f-detail">
            <img src={logo} className="logo-Dbar"></img>
          </div> */}
        </div>
        <div className="row">
          <div className="box-line">
            <div className="about-text-cover text-center">
              <p className="t-1">Welcome To S.Kijchai</p>
              <p className="t-2">ห้องข่าวประชาสัมพันธ์</p>
            </div>
          </div>
        </div>
      </div>
      <div className="cover-board-D">
        <img src={ImgPr22} className="pic-RDetail"></img>
        <b style={{ margin: "auto", textDecoration: "underline" }}>
          SKN เทรดวันแรกคึกคัก ราคาเปิด 9.50 บ./หุ้น
        </b>
        <p className="text-d">
          นางเกศรา มัญชุศรี (กลาง) กรรมการ และผู้จัดการ
          ตลาดหลักทรัพย์แห่งประเทศไทย ถ่ายภาพเป็นที่ระลึกร่วมกับ นายวิชัย
          แสงวงศ์กิจ (ที่ 3 จากขวา) กรรมการผู้จัดการ บริษัท ส.กิจชัย
          เอ็นเตอร์ไพรส์ จำกัด (มหาชน) หรือ SKN และนายไพบูลย์ นลินทรางกูร (ที่3
          จากซ้าย) ประธานเจ้าหน้าที่บริหาร บริษัทหลักทรัพย์ ทิสโก้ จำกัด
          ในโอกาสที่ SKN เข้าซื้อขายวันแรกในตลาดหลักทรัพย์แห่งประเทศไทย
          และได้รับการตอบรับจากนักลงทุนอย่างอบอุ่น โดยราคาเปิดการซื้อขายอยู่ที่
          9.50 บาทต่อหุ้น เพิ่มขึ้นร้อยละ 29.25 เหนือราคาจองที่ 7.35 บาทต่อหุ้น
          ตอกย้ำความเชื่อมั่นในฐานะผู้ผลิตและส่งออกแผ่นไม้เอ็มดีเอฟชั้นนำของประเทศไทย
          เข้ามาเป็นส่วนหนึ่งของ SET เพื่อสร้างความแข็งแกร่งให้ธุรกิจ
          และการเติบโตอย่างยั่งยืน
          โดยมีคณะกรรมการและผู้บริหารระดับสูงถ่ายภาพร่วมกันเป็นที่ระลึก ณ
          หอประชุมศุกรีย์ แก้วเจริญ อาคารตลาดหลักทรัพย์แห่งประเทศไทย
        </p>
        <p style={{ margin: "auto" }}>************************************</p>
        <div className="contact-RDetail">
          <b style={{ color: "black" }}>ติดต่อฝ่ายประชาสัมพันธ์ IR PLUS</b>
          <div className="linkD">
            <p>
              คุณสารภี สายะเวส (จูน) โทร : 02-022-6200 ต่อ 610, 081-854-8170
              email :
            </p>
            <a
              style={{ marginTop: 16, gap: 1 }}
              href="mailto:sarapee@irplus.in.th"
            >
              sarapee@irplus.in.th
            </a>
          </div>
          <div className="linkD">
            <p>
              คุณชนนาถ ไตรทรัพย์ (ฟ้า) โทร: 02-022-6200 ต่อ 614, 095-249-3666
              email :
            </p>
            <a
              style={{ marginTop: 16, gap: 1 }}
              href="mailto:chonnanart@irplus.in.th"
            >
              chonnanart@irplus.in.th
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
