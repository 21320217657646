import React, { useEffect, useState } from 'react'
import { useGlobalContext } from '../../../context/Context'
import { Header } from '../../../Components'
import {
  getDateSetRealtime,
  getStockPriceHistory,
} from '../../../Service/Service'
import { BsGraphUpArrow } from 'react-icons/bs'
import CardCountNumber from '../Components/CardCountNumber'
import StockPriceChart from '../../../Components/Chart/StockPriceChart'

export default function StockPrice() {
  const { language } = useGlobalContext()
  const [dataSet, setDataSet] = useState([])

  const [dataTime, setDataTime] = useState(
    language === 'E' ? 'DD / MMMMM / YYYY / HH:MM' : 'วว / ดดดดด / ปปปป / ซซ:นน'
  )
  const [dataSETRealtime, setDataSETRealtime] = useState({
    time: dataTime,
    high: 0,
    low: 0,
    last: 0,
    change: 0,
    percentChange: '',
    totalVolume: 0,
    totalValue: 0,
    prvTime: '',
  })

  useEffect(() => {
    let active = true

    const getSetRealtime = async () => {
      const { status, result } = await getDateSetRealtime()
      if (active && status && result.data && result.data.time) {
        const now = new Date(result.data.time)
        const options = {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: language === 'T' ? false : true,
        }
        const formattedDateTime = now.toLocaleDateString(
          language === 'E' ? 'en-EN' : 'th-TH',
          options
        )

        setDataSETRealtime(result.data)
        setDataTime(formattedDateTime)
      }
    }

    getSetRealtime()
    return () => {
      active = false
    }
  }, [language])

  useEffect(() => {
    let active = true
    if (active) {
      const getStockPriceHistoryApi = async () => {
        const { status, result } = await getStockPriceHistory()
        if (status) {
          const data = result.sort((a, b) => a.time - b.time)

          const convertISOToUTCTimestamp = (isoString) => {
            const match = isoString.match(
              /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?)([+-]\d{2}:\d{2})$/
            )
            if (!match) {
              console.error('Invalid ISO 8601 date-time format')
              return null
            }
            const [, dateTimePart, offsetPart] = match
            const dateTime = new Date(dateTimePart).getTime()
            const offsetMinutes = parseInt(offsetPart, 10) * 60

            return dateTime - offsetMinutes * 60 * 1000
          }

          const convertTimestampToISOString = (timestamp) => {
            const date = new Date(timestamp)
            return date.toISOString()
          }

          const convertedTime = data.map((item) => ({
            ...item,
            time: item.time ? convertISOToUTCTimestamp(item.time) : null,
          }))

          const convertedToISOString = convertedTime.map((item) => ({
            ...item,
            time: item.time ? convertTimestampToISOString(item.time) : null,
          }))

          // กรองข้อมูลที่ time เป็น null ออก
          const filteredData = convertedToISOString.filter(
            (item) => item.time !== null
          )

          const convertedData = filteredData.map((item) => ({
            ...item,
            time: item.time.split('T')[0], // แยกเฉพาะวันที่ออกมา
          }))

          // ใช้ reduce เพื่อเก็บเฉพาะรายการล่าสุดของ time แต่ละค่า
          const uniqueData = Object.values(
            convertedData.reduce((acc, item) => {
              acc[item.time] = item
              return acc
            }, {})
          )

          // เรียงลำดับข้อมูลตามวันที่จากน้อยไปมาก
          const sortedData = uniqueData.sort((a, b) => {
            const dateA = new Date(a.time)
            const dateB = new Date(b.time)
            return dateA - dateB
          })

          setDataSet(sortedData)
        }
      }
      getStockPriceHistoryApi()
    }

    return () => {
      active = false
    }
  }, [])
  return (
    <div className='container-page'>
      <div className='container-top'>
        <Header nameTH={'ราคาหลักทรัพย์ล่าสุด'} nameEN={'Stock Price'} />
        {dataSETRealtime ? (
          <div className='container-main'>
            <div className='content-stock-info'>
              <div className='stock-info-left'>
                <div className='card-textInfo'>
                  <div className='content-about-info'>
                    <BsGraphUpArrow />
                    <span className='sharesInfo'>SKN</span>
                  </div>
                  {/* <hr /> */}
                  <div className='content-about-info'>
                    <span className='t-s'>
                      {language === 'T' ? 'ราคาล่าสุด' : 'Last'}
                    </span>
                    <CardCountNumber
                      start={0}
                      end={dataSETRealtime?.last}
                      duration={1200}
                    />
                  </div>
                </div>
                <div className='card-content-4'>
                  <hr />
                  {/* <div className="card-stock-textInfo">
                    <p className="t-xs">
                      {language === "E" ? "Last Update :" : "ปรับปรุงเมื่อ  :"}
                    </p>
                    <span className="t-xs">{dataTime} </span>
                  </div> */}
                </div>
              </div>
              <div className='stock-info-right'>
                {/* <div className="card-textInfo">
                  <div className="content-about-info">
                    <p className="t-s">
                      {language === "E" ? "Prior :" : "วันก่อนหน้า :"}
                    </p>
                    <p className="t-xl">
                      {dataSETRealtime?.historicalPrice?.last}
                    </p>
                  </div>
                  <div className="content-about-info">
                    <p className="t-s">
                      {language === "E" ? "Close :" : "ราคาปิด :"}
                    </p>
                    <p className="t-xl">
                      {dataSETRealtime?.historicalPrice?.close}
                    </p>
                  </div>
                </div> */}

                <div className='card-textInfo'>
                  <div className='content-about-info'>
                    <p className='t-s'>
                      {language === 'E' ? 'Last Update :' : 'ปรับปรุงเมื่อ  :'}
                    </p>
                    <p className='t-s'>{dataTime}</p>
                  </div>
                  <div className='content-about-info'>
                    <p className='t-s'>
                      {language === 'E'
                        ? 'Change (% Change) :'
                        : 'เปลี่ยนแปลง (% เปลี่ยนแปลง) :'}
                    </p>
                    <p className='t-xl'>
                      {dataSETRealtime?.change} (
                      {dataSETRealtime?.percentChange})
                    </p>
                  </div>
                </div>
                <hr />

                <div className='card-textInfo'>
                  <div className='content-about-info'>
                    <p className='t-s'>
                      {language === 'E'
                        ? 'Volume (Shares)'
                        : 'ปริมาณซื้อขาย (หุ้น) :'}
                    </p>
                    <p className='t-xl'>
                      {dataSETRealtime?.totalVolume.toLocaleString('en-US')}
                    </p>
                  </div>
                  <div className='content-about-info'>
                    <p className='t-s'>
                      {language === 'E'
                        ? 'Value (Baht) :'
                        : 'มูลค่าการซื้อขาย :'}
                    </p>
                    <p className='t-xl'>
                      {dataSETRealtime?.totalValue.toLocaleString('en-US')}
                    </p>
                  </div>{' '}
                  {/* <div className="content-about-info">
                    <p className="t-xs">
                      {language === "E" ? "Last Update :" : "ปรับปรุงเมื่อ  :"}
                    </p>
                    <p className="t-xl">{dataTime}</p>
                  </div> */}
                  {/* <div className="content-about-info"></div> */}
                </div>
              </div>
            </div>
            <StockPriceChart
              dataSet={dataSet}
              name={'SKN'}
              colorMain={'#0974BA'}
              lineTopColor={'#1B3C93'}
              linebottomColor={'#FFFFFF'}
              lineColor={'#1B3C93'}
              volumeColor={'#0974BA'}
              miniColor={'rgba(9, 116, 186, 0.5)'}
              miniLinebottomColor={'rgba(9, 116, 186, 0.5)'}
              miniLineColor={'rgba(9, 116, 186, 0.5)'}
            />
          </div>
        ) : (
          <div className='container-not-data'>
            <div className='container-main'>
              <div className='row-main'>
                <div className='col-main'>
                  <div className='flex-main-center'>
                    <div className='text-not-data'>
                      {language === 'T'
                        ? 'ไม่มีข้อมูล ณ ขณะนี้'
                        : 'No Information Now'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
