import React from "react";
import { useGlobalContext } from "../../context/Context";
import { Link } from "react-router-dom";

export default function Inquiry() {
  const { language } = useGlobalContext();
  return (
    <>
      {language === "T" ? (
        <section className="mt-36 lg:mt-44 container mx-auto px-4">
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1 text-xl">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-4xl  text-[#1b3a91] font-bold">
                สอบถามข้อมูล
              </h1>
            </div>
          </div>

          <div class="cover-corporate">
            <div class="container">
              <div class="rows">
                <div className="contact-cover mt-20">
                  <div className="form-contact mx-auto max-w-[70%]">
                    <form>
                      <div className="flex flex-col md:flex-row md:gap-4">
                        <input
                          type="text"
                          placeholder="ชื่อ-นามสกุล"
                          className="input-contact"
                          required
                        />
                        <input
                          type="text"
                          placeholder="เบอร์โทรศัพท์"
                          className="input-contact"
                          required
                        />
                      </div>
                      <input
                        type="text"
                        placeholder="อีเมล"
                        className="input-contact"
                        required
                      />
                      <textarea
                        type="text"
                        placeholder="ข้อมูล"
                        required
                        className="text-area-inquiry"
                      />
                      <div className="flex items-start justify-start gap-2">
                        <div className="check-inqurity">
                          <input type="checkbox" />
                        </div>
                        <span>
                          ฉันได้อ่านและยอมรับข้อกำหนดและเงื่อนไขที่ระบุไว้ใน
                          <Link to={"/PrivacyPolicy"} target="_blank">
                            นโยบายความเป็นส่วนตัว
                          </Link>
                          และยินยอมให้รวบรวมประมวลผลและ /
                          หรือเปิดเผยข้อมูลส่วนบุคคลที่ฉันให้ไว้เพื่อบรรลุวัตถุประสงค์ดังกล่าวข้างต้น
                        </span>
                      </div>
                      <div className="contant-btn mx-auto">
                        <button className="btn-blue">ส่งข้อมูล</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="mt-36 lg:mt-44 container mx-auto px-4">
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1 text-xl">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-4xl  text-[#1b3a91] font-bold">
                Inquiry Form
              </h1>
            </div>
          </div>

          <div class="cover-corporate">
            <div class="container">
              <div class="rows">
                <div className="contact-cover mt-20">
                  <div className="form-contact mx-auto max-w-[70%]">
                    <form>
                      <div className="flex flex-col md:flex-row md:gap-4">
                        <input
                          type="text"
                          placeholder="Name"
                          className="input-contact"
                          required
                        />
                        <input
                          type="text"
                          placeholder="Telephone"
                          className="input-contact"
                          required
                        />
                      </div>
                      <input
                        type="text"
                        placeholder="Email"
                        className="input-contact"
                        required
                      />
                      <textarea
                        type="text"
                        placeholder="Message"
                        required
                        className="text-area-inquiry"
                      />
                      <div className="flex items-start justify-start gap-2">
                        <div className="check-inqurity">
                          <input type="checkbox" />
                        </div>
                        <span>
                          I have read and accepted terms and conditions
                          specified in the
                          <Link to={"/PrivacyPolicy"} target="_blank">
                            Privacy Policy
                          </Link>
                          and do hereby consent to the collecting, processing
                          and/or disclosing of the personal data provided by me
                          to fulfil the above-said purposes.
                        </span>
                      </div>
                      <div className="contant-btn mx-auto">
                        <button className="btn-blue">Send</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
