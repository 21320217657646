export const majorShareholderData = [
  {
    majorShareholders: "บริษัท ส.กิจชัย แคปปิตอล จำกัด",
    listedShares: "400,800,000.00",
    percentageShares: "50.10",
  },
  {
    majorShareholders: "นาย สงวน แสงวงศ์กิจ",
    listedShares: "54,600,000.00",
    percentageShares: "6.83",
  },
  {
    majorShareholders: "นาย สมนึก แสงวงศ์กิจ",
    listedShares: "52,700,000.00",
    percentageShares: "6.59",
  },
  {
    majorShareholders: "นาง มาลัย ตั้งศุภอนันต์",
    listedShares: "16,552,000.00",
    percentageShares: "2.07",
  },
  {
    majorShareholders: "นาย สมหวัง ธรณนพเก้า",
    listedShares: "16,202,800.00",
    percentageShares: "2.03",
  },
  {
    majorShareholders: "นาย สุรศักดิ์ แสงวงศ์กิจ",
    listedShares: "16,160,000.00",
    percentageShares: "2.02",
  },
  {
    majorShareholders: "น.ส. แสงจันทร์ แสงวงศ์กิจ",
    listedShares: "15,756,000.00",
    percentageShares: "1.97",
  },
  {
    majorShareholders: "น.ส. สุจิตรา แสงวงศ์กิจ",
    listedShares: "15,000,000.00",
    percentageShares: "1.88",
  },
  {
    majorShareholders: "นาง สุภชา เจริญเสริมสกุล",
    listedShares: "14,708,100.00",
    percentageShares: "1.84",
  },
  {
    majorShareholders: "นาย สุรสิทธิ์ แสงวงศ์กิจ",
    listedShares: "14,450,000.00",
    percentageShares: "1.81",
  },
];

export const majorShareholderDataEN = [
  {
    majorShareholders: "บริษัท ส.กิจชัย แคปปิตอล จำกัด",
    listedShares: "400,800,000.00",
    percentageShares: "50.10",
  },
  {
    majorShareholders: "นาย สงวน แสงวงศ์กิจ",
    listedShares: "54,600,000.00",
    percentageShares: "6.83",
  },
  {
    majorShareholders: "นาย สมนึก แสงวงศ์กิจ",
    listedShares: "52,700,000.00",
    percentageShares: "6.59",
  },
  {
    majorShareholders: "MRS. MALAI TANGSOOPANAN",
    listedShares: "16,552,000.00",
    percentageShares: "2.07",
  },
  {
    majorShareholders: "MR. SOMWANG THORANANOPPAKAO",
    listedShares: "16,202,800.00",
    percentageShares: "2.03",
  },
  {
    majorShareholders: "นาย สุรศักดิ์ แสงวงศ์กิจ",
    listedShares: "16,160,000.00",
    percentageShares: "2.02",
  },
  {
    majorShareholders: "น.ส. แสงจันทร์ แสงวงศ์กิจ",
    listedShares: "15,756,000.00",
    percentageShares: "1.97",
  },
  {
    majorShareholders: "MISS.SUJITTRA SAENGWONGKIJ",
    listedShares: "15,000,000.00",
    percentageShares: "1.88",
  },
  {
    majorShareholders: "MRS. SUPACHA CHAROENSERMSAKUL",
    listedShares: "14,708,100.00",
    percentageShares: "1.84",
  },
  {
    majorShareholders: "นาย สุรสิทธิ์ แสงวงศ์กิจ",
    listedShares: "14,450,000.00",
    percentageShares: "1.81",
  },
];
