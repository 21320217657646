import React, { useEffect, useRef } from "react";
import { useGlobalContext } from "../../context/Context";
import { createChart } from "lightweight-charts";

const CandlestickChart = ({
  dateRange,
  dataSet,
  name,
  colorMain,
  lineTopColor,
  linebottomColor,
  lineColor,
  volumeColor,
  miniColor,
  miniLinebottomColor,
  miniLineColor,
}) => {
  const chartContainerRef = useRef(null);
  const volumeChartContainerRef = useRef(null);
  const miniChartContainerRef = useRef(null);
  const toolTipRef = useRef(null);
  const toolTipWidth = useRef(80);
  const toolTipMargin = useRef(15);
  const toolTipMarginBottom = useRef(15);
  const toolTipWidthBottom = useRef(80);
  const toolTipHeight = useRef(80);
  const chartRef = useRef(null);
  const { language } = useGlobalContext();

  const handleZoom = (months) => {
    const now = new Date();
    const pastDate = new Date();

    switch (months) {
      case "1M":
        pastDate.setMonth(now.getMonth() - 1);
        break;
      case "3M":
        pastDate.setMonth(now.getMonth() - 3);
        break;
      case "YTD":
        pastDate.setFullYear(now.getFullYear(), 0, 1);
        break;
      case "1Y":
        pastDate.setFullYear(now.getFullYear() - 1);
        break;
      case "2Y":
        pastDate.setFullYear(now.getFullYear() - 2);
        break;
      default:
        return;
    }

    chartRef.current.timeScale().setVisibleRange({
      from: Math.floor(pastDate.getTime() / 1000),
      to: Math.floor(now.getTime() / 1000),
    });
  };

  useEffect(() => {
    if (!dateRange || dateRange.length === 0) return;

    const [start, end] = dateRange;
    if (chartRef.current) {
      chartRef.current.timeScale().setVisibleRange({
        from: start.unix(),
        to: end.unix(),
      });
    }
  }, [dateRange]);

  useEffect(() => {
    if (dataSet.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      autoSize: true,
    });
    chartRef.current = chart;
    const candlestickSeries = chart.addCandlestickSeries({
      lastValueVisible: true,
      priceLineVisible: false,
    });
    const mockVolumeSeries = chart.addLineSeries({
      lineWidth: 0, // กำหนด lineWidth เป็น 0 เพื่อไม่ให้แสดงเส้น
      priceLineVisible: false, // ซ่อนเส้นแสดงราคาปัจจุบัน
      lastValueVisible: false, // ซ่อนการแสดงค่าล่าสุดบนแกน y
      crosshairMarkerVisible: false, // ซ่อน crosshair marker
      visible: false, // ซ่อน series ทั้งหมด
    });
    const volumeChart = createChart(volumeChartContainerRef.current, {
      autoSize: true,
    });
    const volumeSeries = volumeChart.addHistogramSeries({
      color: volumeColor,
      priceFormat: {
        type: "volume",
      },
      overlay: true,
      scaleMargins: {
        top: 0.8,
        bottom: 0,
      },
      lastValueVisible: true,
      priceLineVisible: false,
    });
    const mockCandlestickSeries = volumeChart.addCandlestickSeries({
      lineWidth: 0, // กำหนด lineWidth เป็น 0 เพื่อไม่ให้แสดงเส้น
      priceLineVisible: false, // ซ่อนเส้นแสดงราคาปัจจุบัน
      lastValueVisible: false, // ซ่อนการแสดงค่าล่าสุดบนแกน y
      crosshairMarkerVisible: false, // ซ่อน crosshair marker
      visible: false, // ซ่อน series ทั้งหมด
    });
    const miniChart = createChart(miniChartContainerRef.current, {
      autoSize: true,
    });
    // const miniSeries = miniChart.addLineSeries({
    //   color: miniColor,
    //   lineWidth: 1,
    // });
    const miniSeries = miniChart.addAreaSeries({
      color: miniColor,
      lineWidth: 1,
      topColor: "rgba(9, 58, 109, 0.5)",
      bottomColor: miniLinebottomColor,
      lineColor: miniLineColor,
    });

    const candlestickData = dataSet?.map((item) => ({
      time: item.time,
      open: item.open,
      high: item.high,
      low: item.low,
      close: item.close,
    }));
    const volumeData = dataSet?.map((item) => ({
      time: item.time,
      value: item.volume,
    }));
    const miniData = dataSet?.map((item) => ({
      time: item.time,
      value: item.average,
    }));

    const synchronizedData = candlestickData.map((item, index) => ({
      time: item.time,
      open: item.open,
      high: item.high,
      low: item.low,
      close: item.close,
      volume: volumeData[index] ? volumeData[index].value : null,
      average: miniData[index] ? miniData[index].value : null,
    }));

    candlestickSeries.setData(
      synchronizedData?.map((item) => ({
        time: item.time,
        open: item.open,
        high: item.high,
        low: item.low,
        close: item.close,
      }))
    );

    mockVolumeSeries.setData(
      synchronizedData?.map((item) => ({
        time: item.time,
        value: item.volume,
      }))
    );

    volumeSeries.setData(
      synchronizedData?.map((item) => ({
        time: item.time,
        value: item.volume,
      }))
    );

    mockCandlestickSeries.setData(
      synchronizedData?.map((item) => ({
        time: item.time,
        open: item.open,
        high: item.high,
        low: item.low,
        close: item.close,
      }))
    );

    miniSeries.setData(
      synchronizedData?.map((item) => ({
        time: item.time,
        value: item.average,
      }))
    );

    // Fit the chart to show all data initially
    chart.timeScale().fitContent();
    volumeChart.timeScale().fitContent();
    miniChart.timeScale().fitContent();

    // Set the initial visible range to cover all data
    const startDate = candlestickData[0].time;
    const endDate = candlestickData[candlestickData.length - 1].time;

    chart.timeScale().setVisibleRange({ from: startDate, to: endDate });
    volumeChart.timeScale().setVisibleRange({ from: startDate, to: endDate });
    miniChart.timeScale().setVisibleRange({ from: startDate, to: endDate });

    // Restrict zooming and panning to the data range
    chart.applyOptions({
      timeScale: {
        minBarSpacing: 0,
        rightOffset: 2,
        fixLeftEdge: true,
        fixRightEdge: true,
        minVisibleBars: 10,
        maxVisibleBars: candlestickData.length,
        visible: true,
      },
      localization: {
        locale: language === "E" ? "en-EN" : "th-TH",
      },
      rightOffset: 10,
      barSpacing: 20,
      fixLeftEdge: true,
      lockVisibleTimeRangeOnResize: true,
      timeVisible: true,
      secondsVisible: false,
      rightPriceScale: {
        visible: true,
        minimumWidth: 70,
      },
      crosshair: {
        horzLine: {
          visible: true,
          labelVisible: false,
        },
        vertLine: {
          labelVisible: false,
        },
      },
      grid: {
        vertLines: {
          color: "rgba(197, 203, 206, 0.5)",
          style: 1,
          visible: true,
        },
        horzLines: {
          color: "rgba(197, 203, 206, 0.5)",
          style: 1,
          visible: true,
        },
      },
      priceScale: {
        mode: 2,
        invertScale: true,
        autoScale: true,
        scaleMargins: {
          top: 0.1,
          bottom: 0.1,
        },
      },
    });

    volumeChart.applyOptions({
      timeScale: {
        minBarSpacing: 1,
        rightOffset: 2,
        fixLeftEdge: true,
        fixRightEdge: true,
        minVisibleBars: 10,
        maxVisibleBars: candlestickData.length,
        visible: false,
      },
      crosshair: {
        horzLine: {
          visible: true,
          labelVisible: false,
        },
        vertLine: {
          labelVisible: false,
        },
      },
      rightOffset: 10,
      barSpacing: 20,
      fixLeftEdge: true,
      lockVisibleTimeRangeOnResize: true,
      timeVisible: true,
      secondsVisible: false,
      rightPriceScale: {
        visible: true,
        minimumWidth: 70,
        width: 70, // กำหนดความกว้างเป็น 60 พิกเซล
      },
      grid: {
        vertLines: {
          color: "rgba(197, 203, 206, 0.5)",
          style: 1,
          visible: false,
        },
        horzLines: {
          color: "rgba(197, 203, 206, 0.5)",
          style: 1,
          visible: false,
        },
      },
    });

    miniChart.applyOptions({
      rightOffset: 10,
      barSpacing: 20,
      fixLeftEdge: true,
      lockVisibleTimeRangeOnResize: true,
      timeVisible: true,
      secondsVisible: false,
      rightPriceScale: {
        visible: false,
      },
      // localization: {
      //   locale: "th-TH",
      // },

      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          visible: false,
        },
      },
      timeScale: {
        minBarSpacing: 1,
        rightOffset: 2,
        fixLeftEdge: true,
        fixRightEdge: true,
        minVisibleBars: 10,
        maxVisibleBars: candlestickData.length,
        visible: false,
      },
      priceScale: {
        mode: 2,
        autoScale: true,
        scaleMargins: {
          top: 0.1,
          bottom: 0.1,
        },
      },
    });

    const syncCharts = (sourceChart, targetChart) => {
      const onVisibleTimeRangeChange = () => {
        targetChart
          .timeScale()
          .setVisibleRange(sourceChart.timeScale().getVisibleRange());
      };

      const onVisibleLogicalRangeChange = () => {
        targetChart
          .timeScale()
          .setVisibleLogicalRange(
            sourceChart.timeScale().getVisibleLogicalRange()
          );
      };

      sourceChart
        .timeScale()
        .subscribeVisibleTimeRangeChange(onVisibleTimeRangeChange);
      sourceChart
        .timeScale()
        .subscribeVisibleLogicalRangeChange(onVisibleLogicalRangeChange);

      return () => {
        sourceChart
          .timeScale()
          .unsubscribeVisibleTimeRangeChange(onVisibleTimeRangeChange);
        sourceChart
          .timeScale()
          .unsubscribeVisibleLogicalRangeChange(onVisibleLogicalRangeChange);
      };
    };

    const unsync1 = syncCharts(chart, volumeChart);
    const unsync2 = syncCharts(volumeChart, chart);
    const unsync3 = syncCharts(miniChart, chart);
    const unsync4 = syncCharts(chart, miniChart);

    const formatDate = (dateString) => {
      if (!dateString) return "N/A";

      if (language === "E") {
        return new Date(dateString).toLocaleDateString("en-EN", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
      } else {
        return new Date(dateString).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
      }
    };

    const updateToolTip = (param) => {
      const toolTip = toolTipRef.current;

      if (!param.point || !param.time || !param.seriesData.size) {
        toolTip.style.display = "none";
        return;
      }
      toolTip.style.display = "block";

      // ดึงข้อมูลจาก series ที่เกี่ยวข้อง
      const candlestickData = param.seriesData.get(candlestickSeries);
      const mockVolumeData = param.seriesData.get(mockVolumeSeries);
      const volumeData = param.seriesData.get(volumeSeries);
      const mockCandlestickData = param.seriesData.get(mockCandlestickSeries);

      let topOffset;
      if (volumeData) {
        topOffset = 200;
      }

      // ตรวจสอบว่ามีข้อมูลจาก series ใดบ้าง
      const price = candlestickData !== undefined ? candlestickData : 0;
      const mockVolume = mockVolumeData !== undefined ? mockVolumeData : 0;
      const volume = volumeData !== undefined ? volumeData : 0;
      const mockPrice =
        mockCandlestickData !== undefined ? mockCandlestickData : 0;
      const formattedDate = formatDate(param.time);
      if (mockCandlestickData !== undefined) {
        toolTip.innerHTML = `    
        <div class="toolTip-layout">
          <div style="color:${colorMain}" class="toolTip-main-text name">
            ${name} : ${language === "E" ? "Bath" : "บาท"}
            </div>
          <div class="toolTip-main-text">
            <p>${language === "E" ? "Open" : "ราคาเปิด"}: ${mockPrice.open}</p>
          </div>
          <div class="toolTip-main-text">
            <p>${language === "E" ? "Close" : "ราคาปิด"}: ${mockPrice.close}</p>
          </div>
          <div class="toolTip-main-text">
            <p>${language === "E" ? "Low" : "ต่ำสุด"}: ${mockPrice.low}</p>
          </div>
          <div class="toolTip-main-text">
            <p>${language === "E" ? "High" : "สูงสุด"}: ${mockPrice.high}</p>
          </div>
          <div class="toolTip-main-text">${
            language === "E" ? "Volume" : "ปริมาณที่ซื้อขาย"
          }: ${volume.value.toLocaleString()}</div>
          <div class="toolTip-date">
            <div class="toolTip-main-text">${
              language === "E" ? "Date" : "วันที่"
            }: ${formattedDate}</div>
          </div>
        </div>`;
      } else {
        toolTip.innerHTML = `
         <div class="toolTip-layout">
          <div style="color:${colorMain}" class="toolTip-main-text name">
            ${name} : ${language === "E" ? "Bath" : "บาท"}
            </div>
          <div class="toolTip-main-text">
            <p>${language === "E" ? "Open" : "ราคาเปิด"}: ${price.open}</p>
          </div>
          <div class="toolTip-main-text">
            <p>${language === "E" ? "Close" : "ราคาปิด"}: ${price.close}</p>
          </div>
          <div class="toolTip-main-text">
            <p>${language === "E" ? "Low" : "ต่ำสุด"}: ${price.low}</p>
          </div>
          <div class="toolTip-main-text">
            <p>${language === "E" ? "High" : "สูงสุด"}: ${price.high}</p>
          </div>
          <div class="toolTip-main-text">${
            language === "E" ? "Volume" : "ปริมาณที่ซื้อขาย"
          }: ${mockVolume.value.toLocaleString()}</div>
          <div class="toolTip-date">
            <div class="toolTip-main-text">${
              language === "E" ? "Date" : "วันที่"
            }: ${formattedDate}</div>
          </div>
        </div>
     `;
      }

      // คำนวณ left สำหรับกราฟหลัก (เช่น Price chart)
      let left;
      if (price || mockPrice) {
        left = param.point.x + toolTipMargin.current;
        if (
          left >
          chartContainerRef.current.clientWidth - toolTipWidth.current - 50
        ) {
          // ถ้า tooltip เกินขอบขวาของกราฟ ให้ย้ายไปแสดงฝั่งซ้าย
          left =
            param.point.x - toolTipMargin.current - toolTipWidth.current - 170;
        }

        // ตั้งค่า left ของ tooltip
        toolTip.style.left = left + "px";
      }

      // คำนวณ top สำหรับ Volume chart
      let top, bottom;
      bottom = param.point.y + toolTipMarginBottom.current;
      if (volume || mockVolumeData) {
        top = param.point.y + toolTipMargin.current;
        if (
          top >
          chartContainerRef.current.clientHeight - toolTipHeight.current
        ) {
          toolTip.style.top =
            param.point.y -
            toolTipHeight.current -
            toolTipMargin.current +
            "px";
        } else {
          toolTip.style.top = bottom + "px";
          toolTip.style.top = (topOffset ? topOffset : top) + "px";
          toolTip.style.bottom = bottom + "px";
        }
      }
    };

    chart.subscribeCrosshairMove(updateToolTip);
    volumeChart.subscribeCrosshairMove(updateToolTip);

    // Handle dragging to select time range
    let isDragging = false;
    let startRange = null;

    miniChart.subscribeCrosshairMove((param) => {
      if (!isDragging || !param.time) return;
      const endRange = param.time;
      if (new Date(startRange) <= new Date(endRange)) {
        miniChart
          .timeScale()
          .setVisibleRange({ from: startRange, to: endRange });
        chart.timeScale().setVisibleRange({ from: startRange, to: endRange });
        volumeChart
          .timeScale()
          .setVisibleRange({ from: startRange, to: endRange });
      }
    });

    miniChart.subscribeClick((param) => {
      if (isDragging) {
        isDragging = false;
      } else {
        isDragging = true;
        startRange = param.time;
      }
    });

    return () => {
      unsync1();
      unsync2();
      unsync3();
      unsync4();
      chart.remove();
      volumeChart.remove();
      miniChart.remove();
    };
  }, [dataSet, language]);

  // responsive chart
  // useEffect(() => {
  //   const handleResize = () => {
  //     const windowWidth = window.innerWidth;
  //     if (windowWidth < 400) {
  //       chartContainerRef.current.style.width = "246px"; // 310px - 64px
  //       volumeChartContainerRef.current.style.width = "271px"; // 335px - 64px
  //       miniChartContainerRef.current.style.width = "200px"; // 270px - 64px
  //     } else if (windowWidth < 500) {
  //       chartContainerRef.current.style.width = "317px"; // 360px - 64px
  //       volumeChartContainerRef.current.style.width = "340px"; // 385px - 64px
  //       miniChartContainerRef.current.style.width = "280px"; // 310px - 64px
  //     } else if (windowWidth < 550) {
  //       chartContainerRef.current.style.width = "396px"; // 460px - 64px
  //       volumeChartContainerRef.current.style.width = "421px"; // 485px - 64px
  //       miniChartContainerRef.current.style.width = "356px"; // 420px - 64px
  //     } else if (windowWidth < 630) {
  //       chartContainerRef.current.style.width = "376px"; // 540px - 64px
  //       volumeChartContainerRef.current.style.width = "401px"; // 565px - 64px
  //       miniChartContainerRef.current.style.width = "336px"; // 500px - 64px
  //     } else if (windowWidth < 670) {
  //       chartContainerRef.current.style.width = "436px"; // 600px - 64px
  //       volumeChartContainerRef.current.style.width = "461px"; // 625px - 64px
  //       miniChartContainerRef.current.style.width = "396px"; // 560px - 64px
  //     } else if (windowWidth < 720) {
  //       chartContainerRef.current.style.width = "486px"; // 650px - 64px
  //       volumeChartContainerRef.current.style.width = "511px"; // 675px - 64px
  //       miniChartContainerRef.current.style.width = "436px"; // 600px - 64px
  //     } else if (windowWidth < 990) {
  //       chartContainerRef.current.style.width = "536px"; // 700px - 64px
  //       volumeChartContainerRef.current.style.width = "561px"; // 725px - 64px
  //       miniChartContainerRef.current.style.width = "496px"; // 660px - 64px
  //     } else if (windowWidth < 1200) {
  //       chartContainerRef.current.style.width = "846px"; // 910px - 64px
  //       volumeChartContainerRef.current.style.width = "871px"; // 935px - 64px
  //       miniChartContainerRef.current.style.width = "806px"; // 870px - 64px
  //     } else {
  //       chartContainerRef.current.style.width = "1120px"; // 910px - 64px
  //       volumeChartContainerRef.current.style.width = "1145px"; // 935px - 64px
  //       miniChartContainerRef.current.style.width = "1080px"; // 870px - 64px
  //     }
  //     //  else {
  //     //   chartContainerRef.current.style.width = "956px"; // 1120px - 64px
  //     //   volumeChartContainerRef.current.style.width = "981px"; // 1145px - 64px
  //     //   miniChartContainerRef.current.style.width = "916px"; // 1080px - 64px
  //     // }
  //     console.log("resize", window.innerWidth);
  //   };
  //   // Set initial size on mount
  //   handleResize();

  //   // Add event listener for window resize
  //   window.addEventListener("resize", handleResize);

  //   // Clean up the event listener on unmount
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <div style={{ position: "relative", width: "100%", height: "600px" }}>
      <div className="chart-select-layout">
        <span className="focus-text">Focus On:</span>
        <div className="chart-select-year">
          <button onClick={() => handleZoom("1M")}>
            <span>1M</span>
          </button>
          <button onClick={() => handleZoom("3M")}>
            <span>3M</span>
          </button>
          <button onClick={() => handleZoom("YTD")}>
            <span>YTD</span>
          </button>
          <button onClick={() => handleZoom("1Y")}>
            <span>1Y</span>
          </button>
          <button onClick={() => handleZoom("2Y")}>
            <span>2Y</span>
          </button>
        </div>
      </div>
      <div ref={chartContainerRef} style={{ width: "100%", height: "280px" }} />
      <div
        ref={volumeChartContainerRef}
        style={{ width: "100%", height: "150px", marginTop: "20px" }}
      />
      <div className="chart-container">
        <div
          ref={miniChartContainerRef}
          style={{ width: "100%", height: "100px", marginTop: "10px" }}
        />
      </div>
      <div
        className="toolTip-main"
        ref={toolTipRef}
        style={{
          position: "absolute",
          height: "240px",
          width: "240px",
          display: "none",
          pointerEvents: "none",
          zIndex: 1000,
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
          fontSmoothing: "antialiased",
          textAlign: "center",
          background: "#fff",
          borderRadius: "2px",
          paddingTop: "10px",
          paddingBottom: "10px",
          paddingLeft: "30px",
          paddingRight: "30px",
          boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
          borderBottomRightRadius: "25px",
          borderTopLeftRadius: "10px",
        }}
      />
    </div>
  );
};

export default CandlestickChart;
