import React, { useState } from 'react'
import {
  Awards,
  Banner,
  Form,
  History,
  Product,
  RawMaterial,
} from './Components'
import PopupHome from './Components/PopupHome'

export default function HomePage() {
  const [isShowPopup, setIsShowPopup] = useState(true)

  const link = 'https://www.skn.co.th/GeneralMeeting'

  return (
    <>
      <PopupHome
        isOpen={isShowPopup}
        onClose={() => setIsShowPopup(false)}
        link={link}
      />
      <div className='container-d'>
        <Banner
          bg={'bg-image-banner'}
          text2={'S.KIJCHAI'}
          text3={'  ENTERPRISE'}
          subText={'Public Company Limited'}
          detail1TH={
            'ด้วยประสบการณ์และความเชี่ยวชาญในอุตสาหกรรมแปรรูปไม้มายาวนานกว่า 30 ปี จึงเข้าใจเกี่ยวกับผลิตภัณฑ์แปรรูปแผ่นไม้เป็นอย่างดี ซึ่งบริษัทดำเนินงานภายใต้นโยบายที่มุ่งสร้างผลิตภัณฑ์ที่มีคุณภาพ'
          }
          detail2TH={
            'จากมาตรฐานการผลิตระดับสากลและมุ่งเน้นการพัฒนากระบวนการผลิตให้มีระยะเวลาที่สั้น ควบคุมปริมาณการใช้วัตถุดิบให้มีประสิทธิภาพสูงสุด'
          }
          detail1EN={
            'With over 30 years of experiences and expertise in wood processed industry, the organization possesses an in-depth understanding of wood processed product.'
          }
          detail2EN={
            'The company also determine to develop manufacture process to be shorter and control the amount used of raw materials to be utmost efficient.'
          }
        />
        <History />
        <Product />
        <RawMaterial />
        <Form />
        <Awards />
      </div>
    </>
  )
}
