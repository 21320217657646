import React from "react";
import { Header } from "../../Components";
import { useGlobalContext } from "../../context/Context";

export default function NewsewsClipping() {
  const { language } = useGlobalContext();

  return (
    <>
      <div id="newActivities" className="sub-top-hight">
        <div
          className="container-I"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className="row">
            <div className="box-line-c">
              <Header nameTH={"ข่าวจากหนังสือพิมพ์"} nameEN={"News Clipping"} />
            </div>
          </div>
        </div>
        {language === "T" ? (
          <>
            <div className="cover-board" style={{ margin: "5rem 0" }}>
              <div className="dateSearch">
                <div className="d-1">
                  <p>ตั้งแต่วันที่</p>
                  <input type="date" className="input-date"></input>
                </div>
                <div className="d-1">
                  <p>ถึงวันที่</p>
                  <input type="date" className="input-date"></input>
                </div>
                <div className="d-1">
                  <button type="button" className="btn-1">
                    ค้นหา
                  </button>
                </div>
              </div>
            </div>
            <div className="container mt-4" style={{ margin: "auto" }}>
              <div className="relative overflow-x-auto margin-res">
                <table className="w-full table-board table-respon">
                  <thead className="thead-board">
                    <tr>
                      <th
                        className="th-board"
                        style={{ width: "20%" }}
                        scope="col"
                      >
                        วันที่
                      </th>
                      <th
                        className="th-board"
                        style={{ width: "30%" }}
                        scope="col"
                      >
                        หัวข้อข่าว
                      </th>
                      <th
                        className="th-board"
                        style={{ width: "40%" }}
                        scope="col"
                      >
                        แหล่งข่าว
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="no-information" style={{ marginTop: 30 }}>
                <p>ไม่มีข้อมูล ณ ขณะนี้</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="cover-board" style={{ margin: "5rem 0" }}>
              <div className="dateSearch">
                <div className="d-1">
                  <p>Start Date</p>
                  <input type="date" className="input-date"></input>
                </div>
                <div className="d-1">
                  <p>End Date</p>
                  <input type="date" className="input-date"></input>
                </div>
                <div className="d-1">
                  <button type="button" className="btn-1">
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="container mt-4" style={{ margin: "auto" }}>
              <div className="relative overflow-x-auto margin-res">
                <table className="w-full table-board table-respon">
                  <thead className="thead-board">
                    <tr>
                      <th
                        className="th-board"
                        style={{ width: "20%" }}
                        scope="col"
                      >
                        Date
                      </th>
                      <th
                        className="th-board"
                        style={{ width: "30%" }}
                        scope="col"
                      >
                        Title
                      </th>
                      <th
                        className="th-board"
                        style={{ width: "40%" }}
                        scope="col"
                      >
                        Source
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="no-information" style={{ marginTop: 30 }}>
                <p>No Information Now</p>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
