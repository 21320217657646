import React from "react";
import { awardCarb, fsc, logoIso1, logoIso2 } from "../../../assets/Logo";
import { useGlobalContext } from "../../../context/Context";

export default function Awards() {
  const { language } = useGlobalContext();

  return (
    <div className="container-awards">
      <div className="content-awards">
        {language === "T" ? (
          <div className="content-awards-layout">
            <div className="main-head-reward">
              <p className="head-form t-black">รางวัลและการรองรับ</p>
            </div>
            <div className="content-awards-layout">
              <div className="main-head-reward box-card-award">
                <div className="big-cover-award">
                  <div className="content-awards-layout">
                    <div className="box-award">
                      <div className="cover-award">
                        <div className="text-award">
                          <p className="t-1 black">ISO 9001</p>
                          <p className="t-2 black">
                            มาตรฐานระบบการจัดการด้านคุณภาพ
                          </p>
                        </div>
                        <div className="pic-award">
                          <img src={logoIso1} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="content-awards-layout"></div>
                </div>
              </div>
              <div className="main-head-reward box-card-award">
                <div className="big-cover-award">
                  <div className="content-awards-layout">
                    <div className="box-award">
                      <div className="cover-award">
                        <div className="text-award">
                          <p className="t-1 black">ISO 14001</p>
                          <p className="t-2 black">
                            มาตรฐานระบบการจัดการด้านสิ่งแวดล้อม
                          </p>
                        </div>
                        <div className="pic-award">
                          <img src={logoIso2} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="content-awards-layout"></div>
                </div>
              </div>
            </div>
            <div className="content-awards-layout">
              <div className="main-head-reward box-card-award">
                <div className="big-cover-award">
                  <div className="content-awards-layout">
                    <div className="box-award">
                      <div className="cover-award">
                        <div className="text-award">
                          <p className="t-1 black">CARB & EPA Certificate</p>
                          <p className="t-2 black">
                            มาตรฐาน CARB. <br />
                            {`(California Air Resources Board)`}
                          </p>
                        </div>
                        <div className="pic-award">
                          <img src={awardCarb} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="content-awards-layout"></div>
                </div>
              </div>
              <div className="main-head-reward box-card-award">
                <div className="big-cover-award">
                  <div className="content-awards-layout">
                    <div className="box-award">
                      <div className="cover-award">
                        <div className="text-award">
                          <p className="t-1 black">FSC Certificated</p>
                          <p className="t-2 black">
                            Forest Stewardship Council
                          </p>
                        </div>
                        <div className="pic-award">
                          <img src={fsc} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="content-awards-layout"></div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="content-awards-layout">
            <div className="main-head-reward">
              <p className="head-form t-black">Awards and Certifications</p>
            </div>
            <div className="content-awards-layout">
              <div className="main-head-reward box-card-award">
                <div className="big-cover-award">
                  <div className="content-awards-layout">
                    <div className="box-award">
                      <div className="cover-award">
                        <div className="text-award">
                          <p className="t-1 black">ISO 9001</p>
                          <p className="t-2 black">Quality Management System</p>
                        </div>
                        <div className="pic-award">
                          <img src={logoIso1} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="content-awards-layout"></div>
                </div>
              </div>
              <div className="main-head-reward box-card-award">
                <div className="big-cover-award">
                  <div className="content-awards-layout">
                    <div className="box-award">
                      <div className="cover-award">
                        <div className="text-award">
                          <p className="t-1 black">ISO 14001</p>
                          <p className="t-2 black">
                            Environmental Management System
                          </p>
                        </div>
                        <div className="pic-award">
                          <img src={logoIso2} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="content-awards-layout"></div>
                </div>
              </div>
            </div>
            <div className="content-awards-layout">
              <div className="main-head-reward box-card-award">
                <div className="big-cover-award">
                  <div className="content-awards-layout">
                    <div className="box-award">
                      <div className="cover-award">
                        <div className="text-award">
                          <p className="t-1 black">CARB & EPA Certificate</p>
                          <p className="t-2 black">
                            CARB Standard. <br />
                            {`(California Air Resources Board)`}
                          </p>
                        </div>
                        <div className="pic-award">
                          <img src={awardCarb} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="content-awards-layout"></div>
                </div>
              </div>
              <div className="main-head-reward box-card-award">
                <div className="big-cover-award">
                  <div className="content-awards-layout">
                    <div className="box-award">
                      <div className="cover-award">
                        <div className="text-award">
                          <p className="t-1 black">FSC Certificated</p>
                          <p className="t-2 black">
                            Forest Stewardship Council
                          </p>
                        </div>
                        <div className="pic-award">
                          <img src={fsc} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="content-awards-layout"></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
