import React from "react";
import {
  majorShareholderData,
  majorShareholderDataEN,
} from "../../../mock/MockupMajorShareholder";
import { Header } from "../../../Components";
import { useGlobalContext } from "../../../context/Context";

export default function MajorShareholder() {
  const { language } = useGlobalContext();

  return (
    <>
      <div className="sub-top-hight">
        <div className="container-I" style={{ marginBottom: 100 }}>
          <div className="row">
            <div className="box-line-c">
              <Header
                nameTH={"โครงสร้างผู้ถือหุ้น"}
                nameEN={"Major Shareholder"}
              />
            </div>
          </div>

          <div
            class="container mt-4"
            style={{ margin: "auto", marginTop: "3.75rem" }}
          >
            <p class="text-skn-standard corporate-t1">
              {language === "T"
                ? "โครงสร้างผู้ถือหุ้น ณ วันที่ 10 มิถุนายน 2567"
                : "Major Shareholder as of 10 June 2024"}
            </p>
            <div class="relative overflow-x-auto">
              <table class="w-full table-respon mt-4 text-sm text-center text-white">
                {language === "T" ? (
                  <thead class="text-base text-white bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                    <tr>
                      <th scope="col" class="table-1 py-5 text-center">
                        รายชื่อผู้ถือหุ้น
                      </th>
                      <th scope="col" class="table-2 py-5">
                        จำนวนหุ้น
                      </th>
                      <th scope="col" class="table-3 py-5">
                        สัดส่วนร้อยละของหุ้นที่ถือ
                      </th>
                    </tr>
                  </thead>
                ) : (
                  <thead class="text-base text-white bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                    <tr>
                      <th scope="col" class="table-1 py-5 text-center">
                        Major Shareholders
                      </th>
                      <th scope="col" class="table-2 py-5">
                        # Shares
                      </th>
                      <th scope="col" class="table-3 py-5">
                        % Shares
                      </th>
                    </tr>
                  </thead>
                )}

                <tbody>
                  {language === "T"
                    ? majorShareholderData.map((item, i) => {
                        return (
                          <tr
                            key={i}
                            class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                          >
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium"
                              style={{ textAlign: "left" }}
                            >
                              {item.majorShareholders}
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              {item.listedShares}
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              {item.percentageShares}
                            </td>
                          </tr>
                        );
                      })
                    : majorShareholderDataEN.map((item, i) => {
                        return (
                          <tr
                            key={i}
                            class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                          >
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium"
                              style={{ textAlign: "left" }}
                            >
                              {item.majorShareholders}
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              {item.listedShares}
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              {item.percentageShares}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
