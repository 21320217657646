import React from "react";
import { logo } from "../../../../assets/Logo";
import { useParams } from "react-router-dom";
// import { relatePdf } from "../IRCalendar";
import packageJson from "../../../../../package.json";
export default function ReadDetail() {
  const { relateInfo } = useParams();
  return (
    <>
      <div className="container-Detail">
        <div className="h-bg">
          <div className="f-detail">
            <img src={logo} className="logo-Dbar"></img>
          </div>
        </div>
        <div className="row">
          <div className="box-line">
            <div className="about-text-cover text-center">
              <p className="t-1">Welcome To S.Kijchai</p>
              <p className="t-2">ปฏิทินกิจกรรมนักลงทุนสัมพันธ์</p>
            </div>
          </div>
        </div>
      </div>
      <div className="cover-board-D">
        <iframe
          src={`${packageJson.homepage ?? ''}/pdf/${relateInfo}.pdf`}
          frameborder="0"
          width="100%"
          height="1100"
        ></iframe>
      </div>
    </>
  );
}
