import { pathMappings } from "../mock/MockupPathTitle";

export const convertURL = (type, url) => {
  // E = encode, D = decode
  if (type === "E") {
    return encodeURIComponent(url);
  } else if (type === "D") {
    return decodeURIComponent(url);
  }
};
export const convertSelectYear = (select, setSelect, language) => {
  if (select !== "All" || select !== "ทั้งหมด") {
    if (language === "T") {
      if (select < 2500) {
        setSelect(select + 543);
      }
    } else {
      if (select > 2500) {
        setSelect(select - 543);
      }
    }
  }
  if (select === "All" || select === "ทั้งหมด") {
    if (language === "E") {
      setSelect("All");
    } else {
      setSelect("ทั้งหมด");
    }
  }
};
export const convertYear = (select, language) => {
  if (select === "Year") {
    return;
  }
  if (select === "All" || select === "ทั้งหมด") {
    return 0;
  } else {
    if (language === "T") {
      if (select > 2500) {
        return select - 543;
      } else {
        return select;
      }
    } else {
      if (select > 2500) {
        return select - 543;
      } else {
        return select;
      }
    }
  }
};
export const convertArrayYear = (years, language, includeAll = true) => {
  if (years) {
    let dataYear = years?.map((item) => {
      if (language === "T") {
        if (item < 2500) {
          if (item === 0) {
            if (item < 2500) {
              return item + (dateNowYear() + 543);
            } else {
              return item + dateNowYear();
            }
          }
          return item + 543;
        } else {
          return item;
        }
      } else {
        if (item > 2500) {
          return item - 543;
        } else {
          if (item === 0) {
            if (item > 2500) {
              return item + (dateNowYear() - 543);
            } else {
              return item + dateNowYear();
            }
          }
          return item;
        }
      }
    });
    if (includeAll) {
      const label = language === "T" ? "ทั้งหมด" : "All";
      dataYear?.unshift(label);
    }

    return dataYear;
  }
};
export const dateNowYear = () => {
  const now = new Date();
  const year = now.getFullYear();
  return year;
};
export const handleScrollToTop = (toTop) => {
  // 0 = บนสุด , 500 = พอดีกับ header
  window.scrollTo({
    top: toTop,
    behavior: "smooth",
    // behavior: toTop === 0 ? "smooth" : "auto",
  });
};

export const formatDateFull = (dateString, language) => {
  const year = dateString?.substring(0, 4);
  const month = parseInt(dateString?.substring(4, 6), 10);
  const day = dateString?.substring(6, 8);
  const thaiMonths = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];
  const engMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const engYear = parseInt(year, 10);
  const thaiYear = parseInt(year, 10) + 543;
  const thaiMonth = thaiMonths[month - 1];
  const engMonth = engMonths[month - 1];
  const monthChaeck = language === "T" ? thaiMonth : engMonth;
  const yearCheck = language === "T" ? thaiYear : engYear;
  // สร้างรูปแบบข้อความใหม่
  const formattedDate = `${day} ${monthChaeck} ${yearCheck}`;
  return formattedDate;
};

export const converDate = (dateString, language) => {
  const year = dateString.substring(0, 4);
  const coverYearEng = dateString.substring(0, 4);
  const coverYearThai = parseInt(year) + 543;
  const coverYear = language === "T" ? coverYearThai : coverYearEng;
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);
  return `${day}/${month}/${coverYear}`;
};

export const converTime = (timeString, language) => {
  const hour = timeString.substring(0, 2);
  const minute = timeString.substring(2, 4);
  let time = `${hour}:${minute}`;
  if (language === "E") {
    let hours = parseInt(hour, 10);
    if (hours > 12) {
      hours = hours - 12;
      time = `${hours}:${minute} PM`;
    } else {
      time = `${hour}:${minute} AM`;
    }
  }
  return time;
};


export const getTitleFromPath = (path) => {
  for (const key in pathMappings) {
    if (path.endsWith(key)){
      return "SKN - "+ pathMappings[key]
    }
  }
  return "SKN";
}