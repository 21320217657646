import React from "react";
import { useGlobalContext } from "../../../context/Context";
import { Link } from "react-router-dom";

export default function History() {
  const { language } = useGlobalContext();

  return (
    <div className="container-banner history-box bg-image-history">
      <div className="main-content-history">
        {language === "T" ? (
          <div className="content-history">
            <div className="history-homepage hide">
              <div className="about-text-cover">
                <p className="t-1-history">Welcome To S.Kijchai</p>
                <p className="t-2-history black">ประวัติเกี่ยวกับ ส.กิจชัย</p>
                <p>
                  หนึ่งในผู้นำทางด้านการผลิตแผ่นไม้ทดแทนธรรมชาติ
                  ในภูมิภาคเอเชียตะวันออกเฉียงใต้ภายใต้การบริหาร
                  ที่ยึดหลักธรรมาภิบาลที่ดี
                  และมาตรฐานการผลิตที่มีคุณภาพสู่ความยั่งยืนของธรรมชาติสิ่งแวดล้อม
                  และสังคม
                </p>
              </div>
            </div>
            <div className="history-homepage">
              <Link to={"History"}>
                <div className="about-1-cover">
                  <p>
                    ประวัติ และ
                    <br />
                    ความเป็นมา ส.กิจชัย
                  </p>
                </div>
              </Link>
            </div>
            <div className="history-homepage">
              <div className="content-history ">
                <div className="about-text-cover hide-2">
                  <p className="t-1-history">Welcome To S.Kijchai</p>
                  <p className="t-2-history">ประวัติเกี่ยวกับ ส.กิจชัย</p>
                  <p>
                    หนึ่งในผู้นำทางด้านการผลิตแผ่นไม้ทดแทนธรรมชาติ
                    ในภูมิภาคเอเชียตะวันออกเฉียงใต้ภายใต้การบริหาร
                    ที่ยึดหลักธรรมาภิบาลที่ดี
                    และมาตรฐานการผลิตที่มีคุณภาพสู่ความยั่งยืนของธรรมชาติสิ่งแวดล้อม
                    และสังคม
                  </p>
                </div>
                <div className="history-homepage">
                  <Link to={"Vision"}>
                    <div className="about-2-cover about-cover-t">
                      <p>
                        วิสัยทัศน์
                        <br />
                        องค์กร
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="history-homepage">
                  <Link to={"Organization"}>
                    <div className="about-3-cover about-cover-t">
                      <p>
                        โครงสร้าง
                        <br />
                        การจัดการ
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="content-history">
            <div className="history-homepage hide">
              <div className="about-text-cover">
                <p className="t-1-history">Welcome To S.Kijchai</p>
                <p className="t-2-history black">History about S.Kijchai</p>
                <p>
                  One of leaders in manufacturer of wood-based panel in
                  Southeast Asia. The company aimed to create the highest value
                  to customer and related parties under good governance
                  management principle. Additionally, the aim is for to obtain
                  production standard that is high in quality for the
                  sustainability of nature, environment, and society.
                </p>
              </div>
            </div>
            <div className="history-homepage">
              <Link to={"History"}>
                <div className="about-1-cover">
                  <p>
                    History about
                    <br />
                    S.Kijchai
                  </p>
                </div>
              </Link>
            </div>
            <div className="history-homepage">
              <div className="content-history ">
                <div className="about-text-cover hide-2">
                  <p className="t-1-history">Welcome To S.Kijchai</p>
                  <p className="t-2-history">History about S.Kijchai</p>
                  <p>
                    One of leaders in manufacturer of wood-based panel in
                    Southeast Asia. The company aimed to create the highest
                    value to customer and related parties under good governance
                    management principle. Additionally, the aim is for to obtain
                    production standard that is high in quality for the
                    sustainability of nature, environment, and society.
                  </p>
                </div>
                <div className="history-homepage">
                  <Link to={"Vision"}>
                    <div className="about-2-cover about-cover-t">
                      <p>
                        Vision
                        <br />
                        and Mission
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="history-homepage">
                  <Link to={"Organization"}>
                    <div className="about-3-cover about-cover-t">
                      <p>
                        Organization
                        <br />
                        Chart
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
