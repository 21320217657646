import React from "react";
import { rawFeatures, rawFeaturesEN } from "../../../assets/Image";
import { useGlobalContext } from "../../../context/Context";

export default function RawMaterial() {
  const { language } = useGlobalContext();

  return (
    <div className="container-banner container-raw">
      <div className="cover-raw">
        <div className="content-raw content-respon">
          <div className="main-content-raw-layout">
            <div className="content-raw">
              {language === "T" ? (
                <div className="cover-head">
                  <p className="head-raw black">คุณสมบัติ MDF Board</p>
                  <p className="sub-raw">
                    ผลิตภัณฑ์ประเภทแผ่นใยไม้อัด
                    ที่ได้จากการนำเส้นใยของไม้ยางพารา มาผสมกับสารยึดเกาะ
                    {/* <br /> */}
                    แล้วจึงอัดเป็นแผ่นให้เป็นเนื้อเดียวกัน
                    แผ่นใยไม้อัดมีความหนาแน่นสูง
                    ผิวเรียบละเอียดสม่ำเสมอทั่วตลอดทั้งแผ่น
                    {/* <br /> */}
                    สามารถตัดต่อเนื้อไม้ได้อย่างเรียบเนียบ
                    และสามารถนำมาตกแต่งพ่นสีในเนื้อไม้ได้อย่างสวยงาม
                  </p>
                </div>
              ) : (
                <div className="cover-head">
                  <p className="head-raw black">Raw Material</p>
                  <p className="sub-raw">
                    MDF is produced from rubber wood fibers mixed with a binder
                    and then compressed into homogeneous sheets.
                  </p>
                </div>
              )}
            </div>
            <div className="content-raw">
              <div className="img-raw">
                <img src={language === "T" ? rawFeatures : rawFeaturesEN} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
