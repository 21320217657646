import React from "react";
import { logo } from "../../assets/Logo";
import { irContact } from "../../assets/Image";
import "../../CSS/ContactIR.css";
import { useGlobalContext } from "../../context/Context";
import { Link } from "react-router-dom";

export default function ContactInvestor() {
  const { language, changeLanguage } = useGlobalContext();
  return (
    <>
      <section className="mt-36 mb-8 lg:mt-44 container mx-auto px-4">
        {language === "T" ? (
          <>
            <div className="box pl-4 md:pl-8 pr-8 py-4">
              <div className="flex flex-col gap-4 text-center">
                <h2 className="p1">Welcome To S.Kijchai</h2>
                <h1 className="p2 text-3xl  text-[#1b3a91] font-bold">
                  ติดต่อนักลงทุนสัมพันธ์
                </h1>
              </div>
            </div>
            <div className="cover-corporate" style={{ margin: "2rem 0 5rem" }}>
              <div className="container">
                <div className="row">
                  <div className="auto-cols-max">
                    <img src={irContact} alt="" class="contactIR-dot" />
                    <img
                      src={logo}
                      alt=""
                      className="contactIR-logo"
                      width={240}
                      height={97}
                    />
                    <div class=" text-center mt-10">
                      <div className="mb-10">
                        <p className="text-2xl font-bold leading-relaxed text-blue-900 mb-2.5">
                          นาย หาญศิริ แสงวงศ์กิจ
                        </p>
                        <p className="text-lg leading-relaxed font-normal">
                          ผู้บริหารงานนักลงทุนสัมพันธ์
                        </p>
                      </div>
                      <div className="mb-10">
                        <p className="text-2xl font-bold leading-relaxed text-blue-900 mb-2.5">
                          บริษัท ส.กิจชัย เอ็นเตอร์ไพรส์ จำกัด (มหาชน)
                        </p>
                        <p class="text-lg leading-relaxed font-normal">
                          99/9 หมู่ที่ 7 ตำบลห้วยยาง อำเภอแกลง จังหวัดระยอง
                          21110
                        </p>
                      </div>
                      <p class="text-lg leading-relaxed font-normal">
                        โทรศัพท์ : 038 928 188
                      </p>
                      <p class="text-lg leading-relaxed font-normal">
                        โทรสาร : 038 928 189
                      </p>
                      <p class="text-lg leading-relaxed font-normal">
                        Website : <Link to={"/"} >www.skn.co.th</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="box pl-4 md:pl-8 pr-8 py-4">
              <div className="flex flex-col gap-4 text-center">
                <h2 className="p1">Welcome To S.Kijchai</h2>
                <h1 className="p2 text-3xl  text-[#1b3a91] font-bold">
                  IR Contact
                </h1>
              </div>
            </div>
            <div className="cover-corporate" style={{ margin: "2rem 0 5rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <img src={irContact} alt="" class="contactIR-dot" />
                    <img
                      src={logo}
                      alt=""
                      className="contactIR-logo-EN"
                      width={240}
                      height={97}
                    />
                    <div class=" text-center mt-10">
                      <div className="mb-10">
                        <p className="text-2xl font-bold leading-relaxed text-blue-900 mb-2.5">
                          Mr. Harnsiri Sangwongkit
                        </p>
                        <p className="text-lg leading-relaxed font-normal">
                          Investor Relations Executive
                        </p>
                      </div>
                      <div className="mb-10">
                        <p className="text-2xl font-bold leading-relaxed text-blue-900 mb-2.5">
                          S. Kijchai Enterprise Public Company Limited
                        </p>
                        <p class="text-lg leading-relaxed font-normal">
                          99/9 Moo 7 Huaiyang, Klaeng Rayong 21110.
                        </p>
                      </div>
                      <p class="text-lg leading-relaxed font-normal">
                        Tel : 038 928 188
                      </p>
                      <p class="text-lg leading-relaxed font-normal">
                        Fax : 038 928 189
                      </p>
                      <p class="text-lg leading-relaxed font-normal">
                        Website : <Link to={"/"} >www.skn.co.th</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
}
