import React from "react";
import "../../CSS/History/History.css";
import "../../indexTailwind.css";
import { useGlobalContext } from "../../context/Context";

export default function History() {
  const { language } = useGlobalContext();
  return (
    <>
      {language === "T" ? (
        <section className="mt-36 lg:mt-44 container mx-auto px-4">
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4">
              <h2 className="p1 text-xl">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-[#1b3a91] text-3xl font-semibold">
                ประวัติความเป็นมาเกี่ยวกับ ส.กิจชัย
              </h1>
              <p className="secondaryText pr-8">
                หนึ่งในผู้นำทางด้านการผลิตแผ่นไม้ทดแทนธรรมชาติ
                ในภูมิภาคเอเชียตะวันออกเฉียงใต้ภายใต้การบริหาร
                ที่ยึดหลักธรรมาภิบาลที่ดี
                และมาตรฐานการผลิตที่มีคุณภาพสู่ความยั่งยืนของธรรมชาติสิ่งแวดล้อม
                และสังคม
              </p>
            </div>
          </div>

          <div className="mt-8">
            <div className="page-header text-center">
              <p>
                การเปลี่ยนแปลงและพัฒนาการที่สำคัญของบริษัท สามารถสรุปได้ ดังนี้
              </p>
            </div>
            <div>
              <ul className="timeline">
                <li>
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">เริ่มต้น ปี 2553</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - บริษัทจดทะเบียนตั้งขึ้นด้วยทุนจดทะเบียน 600.00
                            ล้านบาท
                            เพื่อดำเนินธุรกิจผลิตและจำหน่ายแผ่นไม้เอ็มดีเอฟ
                            และเริ่มดำเนินการก่อสร้างโรงงานและติดตั้งเครื่องจักรเพื่อผลิตแผ่นไม้เอ็มดีเอฟที่อำเภอแกลง
                            จังหวัดระยอง{" "}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li class="timeline-inverted">
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">ปี 2554</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - ได้รับบัตรส่งเสริมการลงทุน
                            จากคณะกรรมการส่งเสริมการลงทุน (BOI)
                            โดยได้รับสิทธิประโยชน์ทางภาษี และสิทธิประโยชน์อื่น
                            ในธุรกิจที่เกี่ยวข้องกับการผลิตแผ่นไม้เอ็มดีเอฟ
                            ตามที่กำหนดในบัตรส่งเสริมการลงทุน
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">ปี 2555</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - ติดตั้งเครื่องจักรเสร็จสิ้น
                            บริษัทเริ่มดำเนินการทดสอบระบบ (Test Run)
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li class="timeline-inverted">
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">ปี 2556</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - ทดสอบระบบ (Test Run) เสร็จสิ้น
                            บริษัทเริ่มดำเนินการผลิตและจำหน่ายแผ่นไม้เอ็มดีเอฟเชิงพาณิชย์
                            โดยเริ่มจำหน่ายให้กับลูกค้าภายในประเทศ
                            และส่งออกไปยังประเทศต่างๆ{" "}
                          </p>
                          <p class="t-2">
                            -
                            ได้รับการรับรองมาตรฐานการผลิตของผลิตภัณฑ์แผ่นไม้เอ็มดีเอฟ
                            และแผ่นไม้เอ็มดีเอฟแบบบาง (Thin MDF) จาก MUTU
                            Certification International ว่าเป็นไปตามมาตรฐาน
                            California Code of Regulation 93120
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">ปี 2557</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - ที่ประชุมวิสามัญผู้ถือหุ้นครั้งที่ 12/2557
                            ของบริษัท ได้มีมติอนุมัติการเพิ่มทุนจดทะเบียนจาก
                            600.00 ล้านบาท เป็น 720.00 ล้านบาท
                            โดยออกหุ้นสามัญเพิ่มทุนจำนวน 1.20 ล้านหุ้น
                            มูลค่าหุ้นที่ตราไว้ หุ้นละ 100.00 บาท
                            เพื่อเสนอขายต่อผู้ถือหุ้นตามสัดส่วนการถือหุ้น{" "}
                          </p>
                          <p class="t-2">
                            - ได้รับการรับรองมาตรฐานคุณภาพ ISO 9001:2008
                            ซึ่งเป็นมาตรฐานรับรองระบบบริหารงานคุณภาพ (Quality
                            Management System)
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li class="timeline-inverted">
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">ปี 2558</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - ที่ประชุมวิสามัญผู้ถือหุ้นครั้งที่ 4/2558
                            ของบริษัท ได้มีมติอนุมัติการลดทุนจดทะเบียนชำระแล้ว
                            จาก 720.00 ล้านบาท เป็น 600.00 ล้านบาท
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">ปี 2559</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - ได้รับการรับรองมาตรฐานคุณภาพ ISO 14001:2004
                            ซึ่งเป็นมาตรฐานรับรองระบบการจัดการสิ่งแวดล้อม
                            (Environmental Management System)
                          </p>
                          <p class="t-2">
                            - ที่ประชุมวิสามัญผู้ถือหุ้นครั้งที่ 1/2559
                            ของบริษัท
                            ได้มีมติอนุมัติแปรสภาพบริษัทเป็นบริษัทมหาชนจำกัด
                            และเปลี่ยนชื่อบริษัทเป็น บริษัท ส.กิจชัย
                            เอ็นเตอร์ไพรส์ จำกัด (มหาชน) และได้จดทะเบียน
                            แปรสภาพจากบริษัทจำกัดเป็นบริษัทมหาชนจำกัดเมื่อวันที่
                            14 กันยายน 2559
                          </p>
                          <p class="t-2">
                            - นอกจากนี้ ในการประชุมวิสามัญผู้ถือหุ้นคราวเดียวกัน
                            ผู้ถือหุ้นได้มีมติอนุมัติการเพิ่มทุนจดทะเบียนจาก
                            600.00 ล้านบาท เป็น 800.00 ล้านบาท
                            พร้อมกับเปลี่ยนแปลงมูลค่าที่ตราไว้ของหุ้นจากเดิมมูลค่าหุ้นละ
                            100.00 บาท เป็นมูลค่าหุ้นละ 1.00 บาท
                            โดยออกหุ้นสามัญเพิ่มทุนจำนวน 200.00 ล้านหุ้น
                            มูลค่าหุ้นที่ตราไว้หุ้นละ 1.00 บาท
                            เพื่อเสนอขายต่อประชาชนเป็นครั้งแรก
                            และนำหุ้นสามัญของบริษัทเข้าจดทะเบียนในตลาดหลักทรัพย์แห่งประเทศไทย
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li class="timeline-inverted">
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">ปี 2560</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - ได้รับบัตรส่งเสริมการลงทุน
                            จากคณะกรรมการส่งเสริมการลงทุน (BOI)
                            สำหรับสายการผลิตที่ 2 ของบริษัท
                            โดยได้รับสิทธิประโยชน์ทางภาษีและสิทธิประโยชน์อื่น
                            ในธุรกิจที่เกี่ยวข้องกับการผลิตแผ่นไม้เอ็มดีเอฟ
                            ตามที่กำหนดในบัตรส่งเสริมการลงทุน
                          </p>
                          <p class="t-2">
                            -
                            บริษัทได้จดทะเบียนและเริ่มซื้อขายในตลาดหลักทรัพย์แห่งประเทศไทย
                            ในกลุ่มอุตสาหกรรมอสังหาริมทรัพย์และก่อสร้าง
                            หมวดวัสดุก่อสร้าง เพื่อใช้ในการขยายกำลังการผลิต
                            ชำระเงินกู้ยืมระยะยาว
                            และเป็นเงินทุนหมุนเวียนในการดำเนินธุรกิจ
                          </p>
                          <p class="t-2">
                            - ได้รับการรับรองมาตรฐานคุณภาพ ISO 9001:2015
                            ซึ่งเป็นมาตรฐานรับรองระบบบริหารงานคุณภาพ (Quality
                            Management System)
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">ปี 2561</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            -
                            ได้รับการรับรองเกี่ยวกับปริมาณของสารฟอร์มาลดีไฮด์ในผลิตภัณฑ์ตามมาตรฐาน
                            United States Environmental Protection Agency : U.S.
                            EPA จาก MUTU Certification International
                          </p>
                          <p class="t-2">
                            - จดทะเบียนจัดตั้งบริษัท เอส.คลีเบอร์ เคมีคอล จำกัด
                            (บริษัทย่อย)
                            เพื่อประกอบธุรกิจผลิตและจำหน่ายผลิตภัณฑ์กาวและสารเคมีเพื่ออุตสาหกรรม
                            ด้วยทุนจดทะเบียนรวม 100,000,000.00 บาท
                            เป็นหุ้นสามัญจำนวน 10,000,000 หุ้น
                            มูลค่าหุ้นที่ตราไว้หุ้นละ 10.00 บาท
                            โดยบริษัทถือหุ้นในสัดส่วนร้อยละ 100
                          </p>
                          <p class="t-2">
                            - ได้รับการรับรองมาตรฐานคุณภาพ ISO 14001:2015
                            ซึ่งเป็นมาตรฐานรับรองระบบการจัดการสิ่งแวดล้อม
                            (Environmental Management System)
                          </p>
                          <p class="t-2">
                            - ติดตั้งเครื่องจักรสำหรับสายการผลิตที่ 2 เสร็จสิ้น
                            และเริ่มดำเนินการทดสอบระบบ (Test Run)
                          </p>
                          <p class="t-2">
                            -
                            บริษัทเริ่มดำเนินการผลิตและจำหน่ายแผ่นไม้เอ็มดีเอฟเชิงพาณิชย์
                            สำหรับสายการผลิตที่ 2
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li class="timeline-inverted">
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">ปี 2562</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - ที่ประชุมคณะกรรมการบริษัท ครั้งที่ 1/2562
                            ของบริษัท ได้มีมติอนุมัติโครงการลงทุน “โซลาร์รูฟ”
                            เพื่อผลิตกระแสไฟฟ้าสำหรับใช้ภายในโรงงาน
                            และตกลงลงนามสัญญาเมื่อวันที่ 27 กรกฏาคม 2562
                          </p>
                          <p class="t-2">
                            - ได้รับการรับรองมาตรฐานอุตสากรรมสีเขียวระดับที่ 3
                            (Green Industry)
                            ซึ่งเป็นมาตรฐานรับรองการบริหารจัดการสิ่งแวดล้อมอย่างเป็นระบบ
                            (Green System) มีการติดตามประเมินผล
                            และทบทวนเพื่อการพัฒนาอย่างต่อเนื่อง
                          </p>
                          <p class="t-2">
                            - ได้รับการรับรองมาตรฐานการจัดการห่วงโซ่ผลิตภัณฑ์
                            FSC-COC (Chain of Custody)
                            ซึ่งเป็นการรับรองว่าบริษัทสามารถผลิตผลิตภัณฑ์จากไม้ที่มีการบริหารจัดการอย่างมีความรับผิดชอบตามมาตรฐาน
                            FSC ได้
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li class="timeline-inverted">
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">ปี 2563</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - ติดตั้งแผงโซลาร์เซลล์ ชนิดติดตั้งบนหลังคา
                            และเริ่มดำเนินการผลิตและจ่ายไฟฟ้าเข้าระบบ
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      ) : (
        <section className="mt-36 lg:mt-44 container mx-auto px-4">
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4">
              <h2 className="p1 text-xl">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-[#1b3a91] text-3xl font-semibold">
                History about S.Kijchai
              </h1>
              <p className="secondaryText pr-8">
                One of leaders in manufacturer of wood-based panel in Southeast
                Asia. The company aimed to create the highest value to customer
                and related parties under good governance management principle.
                Additionally, the aim is for to obtain production standard that
                is high in quality for the sustainability of nature,
                environment, and society.
              </p>
            </div>
          </div>

          <div className="mt-8">
            <div className="page-header text-center">
              <p>Significant developments of the organization are as follow</p>
            </div>
            <div>
              <ul className="timeline">
                <li>
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">In 2010</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - The company registered with registered capital of
                            600.00 million baht to operate in business to
                            manufacture and distribute MDF board. The company
                            began its operation in construction of factory and
                            machine installment to manufacture MDF board at
                            Klaeng District, Rayong province.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li class="timeline-inverted">
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">In 2011</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - Received investment promotion certificate from the
                            Board of Investment (BOI) and receive tax rights and
                            other rights in related business to MDF manufacture
                            business as stated in Investment Promotion
                            Certificate.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">In 2012</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - Completed the process of machine installment and
                            begins the test run.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li class="timeline-inverted">
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">In 2013</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - Completed test run as the company began to operate
                            commercially in manufacture and distribution of MDF
                            board, the company starts to distribute to customer
                            domestically and began to export to other countries.
                          </p>
                          <p class="t-2">
                            - Certified with MDF board manufacturing standard
                            and Thin MDF also received MUTU Certification
                            International that is based on California Code of
                            Regulation 93120.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">In 2014</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - Extraordinary session of shareholders 12/2014, the
                            company has reached resolution to grant registered
                            capital from 600.00 million baht to 720.00 million
                            baht. The company agreed to release additional 1.20
                            million common shares with the nominal amount of
                            100.00 baht to propose to shareholders based on
                            shares holdings.{" "}
                          </p>
                          <p class="t-2">
                            - Received International Standardization ISO
                            9001:2008 which is the Quality Management System
                            standards.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li class="timeline-inverted">
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">In 2015</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - At the extraordinary session 4/2015, the company
                            reached resolution to reduce paid-in capital from
                            720.00 million baht to 600.00 million baht.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">In 2016</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - Received International Standardization ISO
                            14001:2004 which is the Environmental Management
                            System standards.
                          </p>
                          <p class="t-2">
                            - At the extraordinary session 1/2016, the company
                            reached resolution to incorporate the company as a
                            public limited company and change the name to
                            S.Kijchai Enterprise Public Company Limited. It is
                            also registered from company limited to public
                            company limited on 14 September, 2016.
                          </p>
                          <p class="t-2">
                            - In addition, during the same extraordinary
                            session, shareholders also reach resolution to
                            increase registered capital from 600.00 million baht
                            to 800.00 million baht. It also changes the nominal
                            amount of shares from 100.00 baht to 1.00 baht. The
                            release of 200.00 million more common shares with
                            the nominal amount of 1.00 baht to propose to the
                            common public for the first time and obtain common
                            share of the company to registered in Stock Exchange
                            of Thailand.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li class="timeline-inverted">
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">In 2017</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - Received investment promotion certificate from the
                            Board of Investment (BOI) for the second product
                            line and receive tax rights and other rights in
                            related business to MDF manufacture business as
                            stated in Investment Promotion Certificate.
                          </p>
                          <p class="t-2">
                            - The company registered and began trade in Stock
                            Exchange of Thailand for real estate and
                            construction group in construction materials section
                            to expand its production operation. It is also to
                            pay a long-term loan and to use as working capital
                            in business operations.
                          </p>
                          <p class="t-2">
                            - Received International Standardization ISO
                            9001:2015 which is the Quality Management System
                            standards.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">In 2018</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - Received International Standardization of United
                            States Environmental Protection Agency: US EPA which
                            is the reduce Formaldehyde Emission from Composite
                            Wood Product from MUTU Certification International.
                          </p>
                          <p class="t-2">
                            - Registered subsidiary company “S.Kleber Chemical
                            Company Limited” with registered capital of 100.00
                            million baht to manufacture and distribute glue and
                            chemical. The Common shares worth 10.00 baht per
                            share with 50.00 million baht paid in capital. SKN
                            is 100.00 percent shareholder.
                          </p>
                          <p class="t-2">
                            - Received International Standardization ISO
                            14001:2015 which is the Environmental Management
                            System standards.
                          </p>
                          <p class="t-2">
                            - Completed installation and began the test run for
                            second production line.
                          </p>
                          <p class="t-2">
                            - Started commercial production and distribution of
                            MDF board from second production line.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li class="timeline-inverted">
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">In 2019</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - At the Board of Directors of 1/2019, approved
                            “Solar Roof” Investment Project to generate
                            electricity for use within the factory and the
                            Company has signed Solar Roof Investment Project
                            Agreement on 27 July 2019.
                          </p>
                          <p class="t-2">
                            - Received Green Industry Level 3 (Green System)
                            standards. which is Systematic environmental
                            management standards including monitoring,
                            assessment, and review for continuous improvement.
                          </p>
                          <p class="t-2">
                            - Received FSC-COC (Chain of Custody) standards.
                            Which is the Company's Product are manufactured from
                            wood of the responsible forest management.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li class="timeline-inverted">
                  <div class="timeline-badge primary">
                    <a>
                      <i
                        class="glyphicon glyphicon-record"
                        rel="tooltip"
                        title="11 hours ago via Twitter"
                        id=""
                      ></i>
                    </a>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-footer">
                      <a>
                        <i class="glyphicon glyphicon-thumbs-up"></i>
                      </a>
                      <a>
                        <i class="glyphicon glyphicon-share"></i>
                      </a>
                      <a class="pull-right">In 2019</a>
                    </div>
                    <div class="timeline-body">
                      <ul>
                        <li>
                          <p class="t-2">
                            - The solar roof project completed installation of
                            solar rooftop panels, started its operation and
                            generate electricity to the system.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      )}
      <div className="clearfloat"></div>
    </>
  );
}
