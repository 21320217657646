import React, { useEffect, useState } from "react";
import { Header } from "../../Components";
import { useGlobalContext } from "../../context/Context";
import { getNews } from "../../Service/Service";
import { Pagination } from "../../Pagination";
import { converDate, converTime, handleScrollToTop } from "../../utils/utils";
import { Link } from "react-router-dom";

export default function Set() {
  const { language } = useGlobalContext();

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState();
  const [pagination, setPagination] = useState([]);
  const [dataNews, setDataNews] = useState([]);

  const handleOnChangePage = (pageNumber) => {
    handleScrollToTop(300);
    // setCurrentPage(pageNumber);
    setCurrentPage(pageNumber - 1);
  };

  useEffect(() => {
    const fetchSetNews = async () => {
      setLoading(true);
      const { status, result } = await getNews(language, currentPage);
      setLoading(false);
      if (status) {
        setDataNews(result.data);
        setPagination(JSON.parse(result.headers["x-pagination"]));
        setPageCount(JSON.parse(result.headers["x-pagination"]).TotalPages);
      }
    };
    fetchSetNews();
  }, [language, currentPage]);
  return (
    <div id="newActivities" className="sub-top-hight">
      <div className="container-I" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <div className="row">
          <div className="box-line">
            <Header
              nameTH={"ประกาศจากตลาดหลักทรัพย์"}
              nameEN={"SET Announcement"}
            />
          </div>
        </div>

        <div className="boxAtv" style={{ margin: "5rem 0" }}>
          {/* <div className="box-1">
              {language === "T" ? (
                <div className="row">
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/T/1343NWS190320241933220563T.pdf"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            การเผยแพร่หนังสือเชิญประชุมสามัญผู้ถือหุ้น ประจำปี
                            2567 และการส่งคำถามล่วงหน้า
                          </p>
                        </a>
                        <div className="day">19/3/2567</div>
                        <div className="time">18.15 น.</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/T/1343NWS270220242107310750T.pdf"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            แจ้งมติที่ประชุมคณะกรรมการบริษัท การจ่ายเงินปันผล
                            การลงทุนโครงการขยายกำลังการผลิต
                            และการกำหนดวันและวาระการประชุมสามัญผู้ถือหุ้น
                            ประจำปี 2567
                          </p>
                        </a>
                        <div className="day">19/3/2567</div>
                        <div className="time">18.15 น.</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/T/1343NWS270220241830230990T.pdf"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            คำอธิบายและวิเคราะห์ของฝ่ายจัดการ ประจำปี
                            สิ้นสุดวันที่ 31 ธ.ค. 2566
                          </p>
                        </a>
                        <div className="day">27/02/2567</div>
                        <div className="time">18.15 น.</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/T/1343NWS270220241821450173T.pdf"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            สรุปผลการดำเนินงานของ บจ. ประจำปี (F45)
                            (ตรวจสอบแล้ว)
                          </p>
                        </a>
                        <div className="day">27/02/2567</div>
                        <div className="time">18.15 น.</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/T/1343FIN270220241731570277T.zip"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>งบการเงิน ประจำปี 2566 (ตรวจสอบแล้ว)</p>
                        </a>
                        <div className="day">27/02/2567</div>
                        <div className="time">18.15 น.</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/T/1343NWS080120241723360909T.pdf"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            แจ้งผลการเสนอวาระการประชุมและเสนอชื่อบุคคลเพื่อเข้ารับเลือกตั้งเป็นกรรมการบริษัท
                            สำหรับการประชุมสามัญผู้ถือหุ้น ประจำปี 2567
                          </p>
                        </a>
                        <div className="day">08/01/2567</div>
                        <div className="time">18.15 น.</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/T/1343NWS181220231709060847T.pdf"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>แจ้งวันหยุดทำการของบริษัทประจำปี 2567</p>
                        </a>
                        <div className="day">18/12/2566</div>
                        <div className="time">18.15 น.</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/T/1343NWS131120231802200419T.pdf"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            คำอธิบายและวิเคราะห์ของฝ่ายจัดการ ไตรมาสที่ 3
                            สิ้นสุดวันที่ 30 ก.ย. 2566
                          </p>
                        </a>
                        <div className="day">13/11/2566</div>
                        <div className="time">18.15 น.</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/T/1343FIN131120231709020634T.zip"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>งบการเงิน ไตรมาสที่ 3/2566 (สอบทานแล้ว)</p>
                        </a>
                        <div className="day">13/11/2566</div>
                        <div className="time">18.15 น.</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/T/1343NWS161020231714310717T.pdf"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            การเสนอวาระการประชุมและเสนอชื่อบุคคลเพื่อเข้ารับเลือกตั้งเป็นกรรมการบริษัท
                            สำหรับการประชุมสามัญผู้ถือหุ้น ประจำปี 2567
                          </p>
                        </a>
                        <div className="day">16/10/2566</div>
                        <div className="time">18.15 น.</div>
                      </div>
                    </div>
                  </div>

                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.efinancethai.com/news/2023/8/T/6608366.pdf"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            คำอธิบายและวิเคราะห์ของฝ่ายจัดการ ไตรมาสที่ 2
                            สิ้นสุดวันที่ 30 มิ.ย. 2566
                          </p>
                        </a>
                        <div className="day">10 ส.ค. 2566</div>
                        <div className="time">17.18 น.</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.irplus.in.th/Listed/SKN/frame_news_detail.asp?newsid=y%7BGtQny&lang=T&title=2&subtitle=2"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            สรุปผลการดำเนินงานของ บจ. ไตรมาสที่ 2 (F45)
                            (สอบทานแล้ว)
                          </p>
                        </a>
                        <div className="day">10 ส.ค. 2566</div>
                        <div className="time">17.17 น.</div>
                      </div>
                    </div>
                  </div>

                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.efinancethai.com/news/downloadfinancial.aspx?year=2023&newsid=6608331&lang=T&page=Announcement&V_server=IR"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>งบการเงิน ไตรมาสที่ 2/2566 (สอบทานแล้ว)</p>
                        </a>
                        <div className="day">10 ส.ค. 2566</div>
                        <div className="time">17.13 น.</div>
                      </div>
                    </div>
                  </div>

                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.irplus.in.th/Listed/SKN/frame_news_detail.asp?newsid=yzKsPrv&lang=T&title=2&subtitle=2"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            คำอธิบายและวิเคราะห์ของฝ่ายจัดการ ไตรมาสที่ 1
                            สิ้นสุดวันที่ 31 มี.ค. 2566
                          </p>
                        </a>
                        <div className="day">10 พ.ค. 2566</div>
                        <div className="time">17.15 น.</div>
                      </div>
                    </div>
                  </div>

                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.irplus.in.th/Listed/SKN/frame_news_detail.asp?newsid=yzKsPpt&lang=T&title=2&subtitle=2"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            สรุปผลการดำเนินงานของ บจ. ไตรมาสที่ 1 (F45)
                            (สอบทานแล้ว)
                          </p>
                        </a>
                        <div className="day">10 พ.ค. 2566</div>
                        <div className="time">17.11 น.</div>
                      </div>
                    </div>
                  </div>

                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.efinancethai.com/news/downloadfinancial.aspx?year=2023&newsid=6547253&lang=T&page=Announcement&V_server=IR"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>งบการเงิน ไตรมาสที่ 1/2566 (สอบทานแล้ว)</p>
                        </a>
                        <div className="day">10 พ.ค. 2566</div>
                        <div className="time">17.10 น.</div>
                      </div>
                    </div>
                  </div>

                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.irplus.in.th/Listed/SKN/frame_news_detail.asp?newsid=yzKnPku&lang=T&title=2&subtitle=2"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            การเผยแพร่รายงานการประชุมสามัญผู้ถือหุ้น ประจำปี
                            2566
                          </p>
                        </a>
                        <div className="day">03 พ.ค. 2566</div>
                        <div className="time">12.44 น.</div>
                      </div>
                    </div>
                  </div>

                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.irplus.in.th/Listed/SKN/frame_news_detail.asp?newsid=yzJpSkv&lang=T&title=2&subtitle=2"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>มติที่ประชุมผู้ถือหุ้น</p>
                        </a>
                        <div className="day">24 เม.ย. 2566</div>
                        <div className="time">17.16 น.</div>
                      </div>
                    </div>
                  </div>

                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.irplus.in.th/Listed/SKN/frame_news_detail.asp?newsid=yzHoPrv&lang=T&title=2&subtitle=2"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            การเผยแพร่หนังสือเชิญประชุมสามัญผู้ถือหุ้น ประจำปี
                            2566 และการส่งคำถามล่วงหน้า
                          </p>
                        </a>
                        <div className="day">22 มี.ค. 2566</div>
                        <div className="time">17.28 น.</div>
                      </div>
                    </div>
                  </div>

                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.irplus.in.th/Listed/SKN/frame_news_detail.asp?newsid=yyPoRpu&lang=T&title=2&subtitle=2"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            แจ้งมติที่ประชุมคณะกรรมการบริษัท การจ่ายเงินปันผล
                            และกำหนดวันประชุมสามัญผู้ถือหุ้น ประจำปี 2566
                          </p>
                        </a>
                        <div className="day">27 ก.พ. 2566</div>
                        <div className="time">20.39 น.</div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/E/1343NWS190320241933220553E.pdf"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            Disclosure of the invitation to the 2024 Annual
                            General Meeting of Shareholders and submission of
                            questions in advance
                          </p>
                        </a>
                        <div className="day">19 Mar 2024</div>
                        <div className="time">06.15 PM</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/E/1343NWS270220242107310729E.pdf"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            Notification of the resolutions of the Board of
                            Directors' meeting, dividend payment, the investment
                            in the expansion capacity project, and the calling
                            of the 2024 Annual General Meeting of Shareholders
                          </p>
                        </a>
                        <div className="day">19 Mar 2024</div>
                        <div className="time">06.15 PM</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/E/1343NWS270220241830230982E.pdf"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            Management Discussion and Analysis Yearly Ending 31
                            Dec 2023
                          </p>
                        </a>
                        <div className="day">27 Feb 2024</div>
                        <div className="time">06.15 PM</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/E/1343NWS270220241821450166E.pdf"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>Financial Performance Yearly (F45) (Audited)</p>
                        </a>
                        <div className="day">27 Feb 2024</div>
                        <div className="time">06.15 PM</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/E/1343FIN270220241731570494E.zip"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>Financial Statement Yearly 2023 (Audited)</p>
                        </a>
                        <div className="day">27 Feb 2024</div>
                        <div className="time">06.15 PM</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/E/1343NWS080120241723360900E.pdf"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            Report on the result of the proposed agendas and
                            nominate candidates to be elected as director for
                            the 2024 Annual General Meeting of Shareholders
                          </p>
                        </a>
                        <div className="day">08 Jan 2024</div>
                        <div className="time">06.15 PM</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/E/1343NWS181220231709060839E.pdf"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            Announcement of the Company's holiday for the year
                            2024
                          </p>
                        </a>
                        <div className="day">18 Dec 2023</div>
                        <div className="time">06.15 PM</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/E/1343NWS131120231802200406E.pdf"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            Management Discussion and Analysis Quarter 3 Ending
                            30 Sep 2023
                          </p>
                        </a>
                        <div className="day">13 Nov 2023</div>
                        <div className="time">06.15 PM</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/E/1343FIN131120231709020766E.zip"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>Financial Statement Quarter 3/2023 (Reviewed)</p>
                        </a>
                        <div className="day">13 Nov 2023</div>
                        <div className="time">06.15 PM</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="/pdf/SET/E/1343NWS161020231714310699E.pdf"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            The proposal of meeting agenda & director nomination
                            for the 2024 Annual General Meeting of Shareholder.
                          </p>
                        </a>
                        <div className="day">16 Oct 2023</div>
                        <div className="time">06.15 PM</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.efinancethai.com/news/2023/8/T/6608366.pdf"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            Management Discussion and Analysis Quarter 2 Ending
                            30 Jun 2023
                          </p>
                        </a>
                        <div className="day">10 Aug 2023</div>
                        <div className="time">05:18 PM</div>
                      </div>
                    </div>
                  </div>
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.irplus.in.th/Listed/SKN/frame_news_detail.asp?newsid=y%7BGtQny&lang=T&title=2&subtitle=2"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>Financial Statement Quarter 2/2023 (Reviewed)</p>
                        </a>
                        <div className="day">10 Aug 2023</div>
                        <div className="time">05:17 PM</div>
                      </div>
                    </div>
                  </div>

                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.efinancethai.com/news/downloadfinancial.aspx?year=2023&newsid=6608331&lang=T&page=Announcement&V_server=IR"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>Financial Statement Quarter 2/2023 (Reviewed)</p>
                        </a>
                        <div className="day">110 Aug 2023</div>
                        <div className="time">05:15 PM</div>
                      </div>
                    </div>
                  </div>

                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.irplus.in.th/Listed/SKN/frame_news_detail.asp?newsid=yzKsPrv&lang=T&title=2&subtitle=2"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            Management Discussion and Analysis Quarter 1 Ending
                            31 Mar 2023
                          </p>
                        </a>
                        <div className="day">10 May 2023</div>
                        <div className="time">05:15 PM</div>
                      </div>
                    </div>
                  </div>

                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.irplus.in.th/Listed/SKN/frame_news_detail.asp?newsid=yzKsPpt&lang=T&title=2&subtitle=2"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>Financial Statement Quarter 1/2023 (Reviewed)</p>
                        </a>
                        <div className="day">10 May 2023</div>
                        <div className="time">05:11 PM</div>
                      </div>
                    </div>
                  </div>

                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.efinancethai.com/news/downloadfinancial.aspx?year=2023&newsid=6547253&lang=T&page=Announcement&V_server=IR"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            Financial Performance Quarter 1 (F45) (Reviewed)
                          </p>
                        </a>
                        <div className="day">10 May 2023</div>
                        <div className="time">05:11 PM</div>
                      </div>
                    </div>
                  </div>

                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.irplus.in.th/Listed/SKN/frame_news_detail.asp?newsid=yzKnPku&lang=T&title=2&subtitle=2"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            Publication of the Minutes of the 2023 Annual
                            General Meeting of Shareholders
                          </p>
                        </a>
                        <div className="day">03 May 2023</div>
                        <div className="time">00:44 PM</div>
                      </div>
                    </div>
                  </div>

                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.irplus.in.th/Listed/SKN/frame_news_detail.asp?newsid=yzJpSkv&lang=T&title=2&subtitle=2"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>Shareholders meeting s resolution</p>
                        </a>
                        <div className="day">24 Apr 2023</div>
                        <div className="time">05:16 PM</div>
                      </div>
                    </div>
                  </div>

                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.irplus.in.th/Listed/SKN/frame_news_detail.asp?newsid=yzHoPrv&lang=T&title=2&subtitle=2"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            Disclosure of the invitation to the 2023 Annual
                            General Meeting of Shareholders and submission of
                            questions in advance.
                          </p>
                        </a>
                        <div className="day">22 Mar 2023</div>
                        <div className="time">05:28 PM</div>
                      </div>
                    </div>
                  </div>

                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        <a
                          href="https://www.irplus.in.th/Listed/SKN/frame_news_detail.asp?newsid=yyPoRpu&lang=T&title=2&subtitle=2"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>
                            Notification of the resolutions of the Board of
                            Directors meeting, dividend payment, and the calling
                            of the 2023 Annual General Meeting of Shareholders.
                          </p>
                        </a>
                        <div className="day">27 Feb 2023</div>
                        <div className="time">08:39 PM</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div> */}
          <div className="box-1">
            <div className="row">
              {loading && <div class="loader"></div>}
              {dataNews.map((news) => {
                return (
                  <div className="box-col">
                    <div className="cover-news">
                      <div className="news">
                        {/* <a
                          href={news.documents}
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <p>{news.headline}</p>
                        </a> */}
                        <Link
                          to={`/setNews/${news.newsId}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            textDecoration: "none",
                            color: "black",
                          }}
                        >
                          {news.headline}
                        </Link>
                        <div className="day">
                          {converDate(news.publishedDate, language)}
                        </div>
                        <div className="time">
                          {converTime(news.publishedTime, language)}{" "}
                          {language === "T" ? "น." : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <Pagination
              totalPages={pageCount}
              currentPage={currentPage + 1} // แสดงหน้าเป็น 1-based
              onChangePage={handleOnChangePage}
              hasNext={pagination.HasNext}
              hasPrevious={pagination.HasPrevious}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
