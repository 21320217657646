import { useGlobalContext } from "../../../context/Context";

export default function FinancialMda() {
  const { language } = useGlobalContext();
  return (
    <>
      {language === "T" ? (
        <section className="mt-36 mb-8 lg:mt-44 container mx-auto px-4">
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-4xl  text-[#1b3a91] font-bold">
                คำอธิบายและการวิเคราะห์
              </h1>
            </div>
          </div>

          <div class="cover-corporate">
            <div class="container">
              <div class="rows">
                <div class="col-xl-12">
                  {/* table */}
                  <div class="container mt-4">
                    <div class="relative overflow-x-auto">
                      <table class="w-[600px] sm:w-full mt-4 text-sm text-center">
                        <thead class="text-md bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                          <tr>
                            <th
                              scope="col"
                              class="px-6 py-4 text-center text-white"
                            >
                              เอกสาร
                            </th>
                            <th scope="col" class="px-6 py-4 text-white">
                              ประจำปี
                            </th>
                            <th scope="col" class="px-6 py-4 text-white">
                              ขนาดไฟล์
                            </th>
                            <th scope="col" class="px-6 py-4 text-white">
                              ดาวน์โหลดเอกสาร
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base  hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium text-start"
                            >
                              คำอธิบายและการวิเคราะห์
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              2/2567
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              72 KB
                            </td>
                            <td class="px-6 py-4 text-[#092267] text-center">
                              <a
                                href="pdf/mda/MD&A_Q2_2024_T.pdf"
                                target="_blank"
                              >
                                ไฟล์ดาวน์โหลด
                              </a>
                            </td>
                          </tr>
                          <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base  hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium text-start"
                            >
                              คำอธิบายและการวิเคราะห์
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              1/2567
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              63 KB
                            </td>
                            <td class="px-6 py-4 text-[#092267] text-center">
                              <a
                                href="pdf/mda/MD&A_Q1_2024_T.pdf"
                                target="_blank"
                              >
                                ไฟล์ดาวน์โหลด
                              </a>
                            </td>
                          </tr>

                          {/* 2566 */}
                          <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base  hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium text-start"
                            >
                              คำอธิบายและการวิเคราะห์
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              2566
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              72 KB
                            </td>
                            <td class="px-6 py-4 text-[#092267] text-center">
                              <a href="pdf/mda/MD&A_2023_T.pdf" target="_blank">
                                ไฟล์ดาวน์โหลด
                              </a>
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base  hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium text-start"
                            >
                              คำอธิบายและการวิเคราะห์
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              2565
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              81.9 KB
                            </td>
                            <td class="px-6 py-4 text-[#092267] text-center">
                              <a href="pdf/mda/MD&A_2022_T.pdf" target="_blank">
                                ไฟล์ดาวน์โหลด
                              </a>
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base  hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium text-start"
                            >
                              คำอธิบายและการวิเคราะห์
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              2564
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              100 KB
                            </td>
                            <td class="px-6 py-4 text-[#092267] text-center">
                              <a href="pdf/mda/MD&A_2021_T.pdf" target="_blank">
                                ไฟล์ดาวน์โหลด
                              </a>
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base  hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium text-start"
                            >
                              คำอธิบายและการวิเคราะห์
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              2563
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              18.3 KB
                            </td>
                            <td class="px-6 py-4 text-[#092267] text-center">
                              <a href="pdf/mda/MD&A_2020_T.pdf" target="_blank">
                                ไฟล์ดาวน์โหลด
                              </a>
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base  hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium text-start"
                            >
                              คำอธิบายและการวิเคราะห์
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              2562
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              11 KB
                            </td>
                            <td class="px-6 py-4 text-[#092267] text-center">
                              <a href="pdf/mda/MD&A_2019_T.pdf" target="_blank">
                                ไฟล์ดาวน์โหลด
                              </a>
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base  hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium text-start"
                            >
                              คำอธิบายและการวิเคราะห์
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              2561
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              193 KB
                            </td>
                            <td class="px-6 py-4 text-[#092267] text-center">
                              <a href="pdf/mda/MD&A_2018_T.pdf" target="_blank">
                                ไฟล์ดาวน์โหลด
                              </a>
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base  hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium text-start"
                            >
                              คำอธิบายและการวิเคราะห์
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              2560
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              4,045 KB
                            </td>
                            <td class="px-6 py-4 text-[#092267] text-center">
                              <a href="pdf/mda/MD&A_2017_T.pdf" target="_blank">
                                ไฟล์ดาวน์โหลด
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="mt-36 mb-8 lg:mt-44 container mx-auto px-4">
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-4xl  text-[#1b3a91] font-bold">MD&A</h1>
            </div>
          </div>

          <div class="cover-corporate">
            <div class="container">
              <div class="rows">
                <div class="col-xl-12">
                  {/* table */}
                  <div class="container mt-4">
                    <div class="relative overflow-x-auto">
                      <table class="w-[600px] sm:w-full mt-4 text-sm text-center">
                        <thead class="text-md bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                          <tr>
                            <th
                              scope="col"
                              class="px-6 py-4 text-center text-white"
                            >
                              File
                            </th>
                            <th scope="col" class="px-6 py-4 text-white">
                              Year
                            </th>
                            <th scope="col" class="px-6 py-4 text-white">
                              Size
                            </th>
                            <th scope="col" class="px-6 py-4 text-white">
                              Download File
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base  hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium text-start"
                            >
                              Management Discussion & Analysis
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              2/2024
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              64 KB
                            </td>
                            <td class="px-6 py-4 text-[#092267] text-center">
                              <a
                                href="pdf/mda/MD&A_Q2_2024_E.pdf"
                                target="_blank"
                              >
                                Download
                              </a>
                            </td>
                          </tr>
                          <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base  hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium text-start"
                            >
                              Management Discussion & Analysis
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              1/2024
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              52 KB
                            </td>
                            <td class="px-6 py-4 text-[#092267] text-center">
                              <a
                                href="pdf/mda/MD&A_Q1_2024_E.pdf"
                                target="_blank"
                              >
                                Download
                              </a>
                            </td>
                          </tr>
                          {/* 2023 */}
                          <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base  hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium text-start"
                            >
                              Management Discussion & Analysis
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              2023
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              96 KB
                            </td>
                            <td class="px-6 py-4 text-[#092267] text-center">
                              <a href="pdf/mda/MD&A_2023_E.pdf" target="_blank">
                                Download
                              </a>
                            </td>
                          </tr>
                          <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base  hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium text-start"
                            >
                              Management Discussion & Analysis
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              2022
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              81.9 KB
                            </td>
                            <td class="px-6 py-4 text-[#092267] text-center">
                              <a href="pdf/mda/MD&A_2022_E.pdf" target="_blank">
                                Download
                              </a>
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base  hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium text-start"
                            >
                              Management Discussion & Analysis
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              2021
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              100 KB
                            </td>
                            <td class="px-6 py-4 text-[#092267] text-center">
                              <a href="pdf/mda/MD&A_2021_E.pdf" target="_blank">
                                Download
                              </a>
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base  hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium text-start"
                            >
                              Management Discussion & Analysis
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              2020
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              18.3 KB
                            </td>
                            <td class="px-6 py-4 text-[#092267] text-center">
                              <a href="pdf/mda/MD&A_2020_E.pdf" target="_blank">
                                Download
                              </a>
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base  hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium text-start"
                            >
                              Management Discussion & Analysis
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              2019
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              11 KB
                            </td>
                            <td class="px-6 py-4 text-[#092267] text-center">
                              <a
                                href="pdf/mda/MD&A_2019A_E.pdf"
                                target="_blank"
                              >
                                Download
                              </a>
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base  hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium text-start"
                            >
                              Management Discussion & Analysis
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              2018
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              193 KB
                            </td>
                            <td class="px-6 py-4 text-[#092267] text-center">
                              <a href="pdf/mda/MD&A_2018_E.pdf" target="_blank">
                                Download
                              </a>
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base  hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-4 text-[#1b3a91] font-medium text-start"
                            >
                              Management Discussion & Analysis
                            </th>
                            <td class="px-6 py-4 text-[#666] text-center">
                              2017
                            </td>
                            <td class="px-6 py-4 text-[#666] text-center">
                              4,045 KB
                            </td>
                            <td class="px-6 py-4 text-[#092267] text-center">
                              <a href="pdf/mda/MD&A_2017_E.pdf" target="_blank">
                                Download
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
