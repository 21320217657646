// export const pathMappings = {
//     "/IR_SKN/Chairman": "Chairman",
//     "/IR_SKN/History": "History",
//     "/IR_SKN/Vision": "Vision",
//     "/IR_SKN/Organization": "Organization",
//     "/IR_SKN/Board": "Board",
//     "/IR_SKN/Awards": "Awards",
//     "/IR_SKN/Set": "Set",
//     "/IR_SKN/Public": "Public",
//     "/IR_SKN/Press": "Press",
//     "/IR_SKN/NewsUpdate": "News Update",
//     "/IR_SKN/RDetail1": "R Detail 1",
//     "/IR_SKN/RDetail2": "R Detail 2",
//     "/IR_SKN/NewsClipping": "News Clipping",
//     "/IR_SKN/Corporate": "Corporate",
//     "/IR_SKN/Anti": "Anti",
//     "/IR_SKN/Csr": "CSR",
//     "/IR_SKN/Investor": "Investor",
//     "/IR_SKN/Multimedia": "Multimedia",
//     "/IR_SKN/Analysis": "Analysis",
//     "/IR_SKN/ContactInvestor": "Contact Investor",
//     "/IR_SKN/Contact": "Contact",
//     "/IR_SKN/Inquiry": "Inquiry",
//     "/IR_SKN/Complaint": "Complaint",
//     "/IR_SKN/Email": "Email",
//     "/IR_SKN/FAQs": "FAQs",
//     "/IR_SKN/financial_statement": "Financial Statement",
//     "/IR_SKN/financial_highlights": "Financial Highlights",
//     "/IR_SKN/financial_mda": "Financial MDA",
//     "/IR_SKN/financial_download": "Financial Download",
//     "/IR_SKN/AnnualReport": "Annual Report",
//     "/IR_SKN/Form_56_1": "Form 56-1",
//     "/IR_SKN/GeneralMeeting": "General Meeting",
//     "/IR_SKN/MajorShareholder": "Major Shareholder",
//     "/IR_SKN/DividendPolicy": "Dividend Policy",
//     "/IR_SKN/IRCalendar": "IR Calendar",
//     "/IR_SKN/StockPrice": "Stock Price",
//     "/IR_SKN/CookiePolicy": "Cookie Policy",
//     "/IR_SKN/PrivacyPolicy": "Privacy Policy",
//     "/IR_SKN/ReadDetail/:relateInfo": "Read Detail",
//     "/IR_SKN/setNews/:link": "Set News"
//   };
  

  export const pathMappings = {
    "/Chairman": "Chairman",
    "/History": "History",
    "/Vision": "Vision",
    "/Organization": "Organization",
    "/Board": "Board",
    "/Awards": "Awards",
    "/Set": "Set",
    "/Public": "Public",
    "/Press": "Press",
    "/NewsUpdate": "News Update",
    "/RDetail1": "R Detail 1",
    "/RDetail2": "R Detail 2",
    "/NewsClipping": "News Clipping",
    "/Corporate": "Corporate",
    "/Anti": "Anti",
    "/Csr": "CSR",
    "/Investor": "Investor",
    "/Multimedia": "Multimedia",
    "/Analysis": "Analysis",
    "/ContactInvestor": "Contact Investor",
    "/Contact": "Contact",
    "/Inquiry": "Inquiry",
    "/Complaint": "Complaint",
    "/Email": "Email",
    "/FAQs": "FAQs",
    "/financial_statement": "Financial Statement",
    "/financial_highlights": "Financial Highlights",
    "/financial_mda": "Financial MDA",
    "/financial_download": "Financial Download",
    "/AnnualReport": "Annual Report",
    "/Form_56_1": "Form 56-1",
    "/GeneralMeeting": "General Meeting",
    "/MajorShareholder": "Major Shareholder",
    "/DividendPolicy": "Dividend Policy",
    "/IRCalendar": "IR Calendar",
    "/StockPrice": "Stock Price",
    "/CookiePolicy": "Cookie Policy",
    "/PrivacyPolicy": "Privacy Policy",
    "/ReadDetail/:relateInfo": "Read Detail",
    "/setNews/:link": "Set News"
  };
  