import React from "react";
import { useGlobalContext } from "../../../context/Context";
import { Link } from "react-router-dom";

export default function Form() {
  const { language } = useGlobalContext();

  return (
    <div className="container-form">
      <div className="content-form">
        <div className="cover-form">
          {language === "T" ? (
            <div className="main-content-raw-layout">
              <div className="main-head-form">
                <p className="head-form t-brown">
                  กรอกข้อมูลเพื่อรับใบเสนอราคา / โปรโมชั่นพิเศษ
                </p>
              </div>
              <div className="main-head-form">
                <div className="cover-input-form">
                  <input
                    className="input-form"
                    type="text"
                    placeholder="ชื่อ (required)"
                  />
                  <input
                    className="input-form"
                    type="text"
                    placeholder="เบอร์โทรศัพท์ (required)"
                  />
                  <input
                    className="input-form"
                    type="text"
                    placeholder="ชื่ออีเมล (required)"
                  />
                  <textarea
                    className="input-form textarea-form"
                    placeholder="ข้อความ (required)"
                  />
                  <label className="accept-terms">
                    ข้าพเจ้าได้อ่านและยอมรับ
                    <Link to={"/PrivacyPolicy"} target="_blank">
                      นโยบายความเป็นส่วนบุคคล
                    </Link>
                    ของบริษัทฯ แล้ว
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                  </label>
                  <input
                    className="bt-submit"
                    type="button"
                    value="ส่งข้อมูล"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="main-content-raw-layout">
              <div className="main-head-form">
                <p className="head-form t-brown">
                  Fill out the information to receive a quotation /special
                  promotion.
                </p>
              </div>
              <div className="main-head-form">
                <div className="cover-input-form">
                  <input
                    className="input-form"
                    type="text"
                    placeholder="Name (required)"
                  />
                  <input
                    className="input-form"
                    type="text"
                    placeholder="Phone (required)"
                  />
                  <input
                    className="input-form"
                    type="text"
                    placeholder="Email (required)"
                  />
                  <textarea
                    className="input-form textarea-form"
                    placeholder="message (required)"
                  />
                  <label className="accept-terms">
                    I have read and accepted
                    <Link to={"/PrivacyPolicy"} target="_blank">
                      Privacy Policy
                    </Link>
                    of The Company.
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                  </label>
                  <input className="bt-submit" type="button" value="Send" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
