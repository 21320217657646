import React from "react";
import { organizationEN, organizationTH } from "../../assets/Image";
import { useGlobalContext } from "../../context/Context";

export default function Organization() {
  const { language } = useGlobalContext();
  return (
    <section className="mt-36 mb-8 lg:mt-44 container mx-auto px-4">
      {language === "T" ? (
        <div className="container-I" style={{ marginBottom: 100 }}>
          <div className="row">
            <div className="box-line-2">
              <div className="box pl-4 md:pl-8 pr-8 py-4">
                <div className="flex flex-col gap-4 text-center">
                  <h2 className="p1">Welcome To S.Kijchai</h2>
                  <h1 className="p2 text-3xl  text-[#1b3a91] font-bold">
                    โครงสร้างองค์กร
                  </h1>
                </div>
              </div>
            </div>
            <div className="cover-organization">
              <img className="img-zoom" src={organizationTH} alt="" />
              {/* <img role="presentation" src="https://www.irplus.in.th/Listed/SKN/images/organization_th.jpg" class="zoomImg" style="position: absolute; top: -18.6085px; left: -31.8285px; opacity: 0; width: 1445px; height: 989px; border: none; max-width: none; max-height: none;"> */}
            </div>
          </div>
        </div>
      ) : (
        <div id="#organization" className="sub-top-hight">
          <div className="container-I" style={{ marginBottom: 100 }}>
            <div className="row">
              <div className="box-line-2">
                <div className="box pl-4 md:pl-8 pr-8 py-4">
                  <div className="flex flex-col gap-4 text-center">
                    <h2 className="p1">Welcome To S.Kijchai</h2>
                    <h1 className="p2 text-3xl  text-[#1b3a91] font-bold">
                      Organization Chart
                    </h1>
                  </div>
                </div>
              </div>
              <div className="cover-organization">
                <img className="img-zoom" src={organizationEN} alt="" />
                {/* <img role="presentation" src="https://www.irplus.in.th/Listed/SKN/images/organization_th.jpg" class="zoomImg" style="position: absolute; top: -18.6085px; left: -31.8285px; opacity: 0; width: 1445px; height: 989px; border: none; max-width: none; max-height: none;"> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
