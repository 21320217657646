import React from "react";
import { useGlobalContext } from "../../../context/Context";

export default function Banner({
  bg,
  text1,
  text2,
  text3,
  subText,
  detail1TH,
  detail1EN,
  detail2TH,
  detail2EN,
}) {
  const { language } = useGlobalContext();

  return (
    <div className={`container-banner ${bg}`}>
      <p className="t0-banner">{text1}</p>
      <p>
        <span className="t1-banner ">{text2}</span>
        <span className="t1-banner thin"> {text3}</span>
      </p>
      <p className="t2-banner black">{subText}</p>
      <p className="t3-banner">
        {language === "T" ? detail1TH : detail1EN}
        <span className="t3-banner respon-none">
          {language === "T" ? detail2TH : detail2EN}
        </span>
      </p>
    </div>
  );
}
