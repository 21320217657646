export const dividendPolicyData = [
  {
    security: "SKN",
    approved: "27/02/2024",
    xdDate: "12/03/2024",
    paymentDate: "17/05/2024",
    typeOfDividend: "Cash Dividend",
    dividendPerShare: "0.21",
    performancePeriod: "01/01/23-31/12/23",
  },
  {
    security: "SKN",
    approved: "27/02/2023",
    xdDate: "13/03/2023",
    paymentDate: "19/05/2023",
    typeOfDividend: "Cash Dividend",
    dividendPerShare: "0.36",
    performancePeriod: "01/01/22-31/12/22",
  },
  {
    security: "SKN",
    approved: "23/02/2022",
    xdDate: "10/03/2022",
    paymentDate: "20/05/2022",
    typeOfDividend: "Cash Dividend",
    dividendPerShare: "0.28",
    performancePeriod: "01/01/21-31/12/21",
  },
  {
    security: "SKN",
    approved: "24/02/2021",
    xdDate: "-",
    paymentDate: "-",
    typeOfDividend: "Omitted dividend",
    dividendPerShare: "-",
    performancePeriod: "01/01/20-31/12/20",
  },
  {
    security: "SKN",
    approved: "26/02/2020",
    xdDate: "11/03/2020",
    paymentDate: "22/05/2020",
    typeOfDividend: "Cash Dividend",
    dividendPerShare: "0.0306",
    performancePeriod: "01/01/19-31/12/19",
  },
  {
    security: "SKN",
    approved: "27/02/2019",
    xdDate: "13/03/2019",
    paymentDate: "24/05/2019",
    typeOfDividend: "Cash Dividend",
    dividendPerShare: "0.0982",
    performancePeriod: "01/01/18-31/12/18",
  },
  {
    security: "SKN",
    approved: "26/02/2018",
    xdDate: "12/03/2018",
    paymentDate: "24/05/2018",
    typeOfDividend: "Cash Dividend",
    dividendPerShare: "0.1074",
    performancePeriod: "01/01/17-31/12/17",
  },
];
