import React from "react";
import { useGlobalContext } from "../../context/Context";

export default function Email() {
  const { language } = useGlobalContext();
  return (
    <>
      <section className="mt-36 mb-8 lg:mt-44 container mx-auto px-4">
        {language == "TH" ? (
          <>
            <div className="box pl-4 md:pl-8 pr-8 py-4">
              <div className="flex flex-col gap-4 text-center">
                <h2 className="p1">Welcome To S.Kijchai</h2>
                <h1 className="p2 text-3xl  text-[#1b3a91] font-bold">
                  รับข่าวสารทางอีเมล
                </h1>
              </div>
            </div>

            <div className="cover-corporate" style={{ margin: "2rem 0 5rem" }}>
              <div className="container">
                <div className="row">
                  <div className="auto-cols-max">
                    {/* <div className="em-t1"> */}
                    <p className="text-lg text-center font-normal leading-relaxed mt-10 text-black">
                      ท่านสามารถลงทะเบียนเพื่อรับข่าวสารอิเล็กทรอนิกส์
                      เราจะส่งข่าวสารให้ท่านทาง อีเมล
                      เมื่อมีข่าวสารที่เกี่ยวกับบริษัท (ประกาศ,
                      ปรับปรุงเว็บไซต์) ที่ประกาศผ่านเว็บไซต์นี้
                    </p>
                    <p className="text-lg text-center font-normal leading-relaxed text-black">
                      เพื่อลงทะเบียนรับข้อมูลข่าวสารอิเล็กทรอนิกส์กรุณากรอกรายละเอียด
                      ของท่านและคลิกปุ่ม “สมัครสมาชิกรับข่าวสาร”
                    </p>

                    <div className="flex justify-start mt-5">
                      <form className="w-full">
                        <label for="email">
                          <p className="text-lg leading-normal text-left font-normal text-black">
                            อีเมล *
                          </p>
                        </label>

                        <input
                          className="form-control mt-3 w-full"
                          type="text"
                        ></input>
                        <div className="flex">
                          <input
                            className="check-b"
                            type="checkbox"
                            style={{
                              height: 18,
                              width: 18,
                              marginRight: 5,
                              marginTop: 30,
                              display: "flex",
                            }}
                          ></input>
                          <p
                            className="em-t1-poly text-black"
                            style={{ marginTop: 30 }}
                          >
                            ฉันได้อ่านและยอมรับข้อกำหนดและเงื่อนไขที่ระบุไว้ใน
                            <a
                              className="poly-text"
                              href="/PrivacyPolicy"
                              target="_blank"
                            >
                              {" "}
                              นโยบายความเป็นส่วนตัว{" "}
                            </a>
                            และยินยอมให้รวบรวมประมวลผลและ /
                            หรือเปิดเผยข้อมูลส่วนบุคคลที่ฉันให้ไว้เพื่อบรรลุวัตถุประสงค์ดังกล่าวข้างต้น
                          </p>
                        </div>
                      </form>
                    </div>

                    <div className="flex justify-center mt-20 mb-10">
                      <button className="fc-1-btn">สมัครรับข่าวสาร</button>
                    </div>
                    <div className="em-t1" style={{ marginBottom: 50 }}>
                      หมายเหตุ: หากท่านต้องการยกเลิกข่าวสารอิเล็กทรอนิกส์จาก
                      บริษัท ส.กิจชัย เอ็นเตอร์ไพรส์ จํากัด (มหาชน){" "}
                      <a className="poly-text"> โปรดคลิกที่นี่ </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="box pl-4 md:pl-8 pr-8 py-4">
              <div className="flex flex-col gap-4 text-center">
                <h2 className="p1">Welcome To S.Kijchai</h2>
                <h1 className="p2 text-3xl  text-[#1b3a91] font-bold">
                  E-mail Alert
                </h1>
              </div>
            </div>

            <div className="cover-corporate" style={{ margin: "2rem 0 5rem" }}>
              <div className="container">
                <div className="row">
                  <div className="auto-cols-max">
                    {/* <div className="em-t1"> */}
                    <p className="text-lg text-center font-normal leading-relaxed mt-10 text-black">
                      Welcome to s.kijchai enterprise public company limited.
                      email alert service. Our systems will automatically send
                      you Company Announcements and SET Announcements on
                      s.kijchai enterprise public company limited.
                    </p>
                    <p className="text-lg text-center font-normal leading-relaxed text-black">
                      Please fill up your details below to receive email alerts.
                    </p>

                    <div className="flex justify-start mt-5">
                      <form className="w-full">
                        <label for="email">
                          <p className="text-lg leading-normal text-left font-normal text-black">
                            Email *
                          </p>
                        </label>

                        <input
                          className="form-control mt-3 w-full"
                          type="text"
                        ></input>
                        <div className="flex">
                          <input
                            className="check-b"
                            type="checkbox"
                            style={{
                              height: 18,
                              width: 18,
                              marginRight: 5,
                              marginTop: 30,
                              display: "flex",
                            }}
                          ></input>
                          <p
                            className="em-t1-poly text-black"
                            style={{ marginTop: 30 }}
                          >
                            I have read and accepted terms and conditions
                            specified in the
                            <a
                              className="poly-text"
                              href="/PrivacyPolicy"
                              target="_blank"
                            >
                              Privacy Policy
                            </a>
                            and do hereby consent to the collecting, processing
                            and/or disclosing of the personal data provided by
                            me to fulfil the above-said purpose.
                          </p>
                        </div>
                      </form>
                    </div>

                    <div className="flex justify-center mt-20 mb-10">
                      <button className="fc-1-btn">Subscribe</button>
                    </div>
                    <div className="em-t1" style={{ marginBottom: 50 }}>
                      Note: To unsubscribe from s.kijchai enterprise public
                      company limited. email alerts,
                      <a className="poly-text"> please click here </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
}
