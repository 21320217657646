import React, { useState } from "react";
import { logo } from "../../assets/Logo";
import { Link, NavLink } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { useGlobalContext } from "../../context/Context";

export default function Navbar() {
  const { language, changeLanguage } = useGlobalContext();
  const [onShow, setOnshow] = useState(false);

  function handleMenu() {
    setOnshow((prev) => !prev);
  }

  return (
    <div className="navigation-wrap start-header">
      <div className="cover-menu">
        <div className="navbar-logo">
          <Link to={"/"}>
            <img src={logo} />
          </Link>
        </div>
        <div className="navbar-hamburger" onClick={() => handleMenu()}>
          {onShow === true ? <RxCross2 /> : <FiMenu />}
        </div>
        <ul
          className={`navbar-collapse ${
            !onShow ? "collapse" : ""
          } navbar-mobile`}
        >
          <li className="nav-item">
            <Link to={"/"} className="nav-link">
              {language === "T" ? "หน้าหลัก" : "Home"}
            </Link>
          </li>
          <li className="nav-item">
            <div className="nav-link">
              {language === "T" ? "เกี่ยวกับเรา" : "About Us"}
            </div>
            <ul className="dropdown-menu animated fadeIn">
              <li>
                <Link to={"/Chairman"} className="dropdown-item">
                  {language === "T"
                    ? "สารจากประธานกรรมการบริษัท"
                    : "Chairman’s Statement"}
                </Link>
              </li>
              <li>
                <Link to={"/History"} className="dropdown-item">
                  {language === "T" ? "ประวัติความเป็นมา" : "History"}
                </Link>
              </li>
              <li>
                <Link to={"/Vision"} className="dropdown-item">
                  {language === "T"
                    ? "วิสัยทัศน์ และ พันธกิจ"
                    : "Vision and Mission"}
                </Link>
              </li>
              <li>
                <Link to={"/Organization"} className="dropdown-item">
                  {language === "T" ? "โครงสร้างองค์กร" : "Organization Chart"}
                </Link>
              </li>
              <li>
                <Link to={"/Board"} className="dropdown-item">
                  {language === "T"
                    ? "คณะกรรมการบริษัทและผู้บริหาร"
                    : "Board of Director & Management Team"}
                </Link>
              </li>
              <li>
                <Link to={"/Awards"} className="dropdown-item">
                  {language === "T"
                    ? "รางวัลและการรับรอง"
                    : "Awards and certifications"}
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <div className="nav-link">
              {" "}
              {language === "T" ? "ข่าวสารและกิจกรรม" : "News & Activities"}
            </div>
            <ul className="dropdown-menu animated fadeIn">
              <li>
                <Link to={"/Set"} className="dropdown-item">
                  {language === "T"
                    ? "ประกาศจากตลาดหลักทรัพย์"
                    : "SET Announcement"}
                </Link>
              </li>
              <li>
                <Link to={"/NewsUpdate"} className="dropdown-item">
                  {language === "T" ? "ข่าวสารล่าสุด" : "News Update"}
                </Link>
              </li>
              <li>
                <Link to={"/Public"} className="dropdown-item">
                  {language === "T"
                    ? "ห้องข่าวประชาสัมพันธ์"
                    : "Public Relation"}
                </Link>
              </li>
              <li>
                <Link to={"/NewsClipping"} className="dropdown-item">
                  {language === "T" ? "ข่าวจากหนังสือพิมพ์" : "News Clipping"}
                </Link>
              </li>
              <li>
                <Link to={"/Press"} className="dropdown-item">
                  {language === "T" ? "ข่าวเผยแพร่" : "Press Releases"}
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <div className="nav-link">
              {language === "T" ? "การพัฒนาสู่ความยั่งยืน" : "Sustainability"}
            </div>
            <ul className="dropdown-menu animated fadeIn">
              <li>
                <Link to={"/Corporate"} className="dropdown-item">
                  {language === "T"
                    ? "การกำกับดูแลกิจการ"
                    : "Corporate Governance"}
                </Link>
              </li>
              <li>
                <Link to={"/Anti"} className="dropdown-item">
                  {language === "T"
                    ? "การต่อต้านทุจริตและคอร์รัปชั่น"
                    : "Anti-corruption"}
                </Link>
              </li>
              <li>
                <Link to={"/Csr"} className="dropdown-item">
                  {language === "T"
                    ? "ความรับผิดชอบต่อสังคมและกิจกรรมต่างๆ"
                    : "CSR & Activities"}
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <div className="nav-link">
              {language === "T" ? "นักลงทุนสัมพันธ์" : "Investor Relations"}
            </div>
            <ul className="dropdown-menu animated fadeIn">
              <li>
                <Link to={"/Investor"} className="dropdown-item">
                  {language === "T" ? "หน้าหลักนักลงทุนสัมพันธ์" : "IR HOME"}
                </Link>
              </li>
              <li className="nav-item-nav-hover">
                <Link to={"#"} className="dropdown-item">
                  {language === "T"
                    ? "ข้อมูลทางการเงิน"
                    : "Financial Information"}
                </Link>
                <ul className="dropdown-item-sub left animated fadeIn">
                  <li>
                    <Link
                      to={"financial_statement"}
                      className="dropdown-item padding-left"
                    >
                      {language === "T" ? "งบการเงิน" : "Financial Statement"}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"financial_highlights"}
                      className="dropdown-item padding-left"
                    >
                      {language === "T"
                        ? "ข้อมูลสำคัญทางการเงิน"
                        : "Financial Highlights"}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"financial_mda"}
                      className="dropdown-item padding-left"
                    >
                      {language === "T" ? "คำอธิบายและวิเคราะห์" : "MD&A"}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item-nav-hover">
                <Link to={"#"} className="dropdown-item">
                  {language === "T"
                    ? "รายงานประจำปี / แบบ 56-1"
                    : "Annual Report / Form 56-1"}
                </Link>
                <ul className="dropdown-item-sub left animated fadeIn">
                  <li>
                    <Link
                      to={"AnnualReport"}
                      className="dropdown-item padding-left"
                    >
                      {language === "T" ? "รายงานประจำปี" : "Annual Report"}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"Form_56_1"}
                      className="dropdown-item padding-left"
                    >
                      {language === "T" ? "แบบ 56-1" : "Form 56-1"}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item-nav-hover">
                <Link to={"#"} className="dropdown-item">
                  {language === "T"
                    ? "ข้อมูลผู้ถือหุ้น"
                    : "Shareholder Information"}
                </Link>
                <ul className="dropdown-item-sub left animated fadeIn">
                  <li>
                    <Link
                      to={"GeneralMeeting"}
                      className="dropdown-item padding-left"
                    >
                      {language === "T"
                        ? "การประชุมผู้ถือหุ้น"
                        : "General Meeting"}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"MajorShareholder"}
                      className="dropdown-item padding-left"
                    >
                      {language === "T"
                        ? "โครงสร้างผู้ถือหุ้น"
                        : "Major Shareholder"}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"DividendPolicy"}
                      className="dropdown-item padding-left"
                    >
                      {language === "T"
                        ? "นโยบายและประวัติการจ่ายเงินปันผล"
                        : "Dividend Policy & Payment"}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"IRCalendar"}
                      className="dropdown-item padding-left"
                    >
                      {language === "T"
                        ? "ปฏิทินกิจกรรมนักลงทุนสัมพันธ์"
                        : "IR Calendar"}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"Multimedia"} className="dropdown-item">
                  {language === "T"
                    ? "ข้อมูลนำเสนอแบบมัลติมีเดีย"
                    : "Webcast & Presentation"}
                </Link>
              </li>
              <li>
                <Link to={"Analysis"} className="dropdown-item">
                  {language === "T" ? "บทวิเคราะห์" : "Analyst Report"}
                </Link>
              </li>
              <li className="nav-item-nav-hover">
                <Link to={"#"} className="dropdown-item">
                  {language === "T"
                    ? "ข้อมูลราคาหลักทรัพย์"
                    : "Stock Information"}
                </Link>
                <ul className="dropdown-item-sub left animated fadeIn">
                  <li>
                    <Link
                      to={"StockPrice"}
                      className="dropdown-item padding-left"
                    >
                      {language === "T"
                        ? "ราคาหลักทรัพย์ล่าสุด"
                        : "Stock Price"}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={
                        "https://www.set.or.th/th/market/product/stock/quote/skn/historical-trading"
                      }
                      className="dropdown-item padding-left"
                    >
                      {language === "T"
                        ? "ราคาหลักทรัพย์ย้อนหลัง"
                        : "Historical Price"}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"ContactInvestor"} className="dropdown-item">
                  {language === "T" ? "ติตต่อนักลงทุนสัมพันธ์" : "IR Contact"}
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <div className="nav-link">
              {language === "T" ? "ติดต่อเรา" : "Contact Us"}
            </div>
            <ul className="dropdown-menu animated fadeIn">
              <li>
                <Link to={"Contact"} className="dropdown-item">
                  {language === "T" ? "ติดต่อเรา" : "Contact Us"}
                </Link>
              </li>
              <li>
                <Link to={"Inquiry"} className="dropdown-item">
                  {language === "T" ? "สอบถามข้อมูล" : "Inquiry Form"}
                </Link>
              </li>
              <li>
                <Link to={"Complaint"} className="dropdown-item">
                  {language === "T"
                    ? "ช่องทางรับเรื่องร้องเรียน"
                    : "Complaint Channel"}
                </Link>
              </li>
              <li>
                <Link to={"Email"} className="dropdown-item">
                  {language === "T" ? "รับข่าวสารทางอีเมล" : "E-Mail Alert"}
                </Link>
              </li>
              <li>
                <Link to={"FAQs"} className="dropdown-item">
                  {language === "T" ? "คำถามที่ถูกถามบ่อย" : "FAQs"}
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <div
              className="nav-link text-bold"
              onClick={() => changeLanguage()}
            >
              {language === "T" ? "EN" : "TH"}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
