import { createContext, useContext, useEffect, useMemo, useState } from "react";

const AppContextSign = createContext();

const AppProvider = ({ children }) => {
  let newLang = localStorage.getItem("language");
  const [language, setLanguage] = useState(newLang ? newLang : "T");

  useEffect(() => {
    const newLanguage = localStorage.getItem("language");
    if (newLanguage) {
      setLanguage(newLanguage);
    }
  }, []); // ใช้งานค่า newLanguage เมื่อคอมโพเนนต์โหลดครั้งแรก

  // useEffect(() => {
  //   localStorage.setItem("language", language);
  // }, [language]);

  function changeLanguage() {
    if (language === "T") {
      setLanguage("E");
      localStorage.setItem("language", "E");
    } else {
      setLanguage("T");
      localStorage.setItem("language", "T");
    }
  }
  const contextValue = useMemo(
    () => ({ language, changeLanguage }),
    [language, changeLanguage]
  );

  return (
    <AppContextSign.Provider value={contextValue}>
      {children}
    </AppContextSign.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContextSign);
};

export { AppProvider, useGlobalContext };
