import React from "react";
import {
  logo,
  IconMission1,
  IconMission2,
  IconMission3,
  IconMission4,
} from "../../assets/Logo";
import { Vision1 } from "../../assets/Image";
import { useGlobalContext } from "../../context/Context";

export default function Vision() {
  const { language } = useGlobalContext();
  return (
    <section className="mt-36 mb-8 lg:mt-44 container mx-auto px-4">
      {language === "T" ? (
        <>
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-3xl  text-[#1b3a91] font-bold">
                วิสัยทัศน์ และ พันธกิจ
              </h1>
            </div>
          </div>
          <div className="container-I">
            <div className="row">
              <div className="box-line-2" style={{ marginBottom: 100 }}>
                <img src={logo} className="vision-pic" />
                <div className="page-header2 text-center">
                  <p>วิสัยทัศน์</p>
                </div>
                <p className="text-skn-standard-vision">
                  บริษัทมุ่งมั่นที่จะเป็นหนึ่งในผู้นำทางด้านการผลิตวัสดุทดแทนไม้ธรรมชาติในภูมิภาคเอเชียตะวันออกเฉียงใต้
                  <br />
                  โดยเน้นสร้างคุณค่าสูงสุดให้แก่ลูกค้าและผู้มีส่วนเกี่ยวข้องทุกฝ่ายภายใต้การบริหารที่ยึดหลักธรรมาภิบาลที่ดี
                  <br />
                  และมาตรฐานการผลิตที่มีคุณภาพสู่ความยั่งยืนของธรรมชาติ
                  สิ่งแวดล้อม และสังคม
                </p>
                <img src={Vision1} className="vision-pic-1" />
                <div className="bg-vision-sec2">
                  <div className="page-header2 text-center">
                    <p>พันธกิจ</p>
                  </div>
                  <p className="text-skn-standard-vision">
                    เพื่อให้บรรลุวิสัยทัศน์ของบริษัท บริษัทมีพันธกิจ ดังนี้
                  </p>
                  <div className="grid-box">
                    <div className="icon-box-vision">
                      <div className="icon-box-vision-g">
                        <img src={IconMission1} className="vision-pic-icon" />
                        <p className="text-icon">
                          ผลิตสินค้าที่มีคุณภาพภายใต้มาตรฐาน
                          <br />
                          การผลิตระดับสากล
                        </p>
                      </div>
                      <div className="icon-box-vision-g">
                        <img src={IconMission2} className="vision-pic-icon" />
                        <p className="text-icon">
                          มุ่งพัฒนาผลิตภัณฑ์ที่เน้นการเพิ่มมูลค่า
                          <br />
                          ให้แก่ผลิตภัณฑ์วัสดุทดแทนธรรมชาติ
                        </p>
                      </div>
                      <div className="icon-box-vision-g">
                        <img src={IconMission3} className="vision-pic-icon" />
                        <p className="text-icon">
                          ตอบสนองความพึงพอใจสูงสุด
                          <br />
                          ของลูกค้าในทุกๆ ด้าน
                        </p>
                      </div>
                      <div className="icon-box-vision-g">
                        <img src={IconMission4} className="vision-pic-icon" />
                        <p className="text-icon">
                          ส่งเสริมการกำกับดูแลกิจการที่ดี
                          พัฒนาบุคลากรให้มีความรู้
                          <br />
                          คุณธรรม และจริยธรรม ตลอดจนความรับผิดชอบต่อสังคมและ{" "}
                          <br />
                          สิ่งแวดล้อม รวมถึงผู้มีส่วนเกี่ยวข้องทุกฝ่าย
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <img src={Vision1} className="vision-pic-1" />
                <div className="page-header2 text-center">
                  <p>กลยุทธ์ในการดำเนินธุรกิจ</p>
                </div>
                <p className="text-skn-standard-vision">
                  ด้วยประสบการณ์และความเชี่ยวชาญในอุตสาหกรรมแปรรูปไม้มายาวนานกว่า
                  30 ปี จึงเข้าใจเกี่ยวกับผลิตภัณฑ์แปรรูปแผ่นไม้เป็นอย่างดี
                  โดยการต่อยอดจากทรัพยากรไม้ที่มีด้วยการเพิ่มมูลค่าจากเศษไม้มาแปรรูปผลิตเป็นแผ่นไม้ธรรมชาติ
                  (Wood-based Panel)
                  โดยบริษัทเลือกใช้เครื่องจักรใหม่เป็นเทคโนโลยีที่ทันสมัยในการผลิตและน
                  าเข้าจากประเทศเยอรมนี
                  มีการควบคุมกระบวนการผลิตอย่างเข้มงวดทุกขั้นตอน
                  ซึ่งบริษัทดำเนินงานภายใต้นโยบายที่มุ่งสร้างผลิตภัณฑ์ที่มีคุณภาพจากมาตรฐานการผลิตระดับสากล
                  และมุ่งเน้นการพัฒนากระบวนการผลิตให้มีระยะเวลาที่สั้น
                  ควบคุมปริมาณการใช้วัตถุดิบให้มีประสิทธิภาพสูงสุด
                  เพื่อให้ได้ผลิตภัณฑ์แผ่นไม้เอ็มดีเอฟที่มีคุณภาพ
                  ตอบสนองตามความต้องการของลูกค้าทั้งในและต่างประเทศ นอกจากนี้
                  บริษัทยังส่งเสริมการพัฒนาให้บุคลากรมีความรู้ คุณธรรม
                  และจริยธรรม ตลอดจนความรับผิดชอบต่อสังคมและสิ่งแวดล้อม
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-3xl  text-[#1b3a91] font-bold">
                Vision and Mission
              </h1>
            </div>
          </div>
          <div className="container-I">
            <div className="row">
              <div className="box-line-2" style={{ marginBottom: 100 }}>
                <img src={logo} className="vision-pic" />
                <div className="page-header2 text-center">
                  <p>Vision</p>
                </div>
                <p className="text-skn-standard-vision">
                  The organization is determined to be one of leaders in
                  manufacturer of wood-based panel in Southeast Asia. The
                  company aimed to create the highest value to
                  <br />
                  customer and related parties under good governance management
                  principle.
                  <br />
                  Additionally, the aim is for to obtain production standard
                  that is high in quality for the sustainability of nature,
                  environment, and society.
                </p>
                <img src={Vision1} className="vision-pic-1" />
                <div className="bg-vision-sec2">
                  <div className="page-header2 text-center">
                    <p>Mission</p>
                  </div>
                  <p className="text-skn-standard-vision">
                    To achieve vision of the organization, the company have
                    mission as follow:
                  </p>
                  <div className="grid-box">
                    <div className="icon-box-vision">
                      <div className="icon-box-vision-g">
                        <img src={IconMission1} className="vision-pic-icon" />
                        <p className="text-icon">
                          Manufacture quality products under based
                          <br />
                          on International for standardization
                        </p>
                      </div>
                      <div className="icon-box-vision-g">
                        <img src={IconMission2} className="vision-pic-icon" />
                        <p className="text-icon">
                          Aim to develop products focusing on added-value
                          <br />
                          to natural substitution materials
                        </p>
                      </div>
                      <div className="icon-box-vision-g">
                        <img src={IconMission3} className="vision-pic-icon" />
                        <p className="text-icon">
                          Respond to the highest customer
                          <br />
                          satisfaction in all aspects
                        </p>
                      </div>
                      <div className="icon-box-vision-g">
                        <img src={IconMission4} className="vision-pic-icon" />
                        <p className="text-icon">
                          Promote good management and business operation,
                          <br />
                          develop employee to obtain moral and
                          <br />
                          ethical principle as well as responsibility to society{" "}
                          <br />
                          and environment as well as all related parties.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <img src={Vision1} className="vision-pic-1" />
                <div className="page-header2 text-center">
                  <p>Business operational strategies</p>
                </div>
                <p className="text-skn-standard-vision">
                  With over 30 years of experiences and expertise in wood
                  processed industry, the organization possesses an in-depth
                  understanding of wood processed product. The business begins
                  to expand by using wood resource and added-value from wood
                  bits to wood-based panel. The company use new machine with
                  advance technology for the manufacture process and imported
                  directly from Germany. There is a strict manufacture quality
                  control in every process. The company operates under policy
                  that focuses in creating quality products from international
                  standards. The company also determine to develop manufacture
                  process to be shorter and control the amount used of raw
                  materials to be utmost efficient. This is aim to achievehigh
                  quality MDF board that responds to needs of domestic and
                  international clients. Moreover, the company also promote the
                  development of employees to obtain knowledge, moral and
                  ethical principles as well as responsibility toward society
                  and environment.
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
}
