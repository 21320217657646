import React from "react";
import { useGlobalContext } from "../../context/Context";
import { Header } from "../../Components";

export default function Press() {
  const { language } = useGlobalContext();

  return (
    <div className="container-page">
      <div className="container-top">
        <Header nameTH={"ข่าวเผยแพร่"} nameEN={"Press Releases"} />
        <div className="container-not-data">
          <div className="container-main">
            <div className="row-main">
              <div className="col-main">
                <div className="flex-main-center">
                  <div className="text-not-data">
                    {language === "T"
                      ? "ไม่มีข้อมูล ณ ขณะนี้"
                      : "No Information Now"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
