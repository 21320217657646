import React from "react";
import { useGlobalContext } from "../../context/Context";

export default function Header({ nameTH, nameEN }) {
  const { language } = useGlobalContext();
  return (
    <div className="container-main">
      <div className="row-main">
        <div className="col-main">
          <div className="layout-text-head">
            <p className="t1">Welcome To S.Kijchai</p>
            <p className="t2">{language === "T" ? nameTH : nameEN}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
