import React from "react";
import { logo } from "../../../assets/Logo";
import { ImgPr12 } from "../../../assets/Image";

export default function RelationDetail1() {
  return (
    <>
      <div
        className="container-Detail"
        style={{ margin: "auto", marginTop: "10rem" }}
      >
        <div className="h-bg">
          {/* <div className="f-detail">
            <img src={logo} className="logo-Dbar"></img>
          </div> */}
        </div>
        <div className="row">
          <div className="box-line">
            <div className="about-text-cover text-center">
              <p className="t-1">Welcome To S.Kijchai</p>
              <p className="t-2">ห้องข่าวประชาสัมพันธ์</p>
            </div>
          </div>
        </div>
      </div>
      <div className="cover-board-D">
        <img src={ImgPr12} className="pic-RDetail"></img>
        <b style={{ margin: "auto" }}>
          SKN โชว์ฟอร์มแกร่งคว้ารางวัล Best Company Performance Award ในงาน SET
          Award 2022
        </b>
        <p className="text-d">
          นายหาญศิริ แสงวงศ์กิจ (ซ้าย) และ นางสาวอรวรรณ แสงวงศ์กิจ (ขวา)
          กรรมการบริหาร บริษัท ส.กิจชัย เอ็นเตอร์ไพรส์ จำกัด (มหาชน) หรือ SKN
          ผู้ผลิตและส่งออกแผ่นไม้เอ็มดีเอฟ โชว์ฟอร์มการบริหารงานสุดแกร่ง
          นำทีมคว้ารางวัล Best Company Performance Award กลุ่ม Business
          Excellence ในงาน ประกาศรางวัล SET Awards 2022
          ที่มอบให้กับบริษัทจดทะเบียนที่มีความยอดเยี่ยมและการบริหารงานที่โดดเด่น
          นับเป็นความสำเร็จส่งท้ายปีของ SKN ที่
          ตลท.ได้เห็นถึงศักยภาพในการมุ่งมั่นพัฒนาผลิตภัณฑ์และเดินหน้าบริหารงานสร้างความเติบโตได้อย่างแข็งแกร่งและยั่งยืน
          ณ ตลาดหลักทรัพย์แห่งประเทศไทย (ตลท.) เมื่อเร็วๆ นี้
        </p>
        <p style={{ margin: "auto" }}>************************************</p>
        <div className="contact-RDetail">
          <b style={{ color: "black" }}>ติดต่อฝ่ายประชาสัมพันธ์ IR PLUS</b>
          <div className="linkD">
            <p>
              คุณสารภี สายะเวส (จูน) โทร : 02-022-6200 ต่อ 610, 081-854-8170
              email :
            </p>
            <a
              style={{ marginTop: 16, gap: 1 }}
              href="mailto:sarapee@irplus.in.th"
            >
              sarapee@irplus.in.th
            </a>
          </div>
          <div className="linkD">
            <p>
              คุณชนนาถ ไตรทรัพย์ (ฟ้า) โทร: 02-022-6200 ต่อ 614, 095-249-3666
              email :
            </p>
            <a
              style={{ marginTop: 16, gap: 1 }}
              href="mailto:chonnanart@irplus.in.th"
            >
              chonnanart@irplus.in.th
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
