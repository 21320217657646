import React from "react";
import { PiDownloadSimpleFill } from "react-icons/pi";
import {
  investorBook2022,
  investorBook2023,
  investorBook2023E,
} from "../../../assets/Image";
import { useGlobalContext } from "../../../context/Context";
import { Link } from "react-router-dom";

export default function InvestorKits() {
  const { language } = useGlobalContext();

  return (
    <div className="container-content-financial">
      {language === "T" ? (
        <div className="container-main">
          <div className="content-row">
            <div className="main-col">
              <p className="ir-head">Investor Kits</p>
            </div>
            <div className="main-col">
              <p className="ir-sub">
                นี้คือส่วนของการรวบรวมข้อมูลสำคัญสำหรับนักลงทุน
                <br />
                เพื่อให้ท่านสามารถจัดหาเอกสารที่ต้องการศึกษาได้โดยง่ายและ สะดวก
              </p>
            </div>
          </div>
          <div className="main-col-kits">
            <div className="content-row margin-top">
              <div className="col-25">
                <div className="cover-download-doc">
                  <div className="head-box-ir-kits">
                    <div className="line-head-box-ir-kits" />
                    <p>Investor Kits</p>
                  </div>
                  <div className="download-doc">
                    <div className="content-row">
                      <div className="main-col-kits">
                        <div className="doc">
                          <p>สรุปข้อสนเทศบริษัท</p>
                          {/* href="" */}
                          <a>
                            <p className="t1">
                              <PiDownloadSimpleFill />
                              ดาวน์โหลด
                            </p>
                          </a>
                        </div>
                      </div>
                      <div className="main-col-kits">
                        <div className="doc">
                          <p>ความรับผิดชอบต่อสังคม</p>
                          <Link to={"/pdf/CSR_T.pdf"} target="_blank" download>
                            <p className="t1">
                              <PiDownloadSimpleFill />
                              ดาวน์โหลด
                            </p>
                          </Link>
                        </div>
                      </div>
                      <div className="main-col-kits">
                        <div className="doc">
                          <p>การขับเคลื่อนธุรกิจเพื่อความยั่งยืน</p>
                          <Link to={"/pdf/CSR.pdf"} target="_blank" download>
                            {/* <a href="pdf/CSR.pdf" download> */}
                            <p className="t1">
                              <PiDownloadSimpleFill />
                              ดาวน์โหลด
                            </p>
                            {/* </a> */}
                          </Link>
                        </div>
                      </div>
                      <div className="main-col-kits">
                        <div className="doc">
                          <p>ข้อมูลนำเสนอต่อนักลงทุน</p>
                          <a href="https://market.sec.or.th/public/ipos/IPOSEQ01.aspx?TransID=125342">
                            <p className="t1">
                              <PiDownloadSimpleFill />
                              ดาวน์โหลด
                            </p>
                          </a>
                        </div>
                      </div>
                      <div className="main-col-kits">
                        <div className="doc">
                          <p>การกำกับดูแลกิจการที่ดี</p>
                          <a
                            href="pdf/corporate_T.pdf"
                            className="text-ir-between"
                            download
                          >
                            <p className="t1">
                              <PiDownloadSimpleFill />
                              ดาวน์โหลด
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-75">
                <div className="content-row-1">
                  <div className="main-col-box-ir">
                    <div className="cover-kits cover-kits-1">
                      <a href="zip/T/q2_2567_T.zip" download>
                        <div className="kits-head">
                          <div className="line" />
                          <p>Investor Kits</p>
                        </div>
                        <p className="t1">
                          Financial
                          <br />
                          Statement
                        </p>
                        <p className="t2">
                          <PiDownloadSimpleFill />
                          ดาวน์โหลด
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="main-col-box-ir">
                    <div className="cover-kits cover-kits-2">
                      <Link
                        to={"/pdf/analysis/Company_SKN_301122.pdf"}
                        target="_blank"
                      >
                        <div className="kits-head">
                          <div className="line" />
                          <p>Investor Kits</p>
                        </div>
                        <p className="t1">
                          Analyst
                          <br />
                          Report
                        </p>
                        <p className="t2">
                          <PiDownloadSimpleFill />
                          ดาวน์โหลด
                        </p>
                      </Link>
                    </div>
                  </div>
                  <div className="main-col-box-ir">
                    <div className="cover-kits cover-kits-3">
                      <Link to={"/pdf/STRUCTURESKNT.pdf"} target="_blank">
                        <div className="kits-head">
                          <div className="line" />
                          <p>Investor Kits</p>
                        </div>
                        <p className="t1">
                          Form
                          <br />
                          56-1
                        </p>
                        <p className="t2">
                          <PiDownloadSimpleFill />
                          ดาวน์โหลด
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="content-row-1 switch-seq">
                  <div className="main-col-box-ir">
                    <div className="cover-kits cover-kits-4">
                      <a href="pdf/mda/MD&A_2024_T.pdf" download>
                        <div>
                          <div className="kits-head">
                            <div className="line" />
                            <p>Investor Kits</p>
                          </div>
                          <p className="t1 add-margin">
                            MD&A
                            <br />
                          </p>
                          <p className="t2">
                            <PiDownloadSimpleFill />
                            ดาวน์โหลด
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="main-col-box-ir large">
                    <div className="cover-kits cover-kits-5">
                      <div className="kits-head">
                        <div className="line" />
                        <p>Investor Kits</p>
                      </div>
                      <div className="main-book-an-pic">
                        <img src={investorBook2023} className="book-an-pic" />
                      </div>
                      <div className="book-an-text">
                        <p className="t1">รายงานประจำปี 2566</p>
                        <p className="t2">
                          รายงานประจำปีเป็นข้อมูลนำเสนอผลสำเร็จของการดำเนินงานของบริษัทเพื่อให้นักลงทุนได้รับทราบถึงข้อมูลของบริษัทฯ
                        </p>
                        <Link to={"/pdf/an_SKN_2023.pdf"} target="_blank">
                          <p className="t3">
                            <PiDownloadSimpleFill />
                            ดาวน์โหลด
                          </p>
                        </Link>
                        <Link
                          to={"/AnnualReport"}
                          target="_blank"
                          className="read-more"
                        >
                          {`>>`} เพิ่มเติม
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-main">
          <div className="content-row">
            <div className="main-col">
              <p className="ir-head">Investor Kits</p>
            </div>
            <div className="main-col">
              <p className="ir-sub">
                This is a part of important information gathering for investors.
                <br />
                You can easily find the documents you need.
              </p>
            </div>
          </div>
          <div className="main-col-kits">
            <div className="content-row margin-top">
              <div className="col-25">
                <div className="cover-download-doc">
                  <div className="head-box-ir-kits">
                    <div className="line-head-box-ir-kits" />
                    <p>Investor Kits</p>
                  </div>
                  <div className="download-doc">
                    <div className="content-row">
                      {/* <div className="main-col-kits">
                        <div className="doc">
                          <p>Factsheet</p>
                          <a href="">
                            <p className="t1">
                              <PiDownloadSimpleFill />
                              Download
                            </p>
                          </a>
                        </div>
                      </div>*/}
                      <div className="main-col-kits">
                        <div className="doc">
                          <p>CSR & Activities </p>
                          <Link to={"/pdf/CSR_E.pdf"} target="_blank" download>
                            <p className="t1">
                              <PiDownloadSimpleFill />
                              Download
                            </p>
                          </Link>
                        </div>
                      </div>
                      <div className="main-col-kits">
                        <div className="doc">
                          <p>Driving Business for Sustainability</p>
                          <Link to={"/pdf/CSR.pdf"} target="_blank" download>
                            {/* <a href="pdf/CSR.pdf" download> */}
                            <p className="t1">
                              <PiDownloadSimpleFill />
                              Download
                            </p>
                            {/* </a> */}
                          </Link>
                        </div>
                      </div>
                      {/* <div className="main-col-kits">
                        <div className="doc">
                          <p>Webcast & Presentation</p>
                          <a href="https://market.sec.or.th/public/ipos/IPOSEQ01.aspx?TransID=125342">
                            <p className="t1">
                              <PiDownloadSimpleFill />
                              Download
                            </p>
                          </a>
                        </div>
                      </div> */}
                      <div className="main-col-kits">
                        <div className="doc">
                          <p>Corporate Governance</p>
                          <a
                            href="pdf/corporate_E.pdf"
                            className="text-ir-between"
                            download
                          >
                            <p className="t1">
                              <PiDownloadSimpleFill />
                              Download
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-75">
                <div className="content-row-1">
                  <div className="main-col-box-ir">
                    <div className="cover-kits cover-kits-1">
                      <a href="zip/E/q2_2567_E.zip" download>
                        <div className="kits-head">
                          <div className="line" />
                          <p>Investor Kits</p>
                        </div>
                        <p className="t1">
                          Financial
                          <br />
                          Statement
                        </p>
                        <p className="t2">
                          <PiDownloadSimpleFill />
                          Download
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="main-col-box-ir">
                    <div className="cover-kits cover-kits-2">
                      <Link
                        to={"/pdf/analysis/Company_SKN_301122.pdf"}
                        target="_blank"
                      >
                        <div className="kits-head">
                          <div className="line" />
                          <p>Investor Kits</p>
                        </div>
                        <p className="t1">
                          Analyst
                          <br />
                          Report
                        </p>
                        <p className="t2">
                          <PiDownloadSimpleFill />
                          Download
                        </p>
                      </Link>
                    </div>
                  </div>
                  <div className="main-col-box-ir">
                    <div className="cover-kits cover-kits-3">
                      <Link to={"/pdf/STRUCTURESKNE.pdf"} target="_blank">
                        <div className="kits-head">
                          <div className="line" />
                          <p>Investor Kits</p>
                        </div>
                        <p className="t1">
                          Form
                          <br />
                          56-1
                        </p>
                        <p className="t2">
                          <PiDownloadSimpleFill />
                          Download
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="content-row-1 switch-seq">
                  <div className="main-col-box-ir">
                    <div className="cover-kits cover-kits-4">
                      <a href="pdf/mda/MD&A_2024_E.pdf" download>
                        <div>
                          <div className="kits-head">
                            <div className="line" />
                            <p>Investor Kits</p>
                          </div>
                          <p className="t1 add-margin">
                            MD&A
                            <br />
                          </p>
                          <p className="t2">
                            <PiDownloadSimpleFill />
                            Download
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="main-col-box-ir large">
                    <div className="cover-kits cover-kits-5">
                      <div className="kits-head">
                        <div className="line" />
                        <p>Investor Kits</p>
                      </div>
                      <div className="main-book-an-pic">
                        <img src={investorBook2023E} className="book-an-pic" />
                      </div>
                      <div className="book-an-text">
                        <p className="t1">Annual Report 2023</p>
                        <p className="t2">
                          This Annual Report is a presentation of the success of
                          the company's operation plan to provide investors.
                        </p>
                        <Link to={"/pdf/an_SKN_2023_E.pdf"} target="_blank">
                          <p className="t3">
                            <PiDownloadSimpleFill />
                            Download
                          </p>
                        </Link>
                        <Link
                          to={"/AnnualReport"}
                          target="_blank"
                          className="read-more"
                        >
                          {`>>`} Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
