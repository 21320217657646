import React, { useState } from "react";
import { MultiMediaData, MultiMediaDataEn } from "../../../data/MultiMediaData";
import Pagination from "./Pagination";
import { useGlobalContext } from "../../../context/Context";
import { Link } from "react-router-dom";

export default function Multimedia() {
  const { language, changeLanguage } = useGlobalContext();
  let pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastRecord = currentPage * pageSize;
  const indexOfFirstRecord = indexOfLastRecord - pageSize;

  let currentRecord;
  let nPages;
  if (language === "T") {
    currentRecord = MultiMediaData.slice(indexOfFirstRecord, indexOfLastRecord);
    nPages = Math.ceil(MultiMediaData.length / pageSize);
  } else if (language === "E") {
    currentRecord = MultiMediaDataEn.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    nPages = Math.ceil(MultiMediaDataEn.length / pageSize);
  }

  return (
    <section className="mt-36 mb-8 lg:mt-44 container mx-auto px-4">
      {language === "T" ? (
        <>
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-3xl  text-[#1b3a91] font-bold">
                ข้อมูลนำเสนอแบบมัลติมีเดีย
              </h1>
            </div>
          </div>
          <div
            class="relative overflow-x-auto"
            style={{ margin: "5rem 0 2rem" }}
          >
            <table class="w-full table-respon mt-4 text-sm text-center text-white">
              <thead class="text-md text-white bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                <tr>
                  <th scope="col" class="px-6 py-4 text-left">
                    กิจกรรม
                  </th>
                  <th scope="col" class="px-6 py-4 text-left">
                    ดูเว็บแคสต์
                  </th>
                  <th scope="col" class="px-6 py-4 text-left">
                    ดาวน์โหลด
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentRecord.map((item) => {
                  return (
                    <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base">
                      <td
                        scope="row"
                        class="px-6 py-4 text-[#1b3a91] font-medium text-left"
                      >
                        {item.activity}
                      </td>
                      <td
                        scope="row"
                        class="px-6 py-4 text-[#1b3a91] font-medium text-left"
                      >
                        {item.youtubeLink ? (
                          <a
                            href={item.youtubeLink}
                            target="_blank"
                            style={{
                              display: "flex",
                            }}
                          >
                            <svg
                              class="w-5 h-5 text-blue-800 dark:text-white pt-1 pe-1"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 14 16"
                            >
                              <path d="M0 .984v14.032a1 1 0 0 0 1.506.845l12.006-7.016a.974.974 0 0 0 0-1.69L1.506.139A1 1 0 0 0 0 .984Z" />
                            </svg>
                            ดูเว็บแคสต์
                          </a>
                        ) : (
                          <p></p>
                        )}
                      </td>

                      <td
                        scope="row"
                        class="px-6 py-4 text-[#1b3a91] font-medium text-left"
                      >
                        {item.file ? (
                          <Link
                            to={item.file}
                            target="_blank"
                            style={{
                              display: "flex",
                            }}
                          >
                            <svg
                              class="w-5 h-5 text-blue-800 dark:text-white pe-1"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 16 20"
                            >
                              <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2Zm-3 15H4.828a1 1 0 0 1 0-2h6.238a1 1 0 0 1 0 2Zm0-4H4.828a1 1 0 0 1 0-2h6.238a1 1 0 1 1 0 2Z" />
                              <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                            </svg>
                            ดาวน์โหลด
                          </Link>
                        ) : (
                          <p></p>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      ) : (
        <>
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-3xl  text-[#1b3a91] font-bold">
                Webcast & Presentation
              </h1>
            </div>
          </div>
          <div
            class="relative overflow-x-auto"
            style={{ margin: "5rem 0 2rem" }}
          >
            <table class="w-full table-respon mt-4 text-sm text-center text-white">
              <thead class="text-md text-white bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                <tr>
                  <th scope="col" class="px-6 py-4 text-left">
                    Current Event
                  </th>
                  <th scope="col" class="px-6 py-4 text-left">
                    View Webcast
                  </th>
                  <th scope="col" class="px-6 py-4 text-left">
                    Download
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentRecord.map((item) => {
                  return (
                    <tr class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base">
                      <td
                        scope="row"
                        class="px-6 py-4 text-[#1b3a91] font-medium text-left"
                      >
                        {item.activity}
                      </td>
                      <td
                        scope="row"
                        class="px-6 py-4 text-[#1b3a91] font-medium text-left"
                      >
                        {item.youtubeLink ? (
                          <a
                            href={item.youtubeLink}
                            target="_blank"
                            style={{
                              display: "flex",
                            }}
                          >
                            <svg
                              class="w-5 h-5 text-blue-800 dark:text-white pt-1 pe-1"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 14 16"
                            >
                              <path d="M0 .984v14.032a1 1 0 0 0 1.506.845l12.006-7.016a.974.974 0 0 0 0-1.69L1.506.139A1 1 0 0 0 0 .984Z" />
                            </svg>
                            View Webcast
                          </a>
                        ) : (
                          <p></p>
                        )}
                      </td>

                      <td
                        scope="row"
                        class="px-6 py-4 text-[#1b3a91] font-medium text-left"
                      >
                        {item.file ? (
                          <Link
                            to={item.file}
                            target="_blank"
                            style={{
                              display: "flex",
                            }}
                          >
                            <svg
                              class="w-5 h-5 text-blue-800 dark:text-white pe-1"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 16 20"
                            >
                              <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2Zm-3 15H4.828a1 1 0 0 1 0-2h6.238a1 1 0 0 1 0 2Zm0-4H4.828a1 1 0 0 1 0-2h6.238a1 1 0 1 1 0 2Z" />
                              <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                            </svg>
                            Download
                          </Link>
                        ) : (
                          <p></p>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      )}
    </section>
  );
}
