import React from "react";
import { logo } from "../../assets/Logo";
import { useGlobalContext } from "../../context/Context";
import { Link } from "react-router-dom";

export default function Footer() {
  const { language } = useGlobalContext();

  const date = new Date();
  const year = date.getFullYear();

  return language === "T" ? (
    <div className="container-footer">
      <div className="footer-bg" />
      <div className="content-footer">
        <div className="content-footer-row">
          <div className="col-footer">
            <div className="footer-content">
              <div className="content-footer-row">
                <div className="col-25-footer">
                  <div className="main-footer-about">
                    <div className="footer-logo-container">
                      <Link to={"/"} className="">
                        <div className="footer-logo-text">
                          <img src={logo} />
                        </div>
                      </Link>
                    </div>
                    <div className="footer-about-text">
                      <p>
                        การบริหารที่ยึดหลักธรรมาภิบาลที่ดี
                        <br />
                        และมาตรฐานการผลิตที่มีคุณภาพ
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-25-footer">
                  <div className="main-footer-contact">
                    <div className="footer-title">ติดต่อเรา</div>
                    <div className="footer-contact-info">
                      <ul>
                        <li>
                          Email:{" "}
                          <a href="mailto: skn@kijchai.co.th">
                            skn@kijchai.co.th
                          </a>
                        </li>
                        <li>Phone: 038 928 188</li>
                        <li>99/9 หมู่ 7 ต.ห้วยยาง อ.แกลง จ.ระยอง 21110.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-50-footer">
                  <div className="main-footer-link">
                    <div className="footer-title">แผนผังเว็บไซต์</div>
                    <div className="footer-link-container">
                      <div className="footer-link">
                        <ul>
                          <li>
                            <Link to={"/"} className="black-gray">
                              หน้าหลัก
                            </Link>
                          </li>
                          <li>
                            <Link to={"#"} className="black-gray">
                              เกี่ยวกับเรา
                            </Link>
                            <ul className="sub">
                              <li>
                                <Link to={"Chairman"} className="black-gray">
                                  สารจากประธานกรรมการบริษัท
                                </Link>
                              </li>
                              <li>
                                <Link to={"History"} className="black-gray">
                                  ประวัติความเป็นมา
                                </Link>
                              </li>
                              <li>
                                <Link to={"Vision"} className="black-gray">
                                  วิสัยทัศน์ และ พันธกิจ
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={"Organization"}
                                  className="black-gray"
                                >
                                  โครงสร้างองค์กร
                                </Link>
                              </li>
                              <li>
                                <Link to={"Board"} className="black-gray">
                                  คณะกรรมการบริษัทและผู้บริหาร
                                </Link>
                              </li>
                              <li>
                                <Link to={"Awards"} className="black-gray">
                                  รางวัลและการรับรอง
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to={"#"} className="black-gray">
                              ข่าวสารและกิจกรรม
                            </Link>
                            <ul className="sub">
                              <li>
                                <Link to={"Set"} className="black-gray">
                                  ประกาศจากตลาดหลักทรัพย์
                                </Link>
                              </li>
                              <li>
                                <Link to={"NewsUpdate"} className="black-gray">
                                  ข่าวสารล่าสุด
                                </Link>
                              </li>
                              <li>
                                <Link to={"Public"} className="black-gray">
                                  ห้องข่าวประชาสัมพันธ์
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={"NewsClipping"}
                                  className="black-gray"
                                >
                                  ข่าวจากหนังสือพิมพ์
                                </Link>
                              </li>
                              <li>
                                <Link to={"Press"} className="black-gray">
                                  ข่าวเผยแพร่
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to={"#"} className="black-gray">
                              การพัฒนาสู่ความยั่งยืน
                            </Link>
                            <ul className="sub">
                              <li>
                                <Link to={"Corporate"} className="black-gray">
                                  การกำกับดูแลกิจการ
                                </Link>
                              </li>
                              <li>
                                <Link to={"Anti"} className="black-gray">
                                  การต่อต้านทุจริตและคอร์รัปชั่น
                                </Link>
                              </li>
                              <li>
                                <Link to={"Csr"} className="black-gray">
                                  ความรับผิดชอบต่อสังคมและกิจกรรมต่างๆ
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to={"Investor"} className="black-gray">
                              นักลงทุนสัมพันธ์
                            </Link>
                          </li>
                          <li>
                            <Link to={"Contact"} className="black-gray">
                              ติดต่อเรา
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-fluid">
        <div className="tab-color-fluid">
          <div className="col-fluid">
            <div className="layout-fluid">
              <div className="text-fluid">
                © {year} All rights S.Kijchai Enterprise PCL. |
                <Link to={"CookiePolicy"} className="a-cookies-policy">
                  {" "}
                  Cookies Notice{" "}
                </Link>
                |
                <Link to={"PrivacyPolicy"} className="a-cookies-policy">
                  {" "}
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="container-footer">
      <div className="footer-bg" />
      <div className="content-footer">
        <div className="content-footer-row">
          <div className="col-footer">
            <div className="footer-content">
              <div className="content-footer-row">
                <div className="col-25-footer">
                  <div className="main-footer-about">
                    <div className="footer-logo-container">
                      <Link to={"/"} className="">
                        <div className="footer-logo-text">
                          <img src={logo} />
                        </div>
                      </Link>
                    </div>
                    <div className="footer-about-text">
                      <p>
                        We operate the business based on good governance
                        <br />
                        principles and quality production standards.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-25-footer">
                  <div className="main-footer-contact">
                    <div className="footer-title">Contact Us.</div>
                    <div className="footer-contact-info">
                      <ul>
                        <li>
                          Email:{" "}
                          <a href="mailto: skn@kijchai.co.th">
                            skn@kijchai.co.th
                          </a>
                        </li>
                        <li>Phone: 038 928 188</li>
                        <li>
                          99/9 Moo 7, Huayyang Subdistrict, Klaeng District,
                          Rayong Province 21110.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-50-footer">
                  <div className="main-footer-link">
                    <div className="footer-title">Site Map</div>
                    <div className="footer-link-container">
                      <div className="footer-link">
                        <ul>
                          <li>
                            <Link to={"/"} className="black-gray">
                              Home
                            </Link>
                          </li>
                          <li>
                            <Link to={"#"} className="black-gray">
                              About Us
                            </Link>
                            <ul className="sub">
                              <li>
                                <Link to={"Chairman"} className="black-gray">
                                  Chairman’s Statement
                                </Link>
                              </li>
                              <li>
                                <Link to={"History"} className="black-gray">
                                  History
                                </Link>
                              </li>
                              <li>
                                <Link to={"Vision"} className="black-gray">
                                  Vision and Mission
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={"Organization"}
                                  className="black-gray"
                                >
                                  Organization Chart
                                </Link>
                              </li>
                              <li>
                                <Link to={"Board"} className="black-gray">
                                  Board of Director & Management Team
                                </Link>
                              </li>
                              <li>
                                <Link to={"Awards"} className="black-gray">
                                  Awards and certifications
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to={"#"} className="black-gray">
                              News & Activities
                            </Link>
                            <ul className="sub">
                              <li>
                                <Link to={"Set"} className="black-gray">
                                  SET Announcement
                                </Link>
                              </li>
                              <li>
                                <Link to={"NewsUpdate"} className="black-gray">
                                  News Update
                                </Link>
                              </li>
                              <li>
                                <Link to={"Public"} className="black-gray">
                                  Public Relation
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={"NewsClipping"}
                                  className="black-gray"
                                >
                                  News Clipping
                                </Link>
                              </li>
                              <li>
                                <Link to={"Press"} className="black-gray">
                                  Press Releases
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to={"#"} className="black-gray">
                              Sustainability
                            </Link>
                            <ul className="sub">
                              <li>
                                <Link to={"Corporate"} className="black-gray">
                                  Corporate Governance
                                </Link>
                              </li>
                              <li>
                                <Link to={"Anti"} className="black-gray">
                                  Anti-corruption
                                </Link>
                              </li>
                              <li>
                                <Link to={"Csr"} className="black-gray">
                                  CSR & Activities
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to={"Investor"} className="black-gray">
                              Investor Relations
                            </Link>
                          </li>
                          <li>
                            <Link to={"Contact"} className="black-gray">
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-fluid">
        <div className="tab-color-fluid">
          <div className="col-fluid">
            <div className="layout-fluid">
              <div className="text-fluid">
                © {year} All rights S.Kijchai Enterprise PCL. |
                <Link to={"CookiePolicy"} className="a-cookies-policy">
                  {" "}
                  Cookies Notice{" "}
                </Link>
                |
                <Link to={"PrivacyPolicy"} className="a-cookies-policy">
                  {" "}
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
