export const categoryTh = [
  {
    name: "ข้อมูลส่วนบุคคล",
  },
  {
    name: "การร้องเรียนเกี่ยวกับจรรยาบรรณทางธุรกิจ",
  },
  {
    name: "การร้องเรียนเกี่ยวกับสิทธิมนุษยชน",
  },
  {
    name: "การเลือกปฏิบัติ",
  },
  {
    name: "การล่วงละเมิดหรือคุกคามทางเพศ",
  },
  {
    name: "การคุกคามที่ไม่มีนัยยะทางเพศ",
  },
  {
    name: "พบการทุจริต และ/หรือ ข้อสงสัย ภายในหน่วยงานของบริษัท",
  },
  {
    name: "พบการบกพร่องของเจ้าหน้าที่ และ/หรือ พนักงานบริษัท",
  },
  {
    name: "พบการดำเนินงาน/กิจกรรม ที่ไม่โปร่งใสต่อผู้ถือหุ้น",
  },
  {
    name: "แจ้งข้อร้องเรียนอื่นๆ",
  },
];

export const categoryEn = [
  {
    name: "Personal data privacy",
  },
  {
    name: "Code of Conduct",
  },
  {
    name: "Human Rights",
  },
  {
    name: "Discrimination",
  },
  {
    name: "Sexual harassment",
  },
  {
    name: "Non - sexual harassment",
  },
  {
    name: "Found fraud and/or concerns within the company.",
  },
  {
    name: "Found of defective of the officer and/or employee.",
  },
  {
    name: "Meet operations/ activities that are not transparent to shareholders.",
  },
  {
    name: "Other complaints.",
  },
];
