import React from "react";
import { dividendPolicyData } from "../../../mock/MockupDividendPolicy";
import { ImgDividendPolicy } from "../../../assets/Image";
import { Header } from "../../../Components";
import { useGlobalContext } from "../../../context/Context";

export default function DividendPolicy() {
  const { language } = useGlobalContext();

  return (
    <>
      <div className="sub-top-hight">
        <div className="container-I" style={{ marginBottom: 100 }}>
          <div className="row">
            <div className="box-line-c">
              <Header
                nameTH={"นโยบายจ่ายเงินปันผล"}
                nameEN={"Dividend Policy"}
              />
            </div>
          </div>

          <div class="container mt-4" style={{ margin: "auto" }}>
            <div class="container">
              {language === "T" ? (
                <div class="rows">
                  <div class="col-xl-12">
                    <div class="page-header text-center">
                      <p>ประวัติการจ่ายเงินปันผล</p>
                    </div>
                    <img
                      style={{
                        maxWidth: "80%",
                        margin: "auto",
                        marginBottom: 30,
                      }}
                      src={ImgDividendPolicy}
                      class="corporate-pic"
                    />
                  </div>
                  <div style={{ marginBottom: 50 }}>
                    <p class="text-skn-standard corporate-t1">
                      บริษัทมีนโยบายการจ่ายเงินปันผลให้แก่ผู้ถือหุ้นในอัตราไม่น้อยกว่าร้อยละ
                      40
                      ของกำไรสุทธิจากงบการเงินเฉพาะกิจการภายหลังจากการหักภาษีเงินได้นิติบุคคลและหักสำรองต่างๆ
                      ทุกประเภทตามที่กฎหมายกำหนด
                      และตามที่กำหนดไว้ในข้อบังคับของบริษัท อย่างไรก็ตาม
                      อัตราการจ่ายเงินปันผลตามนโยบายดังกล่าวอาจมีการเปลี่ยนแปลงได้
                      โดยจะขึ้นอยู่กับ ผลการดำเนินงาน ฐานะการเงิน สภาพคล่อง
                      โครงสร้างทางการเงิน
                      ความจำเป็นในการใช้เงินทุนหมุนเวียนในการดำเนินงาน
                      แผนการลงทุน การขยายธุรกิจในอนาคต สภาวะตลาด ความเหมาะสม
                      และปัจจัยอื่นๆ
                      ที่เกี่ยวข้องกับการดำเนินงานและการบริหารงานของบริษัท
                      ตลอดจนข้อกำหนดในสัญญาต่างๆ ที่บริษัทผูกพันอยู่
                      ตามที่คณะกรรมการบริษัทเห็นสมควร
                      โดยอยู่ภายใต้เงื่อนไขที่ว่า
                      บริษัทจะต้องมีเงินสดเพียงพอสำหรับการดำเนินธุรกิจ
                      และการดำเนินการดังกล่าวจะต้องก่อให้เกิดประโยชน์สูงสุดต่อผู้ถือหุ้น
                      ตามที่คณะกรรมการบริษัท และ/หรือ
                      ผู้ถือหุ้นของบริษัทเห็นสมควร
                    </p>
                    <p class="text-skn-standard corporate-t1 mt-5">
                      ทั้งนี้
                      มติของคณะกรรมการบริษัทที่อนุมัติให้จ่ายเงินปันผลจะต้องถูกนำเสนอเพื่อขออนุมัติต่อที่ประชุมผู้ถือหุ้น
                      เว้นแต่เป็นการจ่ายเงินปันผลระหว่างกาล
                      ซึ่งคณะกรรมการบริษัทมีอำนาจอนุมัติให้จ่ายเงินปันผลระหว่างกาลได้เป็นครั้งคราวเมื่อเห็นว่าบริษัทมีกำไรและกระแสเงินสดเพียงพอที่จะทำเช่นนั้นได้
                      แล้วให้รายงานให้ที่ประชุมผู้ถือหุ้นทราบในการประชุมคราวต่อไป
                    </p>
                  </div>
                </div>
              ) : (
                <div class="rows">
                  <div class="col-xl-12">
                    <div class="page-header text-center">
                      <p>Dividend Payment</p>
                    </div>
                    <img
                      style={{
                        maxWidth: "80%",
                        margin: "auto",
                        marginBottom: 30,
                      }}
                      src={ImgDividendPolicy}
                      class="corporate-pic"
                    />
                  </div>
                  <div style={{ marginBottom: 50 }}>
                    <p class="text-skn-standard corporate-t1">
                      บริษัทมีนโยบายการจ่ายเงินปันผลให้แก่ผู้ถือหุ้นในอัตราไม่น้อยกว่าร้อยละ
                      40
                      ของกำไรสุทธิจากงบการเงินเฉพาะกิจการภายหลังจากการหักภาษีเงินได้นิติบุคคลและหักสำรองต่างๆ
                      ทุกประเภทตามที่กฎหมายกำหนด
                      และตามที่กำหนดไว้ในข้อบังคับของบริษัท อย่างไรก็ตาม
                      อัตราการจ่ายเงินปันผลตามนโยบายดังกล่าวอาจมีการเปลี่ยนแปลงได้
                      โดยจะขึ้นอยู่กับ ผลการดำเนินงาน ฐานะการเงิน สภาพคล่อง
                      โครงสร้างทางการเงิน
                      ความจำเป็นในการใช้เงินทุนหมุนเวียนในการดำเนินงาน
                      แผนการลงทุน การขยายธุรกิจในอนาคต สภาวะตลาด ความเหมาะสม
                      และปัจจัยอื่นๆ
                      ที่เกี่ยวข้องกับการดำเนินงานและการบริหารงานของบริษัท
                      ตลอดจนข้อกำหนดในสัญญาต่างๆ ที่บริษัทผูกพันอยู่
                      ตามที่คณะกรรมการบริษัทเห็นสมควร
                      โดยอยู่ภายใต้เงื่อนไขที่ว่า
                      บริษัทจะต้องมีเงินสดเพียงพอสำหรับการดำเนินธุรกิจ
                      และการดำเนินการดังกล่าวจะต้องก่อให้เกิดประโยชน์สูงสุดต่อผู้ถือหุ้น
                      ตามที่คณะกรรมการบริษัท และ/หรือ
                      ผู้ถือหุ้นของบริษัทเห็นสมควร
                    </p>
                    <p class="text-skn-standard corporate-t1 mt-5">
                      ทั้งนี้
                      มติของคณะกรรมการบริษัทที่อนุมัติให้จ่ายเงินปันผลจะต้องถูกนำเสนอเพื่อขออนุมัติต่อที่ประชุมผู้ถือหุ้น
                      เว้นแต่เป็นการจ่ายเงินปันผลระหว่างกาล
                      ซึ่งคณะกรรมการบริษัทมีอำนาจอนุมัติให้จ่ายเงินปันผลระหว่างกาลได้เป็นครั้งคราวเมื่อเห็นว่าบริษัทมีกำไรและกระแสเงินสดเพียงพอที่จะทำเช่นนั้นได้
                      แล้วให้รายงานให้ที่ประชุมผู้ถือหุ้นทราบในการประชุมคราวต่อไป
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div class="relative overflow-x-auto">
              <table class="w-full mt-4 text-sm text-center text-white">
                <thead class="text-base text-white bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                  {language === "T" ? (
                    <tr>
                      <th scope="col" class="px-6 py-5 text-center">
                        หลักทรัพย์
                      </th>
                      <th scope="col" class="px-6 py-5">
                        วันที่คณะกรรมการมีมติ
                      </th>
                      <th scope="col" class="px-6 py-5">
                        วันที่ขึ้นเครื่องหมาย
                      </th>
                      <th scope="col" class="px-6 py-5">
                        วันที่จ่ายเงินปันผล
                      </th>
                      <th scope="col" class="px-6 py-5">
                        ประเภทเงินปันผล
                      </th>
                      <th scope="col" class="px-6 py-5">
                        จำนวนเงินปันผลต่อหุ้น
                      </th>
                      <th scope="col" class="px-6 py-5">
                        วันผลประกอบการ
                      </th>
                    </tr>
                  ) : (
                    <tr>
                      <th scope="col" class="px-6 py-5 text-center">
                        Security
                      </th>
                      <th scope="col" class="px-6 py-5">
                        BoD Approved Date
                      </th>
                      <th scope="col" class="px-6 py-5">
                        XD Date
                      </th>
                      <th scope="col" class="px-6 py-5">
                        Dividend payment date
                      </th>
                      <th scope="col" class="px-6 py-5">
                        Type of dividend
                      </th>
                      <th scope="col" class="px-6 py-5">
                        Dividend per Share (Baht/share)
                      </th>
                      <th scope="col" class="px-6 py-5">
                        Performance Period
                      </th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {dividendPolicyData.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        class="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                      >
                        <td class="px-6 py-4 text-[#666] text-center">
                          {item.security}
                        </td>
                        <td class="px-6 py-4 text-[#666] text-center">
                          {item.approved}
                        </td>
                        <td class="px-6 py-4 text-[#666] text-center">
                          {item.xdDate}
                        </td>
                        <td class="px-6 py-4 text-[#666] text-center">
                          {item.paymentDate}
                        </td>
                        <td class="px-6 py-4 text-[#666] text-center">
                          {item.typeOfDividend}
                        </td>
                        <td class="px-6 py-4 text-[#666] text-center">
                          {item.dividendPerShare}
                        </td>
                        <td class="px-6 py-4 text-[#666] text-center">
                          {item.performancePeriod}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
