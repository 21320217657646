import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function CardCountNumber({ start, end, duration }) {
  const [counterValue, setCounterValue] = useState(start);
  const location = useLocation();

  useEffect(() => {
    let startTimestamp = null;
    // การแสดงค่าเลขที่เปลี่ยนแปลงตามเวลา
    const step = (timestamp) => {
      // startTimestamp คือตัวแปรที่ใช้เก็บเวลาที่ animation เริ่มต้น เมื่อ animation เริ่มทำงานเป็นครั้งแรก startTimestamp จะถูกกำหนดค่าเป็น null
      //  และเมื่อ animation เริ่มทำงาน startTimestamp จะถูกกำหนดค่าเป็นเวลาปัจจุบันที่ animation เริ่มต้นดำเนินการ ซึ่งจะถูกใช้ในการคำนวณ progress ของ animation
      // โดยการหาต่างของเวลาปัจจุบันกับเวลาเริ่มต้นและนำไปหารด้วยระยะเวลาทั้งหมดที่กำหนดให้ animation นั้นจบลง เพื่อให้ได้สัดส่วนของความคืบหน้าของ animation โดยที่มีค่าอยู่ระหว่าง 0 ถึง 1
      if (!startTimestamp) startTimestamp = timestamp;

      //   การ animation จากค่าเริ่มต้น (start) ไปยังค่าสุดท้าย (end) ในระยะเวลาที่กำหนด (duration) โดยเมื่อค่าถึงค่าสุดท้ายแล้ว animation จะหยุด
      //  progress คือสัดส่วนของความคืบหน้าของ animation และจะมีค่าตั้งแต่ 0 ถึง 1   เมื่อเสร็จสิ้น progress เป็น 1
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      setCounterValue(progress * (end - start) + start);

      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);

    // Clean up the animation on component unmount
    return () => {
      setCounterValue(end);
    };
  }, [start, end, duration]);

  return (
    <span className={location.pathname === "/" ? "shares" : "sharesInfo"}>
      {/* กำหนดทศนิยม2ตำแหน่ง */}
      {counterValue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </span>
  );
}
