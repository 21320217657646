import React from "react";
import { logoIso1, logoIso2, awardCarb, fsc } from "../../assets/Logo";
import { useGlobalContext } from "../../context/Context";
import { Header } from "../../Components/index";

export default function Awards() {
  const { language } = useGlobalContext();
  return (
    <section>
      <div id="aboutUs" className="sub-top-hight">
        <div
          className="container-I"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className="row">
            <div className="box-line">
              <Header
                nameEN={"Awards and Certifications"}
                nameTH={"รางวัลและการรับรอง"}
              />
            </div>
          </div>
          {language === "T" ? (
            <div className="bg-awd">
              <div className="content-awards">
                <div className="content-awards-layout">
                  <div className="main-head-reward">
                    <div className="content-awards-layout">
                      <div className="main-head-reward box-card-award">
                        <div className="big-cover-award">
                          <div className="content-awards-layout">
                            <div className="box-award">
                              <div className="cover-award">
                                <div
                                  className="text-award"
                                  style={{ backgroundColor: "#f8fdfe" }}
                                >
                                  <p className="t-1 black">ISO 9001</p>
                                  <p className="t-2 black">
                                    มาตรฐานระบบการจัดการด้านคุณภาพ
                                  </p>
                                </div>
                                <div className="pic-award">
                                  <img src={logoIso1} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-awards-layout"></div>
                        </div>
                      </div>
                      <div className="main-head-reward box-card-award">
                        <div className="big-cover-award">
                          <div className="content-awards-layout">
                            <div className="box-award">
                              <div className="cover-award">
                                <div
                                  className="text-award"
                                  style={{ backgroundColor: "#f8fdfe" }}
                                >
                                  <p className="t-1 black">ISO 14001</p>
                                  <p className="t-2 black">
                                    มาตรฐานระบบการจัดการด้านสิ่งแวดล้อม
                                  </p>
                                </div>
                                <div className="pic-award">
                                  <img src={logoIso2} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-awards-layout"></div>
                        </div>
                      </div>
                    </div>
                    <div className="content-awards-layout">
                      <div className="main-head-reward box-card-award">
                        <div className="big-cover-award">
                          <div className="content-awards-layout">
                            <div className="box-award">
                              <div className="cover-award">
                                <div
                                  className="text-award"
                                  style={{ backgroundColor: "#f8fdfe" }}
                                >
                                  <p className="t-1 black">
                                    CARB & EPA Certificate
                                  </p>
                                  <p className="t-2 black">
                                    มาตรฐาน CARB. <br />
                                    {`(California Air Resources Board)`}
                                  </p>
                                </div>
                                <div className="pic-award">
                                  <img src={awardCarb} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-awards-layout"></div>
                        </div>
                      </div>
                      <div className="main-head-reward box-card-award">
                        <div className="big-cover-award">
                          <div className="content-awards-layout">
                            <div className="box-award">
                              <div className="cover-award">
                                <div
                                  className="text-award"
                                  style={{ backgroundColor: "#f8fdfe" }}
                                >
                                  <p className="t-1 black">FSC Certificated</p>
                                  <p className="t-2 black">
                                    Forest Stewardship Council
                                  </p>
                                </div>
                                <div className="pic-award">
                                  <img src={fsc} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-awards-layout"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-awd">
              <div className="content-awards">
                <div className="content-awards-layout">
                  <div className="main-head-reward">
                    <div className="content-awards-layout">
                      <div className="main-head-reward box-card-award">
                        <div className="big-cover-award">
                          <div className="content-awards-layout">
                            <div className="box-award">
                              <div className="cover-award">
                                <div
                                  className="text-award"
                                  style={{ backgroundColor: "#f8fdfe" }}
                                >
                                  <p className="t-1 black">ISO 9001</p>
                                  <p className="t-2 black">
                                    Quality Management System
                                  </p>
                                </div>
                                <div className="pic-award">
                                  <img src={logoIso1} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-awards-layout"></div>
                        </div>
                      </div>
                      <div className="main-head-reward box-card-award">
                        <div className="big-cover-award">
                          <div className="content-awards-layout">
                            <div className="box-award">
                              <div className="cover-award">
                                <div
                                  className="text-award"
                                  style={{ backgroundColor: "#f8fdfe" }}
                                >
                                  <p className="t-1 black">ISO 14001</p>
                                  <p className="t-2 black">
                                    Environmental Management System
                                  </p>
                                </div>
                                <div className="pic-award">
                                  <img src={logoIso2} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-awards-layout"></div>
                        </div>
                      </div>
                    </div>
                    <div className="content-awards-layout">
                      <div className="main-head-reward box-card-award">
                        <div className="big-cover-award">
                          <div className="content-awards-layout">
                            <div className="box-award">
                              <div className="cover-award">
                                <div
                                  className="text-award"
                                  style={{ backgroundColor: "#f8fdfe" }}
                                >
                                  <p className="t-1 black">
                                    CARB & EPA Certificate
                                  </p>
                                  <p className="t-2 black">
                                    CARB Standard.
                                    <br />
                                    {`(California Air Resources Board)`}
                                  </p>
                                </div>
                                <div className="pic-award">
                                  <img src={awardCarb} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-awards-layout"></div>
                        </div>
                      </div>
                      <div className="main-head-reward box-card-award">
                        <div className="big-cover-award">
                          <div className="content-awards-layout">
                            <div className="box-award">
                              <div className="cover-award">
                                <div
                                  className="text-award"
                                  style={{ backgroundColor: "#f8fdfe" }}
                                >
                                  <p className="t-1 black">FSC Certificated</p>
                                  <p className="t-2 black">
                                    Forest Stewardship Council
                                  </p>
                                </div>
                                <div className="pic-award">
                                  <img src={fsc} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-awards-layout"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
