import logo from "./logo.svg";
import "./App.css";
import { Footer, Navbar } from "./Components";
import { Outlet, useLocation  } from "react-router-dom";
import { getTitleFromPath } from "./utils/utils";
import { useEffect,useRef } from "react";

function App() {
  const location = useLocation()

  useEffect(() => {
    const newTitle = getTitleFromPath(window.location.pathname);
      document.title = newTitle;

      const script = document.createElement("script");
      script.async = true;
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-GD5CY45ERD";
  
      // Add event listeners for script loading
      script.onload = () => {
        // Initialize Google Analytics after the script is loaded
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'G-GD5CY45ERD', {
          'cookie_flags': 'samesite=none;secure'
        });
      };
  
      document.head.appendChild(script);
    
  }, [location]);
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}

export default App;
