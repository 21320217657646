export default function Pagination({
  currentPage,
  totalPages,
  onChangePage,
  hasNext,
  hasPrevious,
}) {
  let startPage = Math.max(1, currentPage - 3);
  let endPage = Math.min(totalPages, startPage + 6);
  return (
    <div className="flexCenter">
      <div className="pagination-box">
        <input
          className={hasPrevious ? "prv-btn" : "prv-btn disabled"}
          type="button"
          value={"<<"}
          onClick={() => onChangePage(currentPage - 1)}
          {...(hasPrevious ? {} : { disabled: true })}
        ></input>

        <div className="page-number">
          {Array?.from({ length: endPage - startPage + 1 }, (_, index) => {
            const pageNumber = startPage + index;
            return (
              <input
                key={pageNumber}
                style={{ width: 37 }}
                className={`${currentPage === pageNumber ? "active" : ""}`}
                type="button"
                value={pageNumber}
                onClick={() => onChangePage(pageNumber)}
              />
            );
          })}
        </div>
        <input
          className={hasNext ? "next-btn" : "next-btn disabled"}
          type="button"
          value={">>"}
          onClick={() => onChangePage(currentPage + 1)}
          {...(hasNext ? {} : { disabled: true })}
        ></input>
      </div>
    </div>
  );
}
